import React, { useState, useCallback, useEffect, useRef } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import { Form, Button } from 'react-bootstrap';
import CustomDayViewPlugin from "./CustomDayViewPlugin";
import { WorkUpdateModal } from './WorkUpdateModal';
import './DailyWorkUpdate.scss';
import { connect } from 'react-redux';
import { setUserInfo } from '../store/actions';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../firebaseConfig';
import { useLocation, useNavigate } from 'react-router-dom';
import { callAxios, convertReadable, formatDate, formatDateYYMM } from '../utils/utils';
import { environment } from '../environment';
import { LoaderSDM } from '../Loader/LoaderSDM';
import { toast } from 'react-toastify';
import { SdmProvider } from '../Context/SdmContext';



const MonthViewWorkUpdate = ({ setLoggedinUser, userRole }) => {
    const calendarMonthRef = useRef(null)
    const [user, loading, error] = useAuthState(auth);
    const email = "himansunayak@virtualemployee.com";
    
    let navigate = useNavigate();
    const {state} = useLocation()
    const [showWorkUpdateModal, setShowWorkUpdateModal] = useState(false);
    const [selected, setSelected] = useState(null);
    const [data, setData] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [isLoadingOptional, setIsLoadingOp] = useState(false)
    const [defaultItem, setDefaultItem] = useState({});
    const [sdmListing, setSdmListing] = useState([])
    const [dropdownItem, setDropdownItem] = useState([])
    const [percentage, setPercentage] = useState(0)
     const [client_name, setClientName] = useState('');
     const [clientInteraction, setClientInteraction] = useState('');

    const [todayDate, setDate] = useState(()=>{
        const date = new Date();
             const syear = date.getFullYear();
             const smonth = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
             const sday = String(date.getDate()).padStart(2, '0');
             return `${syear}-${smonth}-${sday}`;
    })
    const [monthData, setMonthData] = useState([])

    const currentMonth = new Date().toISOString().slice(0, 7);

    // Initialize state with current month
    const [monthwise, setMonthwise] = useState(currentMonth);
    
    // console.log(monthwise)
    const [events, setEvents] = useState([
        {
            title: '',
            date: '2025-01-06',
            extendedProps: { progress: 0 }
        },
        {
            title: '',
            date: '2025-01-07',
            extendedProps: { progress: 80 }
        },
        {
            title: '',
            date: '2025-01-08',
            extendedProps: { progress: 100 }
        },
        {
            title: '',
            date: '2025-01-09',
            extendedProps: { progress: 40 }
        },
        {
            title: '',
            date: '2025-01-10',
            extendedProps: { progress: 100 }
        },
        {
            title: '',
            date: '2025-01-13',
            extendedProps: { progress: 60 }
        },
        {
            title: '',
            date: '2025-01-14',
            extendedProps: { progress: 40 }
        },
        {
            title: '',
            date: '2025-01-15',
            extendedProps: { progress: 0 }
        },
        {
            title: '',
            date: '2025-01-16',
            extendedProps: { progress: 80 }
        },
        {
            title: '',
            date: '2025-01-17',
            extendedProps: { progress: 100 }
        },
        {
            title: '',
            date: '2025-01-20',
            extendedProps: { progress: 40 }
        },
        {
            title: '',
            date: '2025-01-21',
            extendedProps: { progress: 60 }
        },
    ]);

    useEffect(()=>{
        const eventArray = [];
        if(monthData && monthData.length >0){
            monthData.map((item)=>{
                const percentage = item?.count * 100 / item?.total_client
                eventArray.push({
                    title: '',
                    date: item?.date,
                    extendedProps: { progress: percentage, title:`${item?.count}/${item?.total_client} Client Updated` }
                })
            })
            setEvents(eventArray)
        }
    },[monthData])

    const getListingDataFromStore = async(date,sdm_email) =>{
        setIsLoading(true)
        callAxios(`${environment.REACT_APP_API_URL_Live}api/v1/sdm-update/get-work-update`,{date,sdm_email},"post").then((sdmData)=>{
            setSdmListing(sdmData.data.data)
            // console.log("calling from getListingDatafromstore")
            // getClientDataFromCRM(date,email);
            setIsLoading(false)
        }).catch((error)=>{
            // setIsLoading(false)
        })
    }

    const getListingDataFromStore_refresh = async(date,sdm_email,optiona =null) =>{
        setIsLoading(true)
        callAxios(`${environment.REACT_APP_API_URL_Live}api/v1/sdm-update/get-work-update`,{date,sdm_email},"post").then((sdmData)=>{
           if(optiona === "afterSaveRecord"){
            // console.log("calling from aftersaverecord")
            getClientDataFromCRM(todayDate, email, "fromAddedRecord")
           } else{
            setIsLoading(false)
           }
            
            setSdmListing(sdmData.data.data)
            // getClientDataFromCRM();
           
        }).catch((error)=>{
            setIsLoading(false)
        })
    }
    // console.log(sdmListing)
    const getClientDataFromCRM = async(todayDate,email, optional = null) =>{
        if(optional === "fromMonthClickDate"){
            setIsLoadingOp(true)
        }
        setIsLoading(true)
        callAxios(`${environment.REACT_APP_API_URL_Live}api/v1/sdm-update/get-data-by-month`,{todayDate,email},"post").then((monthData)=>{
            if(monthData.status === 200){
                // setShowWorkUpdateModal(true);
                if(optional === "fromAddedRecord"){
                    toast.success(`Work report submitted for client ${defaultItem.clientName}`, {
                                                            position: "top-right", // Position of the toast
                                                            autoClose: 5000,           // Toast will close after 5 seconds (5000 ms)
                                                            hideProgressBar: false,     // Hide the progress bar
                                                            closeOnClick: true,        // Close the toast when clicked
                                                            pauseOnHover: true,        // Pause the toast when hovering over it
                                                            draggable: true,           // Make the toast draggable
                                                        });
                }
                setIsLoading(false)
                setIsLoadingOp(false)
                setData(monthData.data)
                // setDefaultItem(monthData.data.sdmData[0])
                setDropdownItem(monthData.data.dropdownItem)
                if(monthData.data.sdmData.length ===0){
                    toast.error('No billing found on given date', {
                        position: "top-right", // Position of the toast
                        autoClose: 5000,           // Toast will close after 5 seconds (5000 ms)
                        hideProgressBar: false,     // Hide the progress bar
                        closeOnClick: true,        // Close the toast when clicked
                        pauseOnHover: true,        // Pause the toast when hovering over it
                        draggable: true,           // Make the toast draggable
                    });
                }
            }
        }).catch((error)=>{
            setIsLoading(false)
            setData([])
             toast.error('Something went wrong!', {
                                position: "top-right", // Position of the toast
                                autoClose: 5000,           // Toast will close after 5 seconds (5000 ms)
                                hideProgressBar: false,     // Hide the progress bar
                                closeOnClick: true,        // Close the toast when clicked
                                pauseOnHover: true,        // Pause the toast when hovering over it
                                draggable: true,           // Make the toast draggable
                            });
        })
    }

     useEffect(() => {
                    if (loading) return;
                    if (!user) navigate("/");
                    else {
                
                      const userInfo = {
                        uid: user?.uid,
                        email: user?.email,
                        displayName: user?.displayName,
                        accessToken: user?.accessToken,
                        idToken: user?.idToken,
                      };
                      
                
                      setLoggedinUser(userInfo);
                      
                    //   getListingDataFromStore(todayDate,email)
               
                    }
                  }, [user, loading]);
    // Show/Hide Work Update Form Layout
    const openWorkUpdateModal = () => {
        // getClientDataFromCRM();
        if(data.sdmData.length === 0){
            toast.error('No billing found on given date. you can not add work report', {
                position: "top-right", // Position of the toast
                autoClose: 5000,           // Toast will close after 5 seconds (5000 ms)
                hideProgressBar: false,     // Hide the progress bar
                closeOnClick: true,        // Close the toast when clicked
                pauseOnHover: true,        // Pause the toast when hovering over it
                draggable: true,           // Make the toast draggable
            });
        } else{
            setClientName('')
            setDefaultItem({})
            setClientInteraction('')
            setShowWorkUpdateModal(true);
        }
        
    }
    const closeWorkUpdateModal = () => {
        setClientName('')
        setDefaultItem({})
        setClientInteraction('')
        setShowWorkUpdateModal(false);
    }

    const getDataInMonthForm = (monthwise,email)=>{
        setIsLoading(true)
        callAxios(`${environment.REACT_APP_API_URL_Live}api/v1/sdm-update/get-Work-count`,{date:monthwise, sdm_email:email},"post").then((monthData) =>{
            setMonthData(monthData.data.data)
           
            setIsLoading(false)
        }).catch((error) =>{

        })
    }

    const handleMonthChange = (e) => {
        // console.log(e.target.value)
        setMonthwise(e.target.value);  // Update state when the user selects a new month
        // console.log('Selected Month:', e.target.value);  // Debugging

        const calendarApi = calendarMonthRef.current.getApi();
        calendarApi.gotoDate(`${e.target.value}-01`);
    };

    const handleDayChange = (e) =>{
        setDate(e.target.value)
        const calendarApi = calendarMonthRef.current.getApi();
        calendarApi.gotoDate(`${e.target.value}`);
    }
    // Add public holidays
    const publicHolidays = [
        { title: 'New Year\'s Day', date: '2025-01-01', textColor: '#df55fd' }, // Custom background color
        { title: 'Public Holiday', date: '2025-01-22', textColor: '#df55fd' },
    ];

    // Combine public holidays with regular events
    const allEvents = [...events, ...publicHolidays];

    // Custom function to handle the click on the timeGridDay button
    // Handle View Change
    const handleViewChange = (dateInfo) => {
        
        if (dateInfo.view.type === 'customDay') {
            getListingDataFromStore_refresh(formatDate(dateInfo.start),email)
            // console.log("calling from handleview")
            getClientDataFromCRM(dateInfo.start, email, "fromMonthClickDate")
            setDate(formatDate(dateInfo.start))
            setSelected('Day View');

        } else {

            setMonthwise(formatDateYYMM(dateInfo.view.calendar.currentData.currentDate))
            // console.log(dateInfo.view.calendar.currentData.currentDate)
            getDataInMonthForm(monthwise, email)
            setSelected(null);
        }
    };

 


      

    const handleDateClick = (info) => {
        // info.dateStr gives you the clicked date in YYYY-MM-DD format
        const clickedDate = new Date(info.dateStr);
        const today = new Date();
        if (clickedDate > today) {
            toast.error(`You can't select future dates.`, {
                position: "top-right", // Position of the toast
                autoClose: 5000,           // Toast will close after 5 seconds (5000 ms)
                hideProgressBar: false,     // Hide the progress bar
                closeOnClick: true,        // Close the toast when clicked
                pauseOnHover: true,        // Pause the toast when hovering over it
                draggable: true,           // Make the toast draggable
            });
            // alert("You can't select future dates.");
            return;
        }
        setDate(info.dateStr); // Set the selected date
        setSelected('Day View'); // Optionally switch to Day View
        // getListingDataFromStore_refresh(info.dateStr, email); // Fetch data for the clicked date
        // getClientDataFromCRM(info.dateStr, email, "fromMonthClickDate");
        const calendarApi = calendarMonthRef.current.getApi();
        calendarApi.changeView('customDay', info.dateStr);

    };

    const renderEventContent = (eventInfo) => {
        // console.log("render envent for title",eventInfo)
        const progress = eventInfo.event.extendedProps.progress || 0;
        const title = eventInfo.event.extendedProps.title || 0;
        const isHoliday = publicHolidays.some(holiday => holiday.date === eventInfo.event.startStr);

        // Determine the text color based on progress
        let textColor = 'text-dark'; // Default color
        let borderColor = 'border-dark'
        if (progress > 10) {
            textColor = 'text-white'; // Green if progress is over 80%
            borderColor = 'border-success';
        }
        else {
            textColor = 'text-red'; // Red if progress is under 50%
            borderColor = 'border-white';
        }

        

        return (
            <div className="event-content">
                <div className="event-title text-truncate" style={{ color: isHoliday ? eventInfo.event.extendedProps.textColor : 'inherit' }}>
                    {eventInfo.event.title}
                </div>
                {!isHoliday && eventInfo.event.extendedProps.progress !== undefined && (
                    <div className={`progress dark-theme-bg3 border ${borderColor} rounded-pill position-relative`}>
                        <div className="progress-bar bg-success" role="progressbar" aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100" style={{ width: `${progress}%` }}></div>
                        <span className={`fs-12 bold position-absolute top-50 translate-middle-y w-100 text-center text-truncate lh-sm px-1 ${textColor}`}>{title}</span>
                    </div>
                )}
            </div>
        );
    };

    const headerToolbar = {
        left: 'dayGridMonth,customDay',
        // left: 'dayGridMonth,timeGridWeek,timeGridDay',
        center: 'title',
        right: 'prev,next today',
    };

    const buttonText = {
        today: 'Today',
        month: 'Month View',
        week: 'Week',
        day: 'Day View',
        customDay: 'Daily View', //custom created button
    };

    const views = {
        timeGrid: {
            eventLimit: 6,
        },
    };

    // Show Weekoff On Month Calendar
    const dayCellContent = useCallback((arg) => {
        const dayOfWeek = arg.date.getDay();
        if (dayOfWeek === 6 || dayOfWeek === 0) {
            return (
                <div className='info-box h-100 d-flex flex-column flex-fill justify-content-between'>
                    <div className='date-title'>{arg.dayNumberText}</div>
                    <div className="event-title text-light text-opacity-50 text-truncate">Weekoff</div>
                </div>
            );
        }
        return <div className='date-title'>{arg.dayNumberText}</div>;
    }, []);

    const pp = (sdmListing ? sdmListing.length : 0) * 100 / (data?.sdmData ? data.sdmData.length : 0);
    // console.log("percentage:::", pp, sdmListing ? sdmListing.length : 0, data?.sdmData ? data.sdmData.length : 0);
    return (
        <div className="MonthViewLayout py-3">
            {isLoading?<LoaderSDM />:null}
            {isLoadingOptional?<LoaderSDM />:null}
            <div className="container-fluid px-xxl-5">
                <div className="heading border-bottom border-3 border-primary border-opacity-75 d-flex flex-wrap align-items-center justify-content-between gap-2 mb-3 pb-3">
                    <div className="d-flex flex-wrap flex-column flex-md-row align-items-md-center justify-content-between flex-fill gap-2">
                        <div className="fs-3 fw-semibold text-light lh-sm text-nowrap">My Daily Work Update</div>
                        {selected === 'Day View' && data?.sdmData?.length >0?
                           
                            
                            <div className="client-update-menu">
                                <div className="progress position-relative bg-transparent border border-1 border-light rounded-1" style={{ height: '40px', minWidth: '250px' }}>
                                    <div className="progress-bar bg-success" role="progressbar" aria-valuenow={pp} aria-valuemin="0" aria-valuemax="100" style={{ width: `${pp}%` }}></div>
                                    <span className="fs-6 text-light fw-semibold position-absolute top-50 translate-middle-y w-100 text-center lh-sm pointer">{sdmListing && sdmListing?sdmListing.length:0}/{data && data?.sdmData?data.sdmData.length:0}</span>
                                </div>
                                <div className="clients-list-cover w-100 position-absolute top-100 start-50 translate-middle-x z-3">
                                    <div className="clients-list rounded-2 px-2">
                                        <table className='table table-borderless align-middle mb-0'>
                                            <thead>
                                                <tr className="fs-14 text-warning">
                                                    <th className="client-name fw-medium">Assigned Client</th>
                                                    <th className="client-since fw-medium">Assigned Since</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data.sdmData.map((item,index)=>(
                                                <tr>
                                                    <td className='fs-14 text-white text-capitalize'>{item?.clientName} <span className='text-warning'>[{item?.service_id}]</span></td>
                                                    <td className='fs-12 text-light text-opacity-75 text-capitalize'>{convertReadable(item?.createdon)}</td>
                                                </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="progress position-relative bg-transparent border border-1 border-light rounded-1" style={{ height: '40px', minWidth: '250px' }}>
                                <div className="progress-bar bg-success" role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100" style={{ width: '0%' }}></div>
                                <span className="fs-6 text-light fw-semibold position-absolute top-50 translate-middle-y w-100 text-center lh-sm">{sdmListing && sdmListing?sdmListing.length:0}/{data && data?.sdmData?data.sdmData.length:0} Client Updated</span>
                            </div>
                            
                        }
                       
                        <div className="Wallet-page">
                            <div className="d-flex flex-wrap align-items-end gap-2 gap-xl-3">
                                {selected === 'Day View' ? (
                                    <Form.Group className="date-wrapper d-flex flex-column flex-md-row align-items-md-center flex-fill gap-1 gap-md-2 gap-xl-3">
                                        <Form.Label className="text-nowrap fs-14 m-0">Select Date</Form.Label>
                                        <Form.Control type="date" className="bg-transparent py-2"
                                            defaultValue={new Date().toJSON().slice(0, 10)}
                                            onChange={handleDayChange}
                                            value={todayDate}
                                        />
                                    </Form.Group>
                                ) : (
                                    <Form.Group className="date-wrapper d-flex flex-column flex-md-row align-items-md-center flex-fill gap-1 gap-md-2 gap-xl-3">
                                        <Form.Label className="text-nowrap fs-14 m-0">Select Month</Form.Label>
                                        <Form.Control type="month" className="bg-transparent py-2"
                                            // min={currentMonth}  // Set minimum date to current month
                                            value={monthwise}  // Bind value to the state
                                            onChange={handleMonthChange}  // Update state on change 
                                            defaultValue={monthwise}
                                        />
                                    </Form.Group>
                                )}
                                <Button variant="outline-warning" className="text-center px-3 py-2" onClick={openWorkUpdateModal}>
                                    <i className="bi bi-plus-circle-fill lh-sm"></i>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="FullCalendar-wrapper">
                <SdmProvider sdmListing={sdmListing} setShowWorkUpdateModal={setShowWorkUpdateModal} todayDate={todayDate} sdmData={data?.sdmData}>
                    <FullCalendar
                        ref={calendarMonthRef}
                        plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin, CustomDayViewPlugin]}
                        initialView="customDay"
                        events={allEvents}
                        eventContent={renderEventContent}
                        headerToolbar={headerToolbar}
                        buttonText={buttonText}
                        views={views}
                        dayCellContent={dayCellContent}
                        datesSet={handleViewChange}
                        customProps={{ openWorkUpdateModal }}
                        dateClick={handleDateClick}
                        
                    />
                    </SdmProvider>
                </div>

                {/* Work Update Form Layout */}
                
                <WorkUpdateModal showWorkUpdateModal={showWorkUpdateModal} closeWorkUpdateModal={closeWorkUpdateModal} allData={data} defaultItem={defaultItem} setDefaultItem={setDefaultItem} setIsLoading={setIsLoading} setShowWorkUpdateModal={setShowWorkUpdateModal} getListingDataFromStore_refresh={getListingDataFromStore_refresh} date={todayDate} email={user?.email} dropdownItem={dropdownItem} client_name={client_name} setClientName={setClientName} clientInteraction={clientInteraction} setClientInteraction={setClientInteraction}/>
                
            </div>
        </div>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
      setLoggedinUser: (user) => dispatch(setUserInfo(user))
    };
  };
  
  const mapStoreStateToProps = (state) => {
    return {
      ...state,
    };
  };
   
  export default connect(
    mapStoreStateToProps,
    mapDispatchToProps
  )(MonthViewWorkUpdate);
