import React, { useEffect, useState } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { callAxios } from '../utils/utils';
import { environment } from '../environment';
import { nm_paymentstatus, nm_servicemonth } from '../utils/codes';
import { MfaModal } from '../WalletManagement/MfaModal';

export const SendAmountModal = ({ show, close, modalValuedata, Modalitemdata, UserInstallmentFuncModal,formData,setLoading, user, setShowSendAmountModal }) => {
    // console.log(modalValuedata)
    const [transactionId, setTransactionId] = useState('');
    const [isChecked, setIsChecked] = useState(false);
    const [note, setNote] = useState('');

     const [isAuthConfigured, setisAuthConfigured] = useState(null)
        const [mfaApproved, setMfaApproved] = useState(false)
        const [randamNo, setRandomNo] = useState(null)
     // Management MFA modal
            const [showManagementMFAModal, setShowManagementMFAModal] = useState(false);
            const closeManagementMFAModal = () => setShowManagementMFAModal(false);
            const openManagementMFAModal = () => setShowManagementMFAModal(true);


    // Function to handle checkbox change event
    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };

     const mfaOpen = ()=>{
        if (!transactionId.trim()) {
            toast.error('Enter transaction Id...', {
                position: "bottom-center", // Position of the toast
                autoClose: 5000,           // Toast will close after 5 seconds (5000 ms)
                hideProgressBar: false,     // Hide the progress bar
                closeOnClick: true,        // Close the toast when clicked
                pauseOnHover: true,        // Pause the toast when hovering over it
                draggable: true,           // Make the toast draggable
            });
            return false
        }

        if (!isChecked) {
            toast.error('You must verify details before processing.', {
                position: "bottom-center", // Position of the toast
                autoClose: 5000,           // Toast will close after 5 seconds (5000 ms)
                hideProgressBar: false,     // Hide the progress bar
                closeOnClick: true,        // Close the toast when clicked
                pauseOnHover: true,        // Pause the toast when hovering over it
                draggable: true,           // Make the toast draggable
            });
            return false
        }
                    
            setShowManagementMFAModal(true)
        }

    
    const handleSubmit = async (e) => {
            
        const postdata = {
            "users_installment_id": Modalitemdata?.id,
            "transaction_id": transactionId,
            "notes": note,
        }
        try {
            setLoading(true);
            const res = await callAxios(`${environment.REACT_APP_API_URL_Live}api/v1/wallet/send-wallet-users-installment`, postdata, "post");
            if (res?.data?.statusCode === 200) {
                
                setTransactionId("")
                setNote("")
                setMfaApproved(false)
                setIsChecked(false)
                UserInstallmentFuncModal(formData,"fromSendModal")
                setShowSendAmountModal(false)
            }
            close()
            // setLoading(false);
        } catch (error) {
            console.log("Error:", error);
            // setLoading(false);
            setMfaApproved(false)
            setShowSendAmountModal(false)
        }
    };

    useEffect(()=>{
        if(mfaApproved){
            //  console.log("in useEffect!!!")
            handleSubmit();
        }
    },[mfaApproved])
// Handle form submission
//  console.log("is mfaApproved is::",mfaApproved)

    return (
        <>
            <Modal show={show} centered onHide={close} size='xl' backdrop="static" keyboard={false} className='p-0' dialogClassName="DisputeModal" contentClassName='incentives-account-page rounded overflow-hidden'>
                {/* {getLoading ? <Showloader /> : ""} */}
                <Modal.Body className="text-black px-3 px-md-4 px-lg-5 py-4">
                    <div className="fs-3 fw-bold lh-sm mb-2 mb-xl-3">Send ₹{Modalitemdata?.redeem_amount}</div>
                    <div className="info fw-medium lh-sm mb-4" style={{ color: '#FF191A' }}>
                        *Kindly review the billing details and provide the necessary input.
                    </div>
                    <hr className='border border-1 border-secondary border-opacity-75 mb-3' />
                    <div className="billing-info">
                        <div className="heading-title fs-6 fw-semibold mb-3">Client/Billing Information</div>
                        <div className="row row-cols-2 g-3">
                            <div className="info-box text-capitalize">
                                <div className="label-name text-primary fs-14">Billing ID</div>
                                <div className="label-value text-uppercase">{modalValuedata?.billing_id}</div>
                            </div>
                            <div className="info-box text-capitalize">
                                <div className="label-name text-primary fs-14">Service in CRM</div>
                                <div className="label-value text-uppercase">{modalValuedata?.service_id}</div>
                            </div>
                            <div className="info-box text-capitalize">
                                <div className="label-name text-primary fs-14">Zoho Client ID</div>
                                <div className="label-value text-uppercase">{modalValuedata?.client_id}</div>
                            </div>
                            <div className="info-box text-capitalize">
                                <div className="label-name text-primary fs-14">Zoho Invoice ID</div>
                                <div className="label-value text-uppercase">{modalValuedata?.invoice_id}</div>
                            </div>
                            <div className="info-box text-capitalize">
                                <div className="label-name text-primary fs-14">Service Month</div>
                                <div className="label-value">{nm_servicemonth[modalValuedata?.service_month]}</div>
                            </div>
                            <div className="info-box text-capitalize">
                                <div className="label-name text-primary fs-14">Payment Status</div>
                                <div className="label-value">{nm_paymentstatus[modalValuedata?.payment_status]}</div>
                            </div>
                        </div>
                    </div>
                    <hr className='border border-1 border-secondary border-opacity-75 mb-3' />

                    <div className="row row-cols-sm-2 g-3 mb-4">
                        <div className="info-box text-capitalize">
                            <div className="label-name text-primary fs-14">Employee Name</div>
                            <div className="label-value">{Modalitemdata?.name}</div>
                        </div>
                        {modalValuedata && modalValuedata.emp_id?
                        <div className="info-box text-capitalize">
                            <div className="label-name text-primary fs-14">Employee ID</div>
                            <div className="label-value text-uppercase">{modalValuedata.emp_id}</div>
                        </div>
                        :null}
                    </div>
                    <Form >
                        <Form.Group className="mb-3">
                            <Form.Label className="ps-0">Transaction ID</Form.Label>
                            <Form.Control type="text" placeholder="Enter Transaction ID" autoComplete='off'  value={transactionId} onChange={(e) => setTransactionId(e.target.value)} required />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Control as="textarea" rows={5} placeholder="Write note..." autoComplete='off' value={note}
                                onChange={(e) => setNote(e.target.value)} />
                        </Form.Group>

                        <div className="form-check checks-wrapper mb-4">
                            <input className="form-check-input" type="checkbox" id="checkCondition" checked={isChecked} onChange={handleCheckboxChange} />
                            <label className="form-check-label fs-14" for="checkCondition">
                                I have verified all details before processing the payments.
                            </label>
                        </div>
                        <div className="btn-wrapper d-flex flex-wrap flex-column flex-sm-row justify-content-center gap-2 gap-sm-3 pt-2">
                            <Button variant='secondary' className='btn-custom fs-18 px-5 py-2' onClick={close}>Cancel</Button>
                            <Button  variant='success' className='btn-custom fs-18 px-5 py-2' onClick={mfaOpen} disabled={!isChecked} 
                            
                            // onClick={handleSubmit}
                            >Update</Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
            <ToastContainer />

            {/* Management MFA Modal */}
                                        <MfaModal show={showManagementMFAModal} close={closeManagementMFAModal}  setShowManagementMFAModal={setShowManagementMFAModal} email={user?.email} user={user}isAuthConfigured={isAuthConfigured} setisAuthConfigured={setisAuthConfigured} setMfaApproved={setMfaApproved} setRandomNo={setRandomNo} mfaApproved={mfaApproved}/>
                                
        </>
    )
}
