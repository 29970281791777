import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'
import { app, database, auth } from "../../firebaseConfig";

// env import
import {environment} from '../../environment';

import {callAxios} from "../../utils/utils";
import { connect } from 'react-redux';
import { setUserInfo } from '../../store/actions';
import { useAuthState } from 'react-firebase-hooks/auth';

const ViewAccount = ({setLoggedinUser}) => {
    //console.log("View Account Props");
let navigate = useNavigate();
const {state} = useLocation();
// console.log(state)
const { user, userRole } = state; // Read values passed on state
   //console.log(user, userRole); 
   const [loading, error] = useAuthState(auth);
   useEffect(() => {
       if (loading) return;
       if (!user) navigate("/");
       else {
                   
         const userInfo = {
           uid: user?.uid,
           email: user?.email,
           displayName: user?.displayName,
           accessToken: user?.accessToken,
           idToken: user?.idToken,
         };

         setLoggedinUser(userInfo);
        
       }
     }, [user, loading]);
   const shortName =user?.displayName.split(" ").map((shortName) => {
    return shortName[0];
  });

  const logoutHandler = async() => {
   // console.log("In lougout");
    await auth.signOut();
    await callAxios(environment.REACT_APP_API_URL_Live+'api/v1/attendance/login-logout',{uid:user?.uid, action: 'Check-Out', email:user?.email,display_name:user?.displayName}, "post" )
    //await callAxios(environment.REACT_APP_API_URL_Live+'api/v1/attendance/login-logout',{uid:props?.user?.uid, action: 'Check-Out', email:props?.user?.email,display_name:props?.user?.displayName}, "post" )
  };

  const navigateToMember = (e)=>{
    e.preventDefault();
    navigate('/member-list', { state: {user:user,userRole:userRole,memberType:'BDM'}});
   } 

   const navigateToCamM = (e)=>{
    e.preventDefault();
    navigate('/member-list', { state: {user:user,userRole:userRole,memberType:'CAM'}});
   } 

   const navigateToMarketing = (e)=>{
    e.preventDefault();
    navigate('/member-list', { state: {user:user,userRole:userRole,memberType:'MAM'}});
   } 
   const navigateToTeamManager = (e)=>{
    e.preventDefault();
    navigate('/member-list', { state: {user:user,userRole:userRole,memberType:'TM'}});
   } 

   const navigateToCAMManager = (e)=>{
    e.preventDefault();
    navigate('/member-list', { state: {user:user,userRole:userRole,memberType:'CAM MANAGER'}});
   } 

   const navigateToCallManager = (e)=>{
    e.preventDefault();
    navigate('/member-list', { state: {user:user,userRole:userRole,memberType:'CEM'}});
   } 

   const navigateToAccountManager = (e)=>{
    e.preventDefault();
    navigate('/member-list', { state: {user:user,userRole:userRole,memberType:'ACM'}});
   } 

   const navigateToManager = (e)=>{
    e.preventDefault();
    navigate('/member-list', { state: {user:user,userRole:userRole,memberType:'IAM'}});
   } 

   const navigateToSDM = (e)=>{
    e.preventDefault();
    navigate('/member-list', { state: {user:user,userRole:userRole,memberType:'SDM'}});
   } 

   const image = !userRole.profile_image?require("../../resources/images/Avatar.png"):userRole?.profile_image;
    return (
        <div className='account_page py-5 mt-5'>
            <div className='container'>
                {/* <div class="main-heading text-white text-opacity-75 mb-4">Account Details</div> */}
                <div class="row">
                    <div class="col-lg-3 col-sm-12 mb-4 mb-lg-0">
                        <div class="card h-100 text-center">
                            <div class="card-body pb-5 px-xl-4">
                                <div className="user-img d-flex align-items-center justify-content-center rounded-circle border border-2 shadow overflow-hidden mx-auto mb-3" style={{ height: "100px", width: "100px",marginTop: '-70px', }}>
                                    {/* <div className='user-shortname fs-3'>VA</div> */}
                                    <img className="w-100 h-100" src={image} alt="User Image" />
                                </div>
                                <div class="card-title fs-4 text-capitalize">{user?.displayName}</div>
                                <div class="d-grid card-text small text-capitalize mb-2 pb-1"> 
                                    {/* <span class="office-location">NSEZ</span> */}
                                </div>
                                <div class="card-text small mb-4"><i class="bi bi-envelope"></i> {user?.email}</div>
                                <div class="pt-1">
                                    <a class="btn btn-success rounded-1 py-2 px-4" onClick={logoutHandler}><i class="bi bi-box-arrow-right me-1" ></i> Sign out everywhere</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 mb-4 mb-md-0">
                        <div class="card h-100 text-center">
                            <div class="card-body py-5 px-xl-4">
                                <div class="d-grid card-title fs-4 text-capitalize mb-3">Add the BDM 
                                    <span><i class="bi bi-person-plus lh-1 fs-1"></i></span>
                                </div>
                                <div class="card-text small mb-4">Add the Business Development Manager (BDM) role, as they are responsible for engaging in live calls with clients, creating proposals and timelines, and identifying suitable resources from the global bench in real time.</div>
                                <a href="#" onClick={navigateToMember} class="btn btn-primary rounded-1 py-2 px-4">Add Member <i class="bi bi-chevron-right"></i></a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <div class="card h-100 text-center">
                            <div class="card-body py-5 px-xl-4">
                                <div class="d-grid card-title fs-4 text-capitalize mb-3">Add the CAM 
                                    <span><i class="bi bi-database-add lh-1 fs-1"></i></span>
                                </div>
                                <div class="card-text small mb-4">(Client Account Manager) role, as they represent the company's front face and are accountable for promptly joining live calls once clients begin filling out lead forms.</div>
                                <a href="#" onClick={navigateToCamM} class="btn btn-primary rounded-1 py-2 px-4">Add Member <i class="bi bi-chevron-right"></i></a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <div class="card h-100 text-center">
                            <div class="card-body py-5 px-xl-4">
                                <div class="d-grid card-title fs-4 text-capitalize mb-3">Add the MAM 
                                    <span><i class="bi bi-database-add lh-1 fs-1"></i></span>
                                </div>
                                <div class="card-text small mb-4">(Marketing Manager) role, as they represent the company's front face and are accountable for promptly joining live calls once clients begin filling out lead forms.</div>
                                <a href="#" onClick={navigateToMarketing} class="btn btn-primary rounded-1 py-2 px-4">Add Member <i class="bi bi-chevron-right"></i></a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 mt-2">
                        <div class="card h-100 text-center">
                            <div class="card-body py-5 px-xl-4">
                                <div class="d-grid card-title fs-4 text-capitalize mb-3">Add the TM 
                                    <span><i class="bi bi-database-add lh-1 fs-1"></i></span>
                                </div>
                                <div class="card-text small mb-4">(Team Manager) role.</div>
                                <a href="#" onClick={navigateToTeamManager} class="btn btn-primary rounded-1 py-2 px-4">Add Member <i class="bi bi-chevron-right"></i></a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-6 mt-2">
                        <div class="card h-100 text-center">
                            <div class="card-body py-5 px-xl-4">
                                <div class="d-grid card-title fs-4 text-capitalize mb-3">Add the CAM Manager 
                                    <span><i class="bi bi-database-add lh-1 fs-1"></i></span>
                                </div>
                                <div class="card-text small mb-4">(CAM Manager) role.</div>
                                <a href="#" onClick={navigateToCAMManager} class="btn btn-primary rounded-1 py-2 px-4">Add Member <i class="bi bi-chevron-right"></i></a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-6 mt-2">
                        <div class="card h-100 text-center">
                            <div class="card-body py-5 px-xl-4">
                                <div class="d-grid card-title fs-4 text-capitalize mb-3">Add the CEM 
                                    <span><i class="bi bi-database-add lh-1 fs-1"></i></span>
                                </div>
                                <div class="card-text small mb-4">(Client Engagement Manager) role involves making follow-up calls to existing clients for feedback on service and resource performance. Or this role would be for contacting leads and lost opportunities to understand their status and explore future potential.</div>
                                <a href="#" onClick={navigateToCallManager} class="btn btn-primary rounded-1 py-2 px-4">Add Member <i class="bi bi-chevron-right"></i></a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-6 mt-2">
                        <div class="card h-100 text-center">
                            <div class="card-body py-5 px-xl-4">
                                <div class="d-grid card-title fs-4 text-capitalize mb-3">Add the ACM 
                                    <span><i class="bi bi-database-add lh-1 fs-1"></i></span>
                                </div>
                                <div class="card-text small mb-4">(Account Credit Manager) is responsible for approving pending requests and thoroughly cross-verifies all provided information to ensure accuracy and compliance before approving the request.</div>
                                <a href="#" onClick={navigateToAccountManager} class="btn btn-primary rounded-1 py-2 px-4">Add Member <i class="bi bi-chevron-right"></i></a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-6 mt-2">
                        <div class="card h-100 text-center">
                            <div class="card-body py-5 px-xl-4">
                                <div class="d-grid card-title fs-4 text-capitalize mb-3">Add the IAM 
                                    <span><i class="bi bi-database-add lh-1 fs-1"></i></span>
                                </div>
                                <div class="card-text small mb-4">(Incentive Approver Manager) is responsible for approving pending requests and thoroughly cross-verifies all provided information to ensure accuracy and compliance before approving the request.</div>
                                <a href="#" onClick={navigateToManager} class="btn btn-primary rounded-1 py-2 px-4">Add Member <i class="bi bi-chevron-right"></i></a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-6 mt-2">
                        <div class="card h-100 text-center">
                            <div class="card-body py-5 px-xl-4">
                                <div class="d-grid card-title fs-4 text-capitalize mb-3">Add the SDM 
                                    <span><i class="bi bi-database-add lh-1 fs-1"></i></span>
                                </div>
                                <div class="card-text small mb-4">(Service Delivery Manager) is responsible for manage SLAs, handle incident and problem management, coordinate with technical teams, and ensure continuous service improvement.</div>
                                <a href="#" onClick={navigateToSDM} class="btn btn-primary rounded-1 py-2 px-4">Add Member <i class="bi bi-chevron-right"></i></a>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        setLoggedinUser: (user) => dispatch(setUserInfo(user))
    };
  };
  
  const mapStoreStateToProps = (state) => {
    return {
      ...state,
    };
  };
  
  export default connect(
    mapStoreStateToProps,
    mapDispatchToProps
  )(ViewAccount);