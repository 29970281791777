export const LoaderSDM = () =>{

   
        return(
            <div className="loader-overlay" id="loader" style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            height: '100vh',
            background: 'rgba(0, 0, 0, 0.8)',
            backdropFilter: 'blur(2px)'
        }}>
            <div className="loader-sdm-update"></div>
    
            <div className="text-center w-50">
               <p>Please wait ....</p>
               
            </div>
    
        </div>
        )
 
}