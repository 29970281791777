import React, { useState } from "react";
import { Button } from 'react-bootstrap'
import { ManagementApprovalModal } from "../ManagementApprovalModal";
import { nm_servicestatus,new_servicetype,nm__clienttype, nm_servicemonth, nm_paymentstatus, new_servicetype_mapping } from "../../utils/codes";
import { callAxios, convertReadable, convertReadableTime } from "../../utils/utils";
import { ManagementRejectModal } from "../ManagementRejectModal";

import { toast } from "react-toastify";
import { environment } from "../../environment";


export const Installmentinfo = ({ installmentData, DateFormatefunc, DateFormatefuncdata, type, opportunityDetailsdata,firstInstallmentData,by,userRole,reapprove, setLoading, user,topic }) => {
    // console.log("reapprove::",reapprove)
    // Management Approval modal

     const [crmRealData, setCrmData] = useState({})
      const [isPLoading, setIsPLoading] = useState(false)
    const [showManagementApprovalModal, setShowManagementApprovalModal] = useState(false);
    const closeManagementApprovalModal = () => {
        setCrmData([])
        setShowManagementApprovalModal(false);
    }
    const openManagementApprovalModal = () => setShowManagementApprovalModal(true);

    // Management Reject modal
const [showManagementRejectModal, setShowManagementRejectModal] = useState(false);
const closeManagementRejectModal = () => setShowManagementRejectModal(false);
const openManagementRejectModal = () => setShowManagementRejectModal(true);

const [currentItem_, setCurrentItem_] = useState({})

    function getRandomInteger(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }
    
    // Example usage:
    let randomInt = getRandomInteger(1, 10);

    const [opId, setOPId] = useState('');
    const [invoiceId, setInvoiceId] = useState('');
    const [randNo, setRanNo] = useState(null);
    const [currentItem, setCurrentItem] = useState({});

    const openManagementApprovalModal_ = async(opid, invoiceId,randomInt,item)=>{
        
        const installment1 = installmentData.find(item => item.installment === 1);
        if(item?.installment > 1 && !installment1?.is_bdm_approved_by_management){
            toast.error("You need to do approve 1st installment first", {
                                position: "top-right",
                                autoClose: 2000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                                });
            return false
        } 
        setLoading(true)
        callAxios(`${environment.REACT_APP_API_URL_Live}api/v1/wallet/management-approval-popup`,{opId:opid,invoiceId:invoiceId,type:type},"post").then((crmData)=>{
                    if(crmData.status === 200){
                        
                    setCrmData(crmData.data)
                    setLoading(false)
                    }
                    
                })
        setOPId(opid);
        setInvoiceId(invoiceId);
        setRanNo(randomInt)
        setCurrentItem(item)
        openManagementApprovalModal()
    }

    const openManagementRejectModal_= async(opid, invoiceId,randomInt,item) =>{
        setCurrentItem_(item)
        openManagementRejectModal()
    }
    return (
        <>
            <div className="description">
                <div className="total-insentive d-flex flex-wrap row-gap-1 column-gap-3 lh-sm mb-3">
                <span className="fs-5 fw-semibold text-white">Total Incentive: ₹{installmentData[0]?.incentive_object?.total_bonus}</span>
                <span className="fs-18 fw-semibold text-white text-opacity-50 text-capitalize">Incentive Type: {installmentData[0]?.incentive_object?.scenario} ({installmentData[0]?.incentive_object?.client_type})</span>
                </div>
                <div className="installment-wrapper d-flex flex-column gap-2">
                    {/* 1st Installment Info */}
                    {installmentData?.map((items, index) => (
                        <div className="installment-info custom-bg rounded-3 p-3" style={{ backgroundColor: 'var(--custom-bg)' }}>
                            <div className="installment-info mb-3">
                                <div className="label fs-18 text-white">
                                    {items?.installment === 1 ? "1st "
                                    : items?.installment === 2 ? "2nd "
                                     : items?.installment === 3 ? "3rd "
                                      : items?.installment === 4 ? "4th " :null}
                                    
                                    Installment of Incentive</div>
                                <div className="value fs-12 text-white text-opacity-75">
                                    The redeem button will be enabled once the client is won, payment is received,
                                    billing is assigned to the Billing Manager and SDM (Service Lead) on NM-Apps,
                                    and the resource has started work on the billing.
                                </div>
                            </div>
                            <div className="row gx-2 gy-3">
                                <div className="col-12 col-md-4 col-lg-3 info-box text-capitalize">
                                    <div className="label fs-14 text-warning">Billing Manager</div>
                                    <div className="value text-white text-opacity-75 text-capitalize">{items?.billing_manager}</div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-2 info-box text-capitalize">
                                    <div className="label fs-14 text-warning">Service Delivery Manager <sup>2</sup></div>
                                    <div className="value text-white text-opacity-75">{items?.sdm}</div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-3 info-box text-capitalize">
                                    <div className="label fs-14 text-warning">Front Face on Billing <sup>1</sup></div>
                                    <div className="value text-white text-opacity-75">{items?.front_face}</div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-2 info-box text-capitalize">
                                    <div className="label fs-14 text-warning">Service Type</div>
                                    <div className="value text-white text-opacity-75">{new_servicetype_mapping[items?.service_type]}</div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-2 info-box text-capitalize">
                                    <div className="label fs-14 text-warning">Service Month</div>
                                    <div className="value text-white text-opacity-75">{nm_servicemonth[items?.service_month]}</div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-3 info-box text-capitalize">
                                    <div className="label fs-14 text-warning">Client ID</div>
                                    <div className="value text-white text-opacity-75">{items?.client_id}</div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-2 info-box text-capitalize">
                                    <div className="label fs-14 text-warning">Client Type</div>
                                    <div className="value text-white text-opacity-75">{nm__clienttype[items?.client_type].replace(/\s+\S+$/, '')}</div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-3 info-box text-capitalize">
                                    <div className="label fs-14 text-warning">Service Status</div>
                                    <div className="value" style={{ color: '#03ff85' }}>{nm_servicestatus[items?.service_status]}</div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-2 info-box text-capitalize">
                                    <div className="label fs-14 text-warning">Invoice ID</div>
                                    <div className="value text-white text-opacity-75">{items?.invoice_id}</div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-2 info-box text-capitalize">
                                    <div className="label fs-14 text-warning">Invoice From</div>
                                    <div className="value text-white text-opacity-75">{items?.invoice_from}</div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-3 info-box text-capitalize">
                                    <div className="label fs-14 text-warning">Hour based on Receive Payment</div>
                                    <div className="value text-white text-opacity-75">{items?.hours_based_on_payment}</div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-2 info-box text-capitalize">
                                    <div className="label fs-14 text-warning">Payment Status</div>
                                    <div className="value text-white text-opacity-75">{nm_paymentstatus[items?.payment_status]}</div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-3 info-box text-capitalize">
                                    <div className="label fs-14 text-warning">Payment Receive Status</div>
                                    <div className="value text-white text-opacity-75">{DateFormatefuncdata(items?.payment_received_date)}</div>
                                </div>
                            </div>
                            <div className="row gy-3 mt-1">
                                <div className="col-lg-7 col-xl-8">
                                    <div className="fs-12 text-white text-opacity-75 mb-2">1. "Front Face" means the client is familiar with them. If it's different from the billing resource, it means the billing resource is acting as their proxy.</div>
                                    <div className="fs-12 text-white text-opacity-75">2. The Service Delivery Manager (SDM) is responsible for managing billing hours with the client and coordinating with the working resource to ensure smooth delivery of billing hours to the client.</div>
                                </div>
                                {items?.is_bdm_approved_by_management && items?.is_installment_approved ===1?
                                    <div className="col-lg-5 col-xl-4">
                                        <div className="d-flex justify-content-lg-end">
                                            <div className="text-lg-end">
                                                <span className="d-block fs-5 fw-semibold text-white">Approved ₹{items?.amount} on {convertReadable(items?.approved_date)} {convertReadableTime(items?.approved_date)}</span>
                                                {items?.is_requested_for_accounts?
                                                <span className="d-block fs-6 fw-semibold text-white text-opacity-50">₹{items?.is_share_your_incentive?items?.redeem_amount:items?.amount} was redeemed on {convertReadable(items?.redeem_datetime)} {convertReadableTime(items?.redeem_datetime)}, By {items?.type}</span>
                                                :null}
                                            </div>
                                        </div>
                                    </div>
                                    : reapprove?
                                    <div className="col-lg-4 text-lg-end">
                                        <div className="d-flex flex-wrap align-items-center justify-content-lg-end gap-2">
                                        
                                            <Button variant="success" className="text-center rounded-1 px-4 py-2" onClick={()=>{openManagementApprovalModal_(items?.opportunityid, items?.invoice_id,randomInt,items)}} style={{ minWidth: '240px' }}>
                                                <span className="d-block fs-5 fw-semibold text-white">Re-Approve ₹{items?.amount}</span>
                                            </Button>
                                        </div>
                                    </div>
                                    
                                    :items?.is_installment_approved ===2?
                                    <div className="col-lg-5 col-xl-4">
                                        <div className="d-flex justify-content-lg-end">
                                            <div className="text-lg-end">
                                                <span className="d-block fs-5 fw-semibold text-danger">Rejected</span>
                                                
                                                <span className="d-block fs-6 fw-semibold text-white text-opacity-50">{items?.reason} </span>
                                                
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className="col-lg-4 text-lg-end">
                                        <div className="d-flex flex-wrap align-items-center justify-content-lg-end gap-2">
                                        <span className="insentive-status fs-5 fw-semibold text-white pointer me-3" onClick={()=>{openManagementRejectModal_(items?.opportunityid, items?.invoice_id,randomInt,items)}}>Reject</span>
                                            <Button variant="success" className="text-center rounded-1 px-4 py-2" onClick={()=>{openManagementApprovalModal_(items?.opportunityid, items?.invoice_id,randomInt,items)}} style={{ minWidth: '240px' }}>
                                                <span className="d-block fs-5 fw-semibold text-white">Approve ₹{items?.amount}</span>
                                            </Button>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    ))}
                </div>
                {/* Management Approval Modal */}
                {crmRealData && randNo && crmRealData?.billingDetails?
                <ManagementApprovalModal show={showManagementApprovalModal} close={closeManagementApprovalModal} opId={opId} invoiceId={invoiceId} randNo={randNo} incentiveObject={installmentData[0]?.incentive_object} type={type} currentItem={currentItem} setShowManagementApprovalModal={setShowManagementApprovalModal} opportunityDetailsdata={opportunityDetailsdata} firstInstallmentData={firstInstallmentData} by={by} userRole={userRole} setCrmData={setCrmData} crmRealData={crmRealData} isPLoading={isPLoading} setIsPLoading={setIsPLoading} user={user} topic={topic}/>
                :null}
            
                    {/* Management Reject Modal */}
                <ManagementRejectModal show={showManagementRejectModal} close={closeManagementRejectModal} currentItem={currentItem_} setShowManagementRejectModal={setShowManagementRejectModal} by={by} userRole={userRole} opportunityDetailsdata={opportunityDetailsdata}/>
            </div>
        </>
    )
}