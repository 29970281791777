import { ProgressBar } from 'react-bootstrap'

export const Loader = ({isNeedProgressBar,progress}) =>{

    if(isNeedProgressBar){
        return(
            <div className="loader-overlay" id="loader" style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            height: '100vh',
            background: 'rgba(0, 0, 0, 0.8)',
            backdropFilter: 'blur(2px)'
        }}>
            <img src={require('../resources/images/wallet/walletpreloader.gif')} alt="Balance" className="img-fluid" style={{ maxWidth: '200px', margin: "0 auto" }} />
    
            <div className="text-center w-50">
               <p>Please wait ....</p>
               <p><ProgressBar animated now={progress} label={`${progress}%`}/></p>
            </div>
    
        </div>
        )
    } else{
        return(
            <div className="loader-overlay" id="loader" style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            height: '100vh',
            background: 'rgba(0, 0, 0, 0.8)',
            backdropFilter: 'blur(2px)'
        }}>
            <img src={require('../resources/images/wallet/walletpreloader.gif')} alt="Balance" className="img-fluid" style={{ maxWidth: '200px', margin: "0 auto" }} />
    
            <div className="text-center w-50">
               <p>Please wait ....</p>
               
            </div>
    
        </div>
        )
    }
   
    
}