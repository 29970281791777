import React, { useEffect, useState } from "react";
import './wallet.scss';
import { Accordion, Button, Card, Form, InputGroup, ProgressBar, useAccordionButton } from 'react-bootstrap'
import SearchIcon from '../resources/images/search.svg';
import { RedeemInsentiveModal } from "./RedeemInsentiveModal";
import { RedeemAmountModal } from "./RedeemAmountModal";
import { setUserInfo } from "../store/actions";
import { connect } from "react-redux";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebaseConfig";
import { useLocation, useNavigate } from "react-router-dom";
import { callAxios, formatDateToMonthYear, log__ } from "../utils/utils";
import { environment } from "../environment";
import { new_ClientType, new_opportunitytype, statuscode } from "../utils/codes";
import { toast } from "react-toastify";
import { Inprogress } from "./cards/Inprogress";
import { IncentivePolicy } from "../utils/IncentivePolicy";
import { ShareCard } from "./cards/ShareCard";
import { InvalidPolicy } from "./cards/InvalidPolicy";
import { InstallmentWithCredit } from "./cards/InstallmentWithCredit";
import { WonCard } from "./cards/WonCard";
import { DisputeCard } from "./cards/DisputeCard";
import { ServiceWaitingCard } from "./cards/ServiceWaitingCard";
import { LostCard } from "./cards/LostCard";
import { InprogressCard } from "./cards/InprogressCard";
import { SearchCard } from "./cards/SearchCard";
import { BeforeOctober } from "./cards/BeforeOctober";


// Custom Accordion
function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
        log__('totally custom!'),
    );

    return (
        <div className="custom-bg" onClick={decoratedOnClick}>
            {children}
        </div>
    );
}

const Wallet = ({ setLoggedinUser, userRole }) => {
    // const [selected, setSelected] = useState('BDM');
    const [type, setType] = useState([]);
    const [hideFilter, setHideFiler] = useState(true)
    const [hideDropFilter, setHideDropFiler] = useState(true)
    const [filter, setFilter] = useState('all');
    const {state} = useLocation();
    const [selected, setSelected] = useState(state?.tab || 'BDM');
    const [URole, setURole] = useState('');
    
const handleFilterOption = async(value)=>{
    setFilter(value)
    if(value === "disputed"){
        setType(['disputed'])
    } else{
        if(value === "all"){
            setType(['individual','invalidpolicy','beforeOct24','disputed'])
         
        } 
        
    }
   
    
}
    // console.log("filter value::", filter)
    useEffect(()=>{
        if(state?.filterOption ==="disputed"){
            setType(['disputed'])
            setFilter('disputed')
        }
        else if(state?.filterOption === "redeem-pending"){
            setType(['individual'])
            setFilter('redeem-pending')
        }
        else if(state?.filterOption === "pending-on-accounts"){
            setType(['individual'])
            setFilter('pending-on-accounts')
        }
        else if(state?.filterOption === "amount-transferred"){
            setType(['individual'])
            setFilter('amount-transferred')
        }
        
        else if(state?.tab === "BDM"){
            setSelected(state.tab); // Update state if state?.type changes dynamically
            setType(['individual','invalidpolicy','beforeOct24','disputed'])
            setFilter('all')
        } else if(state?.tab === "SDM"){
            setHideFiler(false)
            setType(['sdm'])
            setFilter('all')
        } 
        else if(state?.tab === "SBDM"){
            setHideFiler(false)
            setType(['shared'])
            setFilter('all')
        }
        else{
            setHideFiler(false)
            setType(['individual','invalidpolicy','beforeOct24','disputed'])
            setFilter('all')
        }

        const URole = userRole? userRole : state?.userRole
        setURole(URole)
    },[])

    // console.log(URole)
   
    const handleSelect = (role,filter) => {
        // log__(role)
        setFilter(filter)
        setSelected(role); // Set the selected role when a button is clicked
        if(role === "BDM"){
             setFilter('all')
            setHideFiler(true)
            setType(['individual','invalidpolicy','beforeOct24','disputed'])
            //  loadWallet(URole,['individual','invalidpolicy','beforeOct24','disputed'],filter)
        }
        if(role === "SDM"){
            setHideFiler(false)
            setType(['sdm'])
             setFilter('all')
            //  loadWallet(URole,['sdm'],filter)
        }
        if(role === "SBDM"){
            setHideFiler(false)
            setType(['shared'])
              setFilter('all')
            //  loadWallet(URole,['shared'],filter)
        }

        
    };
// console.log(type) 
    // Redeem amount modal
    const [showRedeemAmountModal, setShowRedeemAmountModal] = useState(false);
    const closeRedeemAmountModal = () => setShowRedeemAmountModal(false);
    const openRedeemAmountModal = () => setShowRedeemAmountModal(true);

    // Add overlay when click on search to add field
    const [isToggled, setIsToggled] = useState(false);

    const handleToggle = () => {
        setIsToggled((prev) => !prev);
    };

    useEffect(() => {
        if (isToggled) {
            document.body.classList.add('toggled-class'); // Add class
        } else {
            document.body.classList.remove('toggled-class'); // Remove class
        }

        // Clean up the effect on unmount
        return () => {
            document.body.classList.remove('toggled-class');
        };
    }, [isToggled]);

    const [user, loading, error] = useAuthState(auth);
    let navigate = useNavigate();
    const [activeKey, setActiveKey] = useState(null);
    const [addingLoader, setAddingLoader] = useState(false)
     const [isLoading, setIsLoading] = useState(true);
     const [isFullLoading, setIsFullLoading] = useState(false);
     const [bonus, setBonus] = useState({})
     
     const [incentivetype, setIncentiveType] = useState("")
     const [searchLoading, setSearchLoading] = useState(false);

    const [walletInfo, setWalletInfo] = useState({});
    const [myOpportunity, setMyOpportunity] = useState([]);
    const [searchResults, setSearchResults] = useState([]);
    const [topic, setTopic] = useState("");
    const [opRealTime, setOpRealTimeData] = useState([]);
     const [progress, setProgress] = useState(0);

    const setPolicy = (type, userRole) =>{
        //  log__(type, userRole)
        const getBonus = IncentivePolicy[0][userRole][type];
        // log__(getBonus)
        setIncentiveType(type)
        setBonus(getBonus)
    }
    const loadWallet = (userRole,type,filter, isRedeemed=null) =>{
        setIsLoading(true)
        setMyOpportunity([])
        const progressInterval = setInterval(() => {

            setProgress((prev) => (prev < 90 ? prev + 1 : prev)); // Increment by 1%

          }, 900);
        setOpRealTimeData([])
        callAxios(`${environment.REACT_APP_API_URL_Live}api/v1/wallet/create-wallet`,{userType:userRole,startDate, endDate, card_type:type, filter:filter},"post").then((res)=>{
            setProgress(100);

            setTimeout(() => {
                clearInterval(progressInterval);
                setProgress(0);
           
            if(res.data.OpportunityList.length === 0){
                setWalletInfo(res.data.oData)
            } else{
                setWalletInfo(res.data.OpportunityList[0].__wallet)
            }
           
            setAddingLoader(false)
            const groupedData = res.data.OpportunityList.reduce((acc, item) => {
                            // Check if the firstBillingMonth is "Un-Billed"
                            const monthYear = item.firstBillingMonth === "Opportunity not won" 
                                ? "Opportunity not won" 
                                : formatDateToMonthYear(item.firstBillingMonth); // You can also use updatedAt if applicable
                        
                            // Initialize the group if it doesn't exist
                            if (!acc[monthYear]) {
                                acc[monthYear] = [];
                            }
                        
                            // Add the item to the respective group
                            acc[monthYear].push(item);
                        
                            return acc;
                        }, {});
            
                        const sortedEntries = Object.entries(groupedData).sort(([keyA], [keyB]) => {
                            if (keyA === "Opportunity not won") return 1; // Move "Un-Billed" to the bottom
                            if (keyB === "Opportunity not won") return -1; // Move other keys above "Un-Billed"
                            const dateA = new Date(keyA);
                            const dateB = new Date(keyB);
                            return dateB - dateA; // Sort in reverse chronological order
                          });

            const sortedData = Object.fromEntries(sortedEntries);
            
            setMyOpportunity(sortedData)

            setOpRealTimeData(sortedData)
            
           
            setIsLoading(false)
            // console.log(isRedeemed)
            if(isRedeemed === "Redeemed"){
                toast.success("Incentive redeemed successfully!", {
                                        position: "top-right",
                                        autoClose: 2000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                        theme: "light",
                                      });
            }
            
        }, 1000);

          })
    }
    // log__(myOpportunity)
    useEffect(() => {
        if (loading) return;
        if (!user) navigate("/");
        else {
    
          const userInfo = {
            uid: user?.uid,
            email: user?.email,
            displayName: user?.displayName,
            accessToken: user?.accessToken,
            idToken: user?.idToken,
          };
    
          setLoggedinUser(userInfo);
        //   loadWallet(userRole);
        // loadWallet(URole,type);
    
        }
      }, [user, loading, URole]);

      useEffect(()=>{
        // console.log("calling useEEE")
        if(type.length >0){
            loadWallet(URole,type,filter);
        }
        
      },[URole,filter,type])

      const handleLookup = () =>{
        if(topic.length > 0){
            setSearchLoading(true)
        callAxios(`${environment.REACT_APP_API_URL_Live}api/v1/wallet/search-opportunity`,{searchString:topic},"post").then((res)=>{
            // log__(res.data.oData)
            if(res.status === 200){
                if(res.data.statuscode === 200){
                    document.body.classList.add('toggled-class');
                    setSearchResults(res.data.oData)
                    setSearchLoading(false)
                } else{
                    setSearchLoading(false)
                    document.body.classList.remove('toggled-class')
                    toast.error(res.data.message, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                      });
                }
            }
            
        })
        } else{
            document.body.classList.remove('toggled-class');
            toast.error("Enter Opportunity ID first...", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
        }
        
      }
    //   log__("type length:::", incentivetype)

    const resetSearch = () =>{
        document.body.classList.remove('toggled-class');
        setSearchResults([])
        setSearchLoading(false)
        setBonus({})
        document.getElementById("search-op-incentive").value = "";
      }

      let searchHtml;

      searchHtml =  searchResults && searchResults.length > 0 && searchResults.map((item)=>{
        
       return <SearchCard resetSearch={resetSearch} item={item} bonus={bonus} setPolicy={setPolicy} userRole={URole} incentivetype={incentivetype} setIncentiveType={setIncentiveType} setSearchResults={setSearchResults} setSearchLoading={setSearchLoading} setBonus={setBonus}  type={type} loadWallet={loadWallet} setIsFullLoading={setIsFullLoading}/>
      })

      
     
      
       
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Prevent form submission if inside a form
            handleLookup();
        }
    };

    const [startDate, setStartDate] = useState(() => {
        const startDD = state?.startDate?state.startDate:`${new Date().getFullYear()}-${String(new Date().getMonth() + 1).padStart(2, '0')}-01`;
        return startDD;
      });
      const [endDate, setEndDate] = useState(() => {
          const date = new Date();
          const syear = date.getFullYear();
          const smonth = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
          const sday = String(date.getDate()).padStart(2, '0');
          return state?.endDate?state.endDate:`${syear}-${smonth}-${sday}`;
      });

      const handalSearch = async() =>{
        loadWallet(URole,type,filter)
      }
// log__("real opportunity::",opRealTime)

// const showCards = opRealTime.map((opportunity, oppIndex) => {
//     if(opportunity.statuscode === 3){

//         return <WonCard key={oppIndex} index={oppIndex} opInfo={opportunity} openRedeemAmountModal={openRedeemAmountModal} user={user} userRole={userRole} loadWallet={loadWallet} setIsLoading={setIsLoading} opRealTime={opRealTime} setActiveKey={setActiveKey} activeKey={activeKey}/>

//     } else if([1,2,100000001,100000002,100000003,100000004,693980000,5].includes(opportunity.statuscode)){

//         return <InprogressCard key={oppIndex} index={oppIndex} opInfo={opportunity} openRedeemAmountModal={openRedeemAmountModal} user={user} userRole={userRole} loadWallet={loadWallet} setIsLoading={setIsLoading} opRealTime={opRealTime} setActiveKey={setActiveKey} activeKey={activeKey} />

//     } else if(opportunity.statuscode === 4){

//         return <LostCard key={oppIndex} index={oppIndex} opInfo={opportunity} openRedeemAmountModal={openRedeemAmountModal} user={user} userRole={userRole} loadWallet={loadWallet} setIsLoading={setIsLoading} opRealTime={opRealTime} setActiveKey={setActiveKey} activeKey={activeKey}/>

//     } else if(opportunity.card_type === "shared"){

//         return <ShareCard key={oppIndex} index={oppIndex} opInfo={opportunity} openRedeemAmountModal={openRedeemAmountModal} user={user} userRole={userRole} loadWallet={loadWallet} setIsLoading={setIsLoading} opRealTime={opRealTime} setActiveKey={setActiveKey} activeKey={activeKey}/>

//     } else if(opportunity.card_type === "invalidpolicy"){

//          return <InvalidPolicy key={oppIndex} index={oppIndex} opInfo={opportunity} openRedeemAmountModal={openRedeemAmountModal} user={user} userRole={userRole} loadWallet={loadWallet} setIsLoading={setIsLoading} opRealTime={opRealTime} setActiveKey={setActiveKey} activeKey={activeKey}/>

//     } else if(opportunity.card_type === "beforeOct24"){

//         return null

//     } else {
        
//         return null;
//     }
// })





// const showCards = Object.entries(myOpportunity).map(([key, opportunities], index) => {
//     return opportunities.map((opportunity, oppIndex) => {
//         const commonProps = {
//             key: `${index}-${oppIndex}`, // Ensure unique keys using both indexes
//             index: oppIndex,
//             opInfo: opportunity,
//             openRedeemAmountModal,
//             user,
//             userRole,
//             loadWallet,
//             setIsLoading,
//             opRealTime,
//             setActiveKey,
//             activeKey,
//         };

//         if (opportunity.statuscode === 3) {
//             return <WonCard {...commonProps} />;
//         } else if ([1, 2, 100000001, 100000002, 100000003, 100000004, 693980000, 5].includes(opportunity.statuscode)) {
//             return <InprogressCard {...commonProps} />;
//         } else if (opportunity.statuscode === 4) {
//             return <LostCard {...commonProps} />;
//         } else if (opportunity.card_type === "shared") {
//             return <ShareCard {...commonProps} />;
//         } else if (opportunity.card_type === "invalidpolicy") {
//             return <InvalidPolicy {...commonProps} />;
//         } else if (opportunity.card_type === "beforeOct24") {
//             return null;
//         } else {
//             return null;
//         }
//     });
// });

const backtoDashboardWallet = (e)=>{
    e.preventDefault();
    navigate("/wallet-dashboard")
  }
    return (
        <div className="Wallet-page py-3">
            {isFullLoading? <div className="loading" style={{zIndex:9999}}>Loading&#8230;</div>:null}
            <div className="container-fluid px-xxl-5">
                <div className="d-flex flex-wrap flex-column flex-md-row align-items-md-center justify-content-between gap-2 mb-3">
                    {/* <div className="fs-3 fw-semibold text-light lh-sm text-nowrap">Wallet</div> */}
                    <Button variant="light" type="" className="bg-white border-0 btn btn-light text-center px-2 p-1" style={{ width: '40px' }} onClick={backtoDashboardWallet}>
                        <i className="bi bi-arrow-left fs-4 lh-sm"></i>
                    </Button>
                    <div className="selector-button d-flex flex-wrap gap-1 border border-1 border-secondary rounded-3 px-2 py-1">
                        <Button variant="primary" type="button" className="fw-medium border-0 py-2" onClick={() => handleSelect('BDM',filter)} style={{ backgroundColor: selected === 'BDM' ? '#333de5' : 'transparent', opacity: selected === 'BDM' ? '100' : '0.6', }}>Me as BDM</Button>
                        <Button variant="primary" type="button" className="fw-medium border-0 py-2" onClick={() => handleSelect('SDM',filter)} style={{ backgroundColor: selected === 'SDM' ? '#333de5' : 'transparent', opacity: selected === 'SDM' ? '100' : '0.6', }}>Me as SDM</Button>
                        <Button variant="primary" type="button" className="fw-medium border-0 py-2" onClick={() => handleSelect('SBDM',filter)} style={{ backgroundColor: selected === 'SBDM' ? '#333de5' : 'transparent', opacity: selected === 'SBDM' ? '100' : '0.6', }}>Me as Supported BDM</Button>
                    </div>
                    <div className="date-wrapper d-flex flex-wrap align-items-md-center gap-2 gap-lg-3">
                        <Form.Group className="d-flex flex-column flex-md-row align-items-md-center flex-fill gap-1 gap-md-2 gap-xl-3" controlId="dateFrom">
                            <Form.Label className="m-0">From</Form.Label>
                            <Form.Control type="date" className="bg-transparent py-2"
                                defaultValue={state?.startDate?state?.startDate:`${new Date().getFullYear()}-${String(new Date().getMonth() + 1).padStart(2, '0')}-01`}
                                onChange={(e) => setStartDate(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="d-flex flex-column flex-md-row align-items-md-center flex-fill gap-1 gap-md-2 gap-xl-3" controlId="dateTo">
                            <Form.Label className="m-0">To</Form.Label>
                            <Form.Control type="date" className="bg-transparent py-2"
                                defaultValue={state?.endDate?state?.endDate:new Date().toJSON().slice(0, 10)}
                                onChange={(e) => setEndDate(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="d-flex flex-column justify-content-end flex-fill">
                            <Button variant="secondary" className="rounded-2 py-2" onClick={handalSearch}>
                                <img src={SearchIcon} alt="" className="img-fluid align-text-top" />
                                {/* <span className="d-md-none ms-1">Search</span> */}
                            </Button>
                        </Form.Group>
                        {/* <div className="button-container position-relative d-inline-block">
                            <Button variant="primary" id="readButton" className="px-4">Read by</Button>
                            <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger fw-semibold">9</span>
                            <div className="name-list fs-14 text-body bg-white position-absolute top-100 start-0 border rounded overflow-hidden py-1 z-1" id="nameList" style={{ width: '150px' }}>
                                <p className="text-truncate pointer m-0 px-2 py-1">John Doe</p>
                                <p className="text-truncate pointer m-0 px-2 py-1">Jane Smith</p>
                                <p className="text-truncate pointer m-0 px-2 py-1">Mark Johnson</p>
                            </div>
                        </div> */}
                    </div>
                    {type.includes("sdm") || type.includes("shared")?
                    <div className="date-wrapper">
                        <Form.Group className="status-type d-flex flex-column flex-md-row align-items-md-center flex-fill gap-1 gap-md-2 gap-xl-3" controlId="statusType">
                            <Form.Label className="m-0">Status</Form.Label>
                            <Form.Select className="text-light dark-theme-bg py-2" style={{ minWidth: '120px' }} required onChange={(e)=>handleFilterOption(e.target.value)} value={filter}>
                                <option value="" disabled >Status</option>
                                <option value="all" selected>All</option>
                                                                                <option value="redeem-pending" selected>Ready to redeem</option>
                                                                                <option value="pending-on-accounts" selected>Pending on accounts</option>
                                                                                <option value="amount-transferred" selected>Amount transferred</option>
                               

                            </Form.Select>
                        </Form.Group>
                    </div>
                    :
                     <div className="date-wrapper">
                                            <Form.Group className="status-type d-flex flex-column flex-md-row align-items-md-center flex-fill gap-1 gap-md-2 gap-xl-3" controlId="statusType">
                                                <Form.Label className="m-0">Status</Form.Label>
                                               <Form.Select className="text-light dark-theme-bg py-2" data-bs-theme="dark" style={{ minWidth: '120px' }} required onChange={(e)=>handleFilterOption(e.target.value)} value={filter}>
                                                                               <option value="" disabled >Status</option>
                                                                              
                                                                                <option value="all" selected>All</option>
                                <option value="redeem-pending" selected>Ready to redeem</option>
                                <option value="pending-on-accounts" selected>Pending on accounts</option>
                                <option value="amount-transferred" selected>Amount transferred</option>
                                <option value="1">In Progress</option>
                                <option value="3">Won</option>
                                <option value="disputed">Disputed</option>
                                                                               
                                               
                                                                           </Form.Select>
                                            </Form.Group>
                                        </div>
                    }
                </div>

                <div className="wallet-info-section">
                    {hideFilter?
                    <div className="search-wrapper dropdown z-1">
                        <InputGroup className="border border-secondary border-opacity-75 rounded-3 mb-3" type="button" data-bs-toggle="dropdown" aria-expanded="false" onClick={handleToggle}>
                            <Form.Control type="search" id="search-op-incentive" className="bg-transparent border-0 rounded-3 shadow-none py-2 text-white" placeholder="Search with Opportunity ID" onChange={(e)=>{setTopic(e.target.value)}} onKeyDown={handleKeyDown}/>
                            <Button variant="success" id="button-addon2" className="rounded-1 mx-1 my-1" style={{ padding: '6px 40px' }} onClick={handleLookup}>Search</Button>
                        </InputGroup>
                        {searchLoading?<div className="info-card w-100 rounded-3 px-4 py-3"><div className="search-op-loader" style={{margin:"0 auto"}}></div></div>:searchHtml}
                        {/* <SearchCard /> */}
                    </div>
                    :null}
                    
                    <div className="wallet-cards-wrapper d-flex flex-column gap-3">
                        <Accordion defaultActiveKey="0" alwaysOpen className="wallet-cards-wrapper d-flex flex-column gap-3">
                        {isLoading?
                    <div className="loader-overlay" id="loader" style={{

                        display: 'flex',
    
                        flexDirection: 'column',
    
                        alignItems: 'center',
    
                        justifyContent: 'center',
    
                        textAlign: 'center',
    
                        height: '100vh',
    
                        background: 'rgba(0, 0, 0, 0.8)',
    
                        backdropFilter: 'blur(2px)'
    
                    }}>
    
                        <img src={require('../resources/images/wallet/walletpreloader.gif')} alt="Balance" className="img-fluid" style={{ maxWidth: '200px', margin: "0 auto" }} />
    
       
    
                        <div className="text-center w-50">
                        <p>Please wait ....</p>
                        <p><ProgressBar animated now={progress} label={`${progress}%`}/></p>
    
                        </div>
    
       
    
                    </div>
                    : Object.entries(myOpportunity).length === 0?
                    filter === "all"?
                    <span className="text-center text-warning fs-18">No Results found!</span>
                    : filter === 'disputed'?
                    <span className="text-center text-warning fs-18">No disputed result found!</span>
                    :filter === '3'?
                    <span className="text-center text-warning fs-18">The opportunity not found with Won status</span>
                    :filter === '1'?
                    <span className="text-center text-warning fs-18">The opportunity not found with In progress</span>
                    :filter === "redeem-pending"?
                    <span className="text-center text-warning fs-18">No Redeem request found yet!</span>
                    :filter === "amount-transferred"?
                    <span className="text-center text-warning fs-18">No amount transferred found yet!</span>
                    :filter === "pending-on-accounts"?
                    <span className="text-center text-warning fs-18">No pending on account requests found yet!</span>
                    :
                    <span className="text-center text-warning fs-18">The opportunity hasn’t been added yet</span>
                    :
                   
                    Object.entries(myOpportunity).map(([key, opportunities], index) => (
                        <div key={index}>
                             <div className="month-name fw-semibold text-white text-uppercase">{key} | {opportunities.length}</div>
                            {/* {showCards} */}
                            {opportunities.map((opportunity, oppIndex) => {
                    const commonProps = {
                        key: `${index}-${oppIndex}`, // Ensure unique keys using both indexes
                        index: oppIndex,
                        opInfo: opportunity,
                        openRedeemAmountModal,
                        user,
                        URole,
                        loadWallet,
                        setIsLoading,
                        opRealTime,
                        setActiveKey,
                        activeKey,
                    };
                    if(opportunity.otherDisputedCount > 0){
                        return <DisputeCard {...commonProps}  disputer="other"/>;
                    }
                    else if(opportunity.cardType === "disputed"){
                        return <DisputeCard {...commonProps}  disputer="self"/>;
                    }
                    else if (opportunity.cardType === "shared") {
                        return <ShareCard {...commonProps} />;
                    } else if (opportunity.cardType === "invalidpolicy") {
                        return <InvalidPolicy {...commonProps} />;
                    }
                    else if (opportunity.cardType === "beforeOct24") {
                        return <BeforeOctober {...commonProps} />;
                    } 
                    else if (opportunity.statuscode === 3) {
                        return <WonCard {...commonProps} />;
                    } else if ([1, 2, 100000001, 100000002, 100000003, 100000004, 693980000, 5].includes(opportunity.statuscode)) {
                        return <InprogressCard {...commonProps} />;
                    } else if (opportunity.statuscode === 4) {
                        return <LostCard {...commonProps} />;
                    } else {
                        return null;
                    }
                })}
                        </div>
                    ))}
                            {/* <InprogressCard />
                            <LostCard />
                            <ServiceWaitingCard />
                            <DisputeCard />
                            <WonCard />
                            <InstallmentWithCredit />
                            <InvalidPolicy /> */}
                        </Accordion>

                        {/* <ShareCard /> */}
                    </div>

                    {/* Redeem Amount Modal */}
                    <RedeemAmountModal show={showRedeemAmountModal} close={closeRedeemAmountModal} />
                </div>
            </div>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
      setLoggedinUser: (user) => dispatch(setUserInfo(user))
    };
  };
  
  const mapStoreStateToProps = (state) => {
    return {
      ...state,
    };
  };
   
  export default connect(
    mapStoreStateToProps,
    mapDispatchToProps
  )(Wallet);
