import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap'
import { new_opportunitytype, statuscode } from '../../utils/codes';
import { RedeemAmountModal } from '../RedeemAmountModal';
import { set } from 'date-fns';

export const ShareCard = ({key, index, opInfo, user, userRole, loadWallet, setIsLoading, opRealTime, setActiveKey, activeKey})=>{
   const [invoiceHtml, setInvoiceHtml] = useState([]);

   const [showRedeemAmountModal, setShowRedeemAmountModal] = useState(false);
   const closeRedeemAmountModal = () => setShowRedeemAmountModal(false);
   const openRedeemAmountModal = () => setShowRedeemAmountModal(true);

   const [redeemAmount, setredeemAmount] = useState(null);
   const [month, setMonth] = useState(0);
   const [invoiceid, setInvoiceid] = useState('');

// console.log(showRedeemAmountModal)

   const setData = async(item) =>{
    setredeemAmount(item?.total_shared_amount);
    setMonth(item?.installment);
    setInvoiceid(item?.invoice_id)
   }

       useEffect(() => {
   
   if (opInfo.sharedInstallments.length >0 ) {
              
       const promises = opInfo.sharedInstallments.map((item,index ) => {
           
       
            return (
                <div className="info-card special-data-card border border-5 border-warning border-opacity-75 rounded-3 p-3 pb-4 mt-3">
                <div className="row align-items-center gx-3">
                    <div className="col-lg-4">
                        <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start gap-2 gap-xxl-4">
                            <div className="gift-img">
                                <img src={require('../../resources/images/wallet/giftbox.png')} alt="Giftbox" className="img-fluid" />
                            </div>
                            <div className="gift-info">
                                <div className="gift-amount fs-2 fw-semibold text-white lh-sm">₹{item?.total_shared_amount} <span className="fs-6 fw-normal text-warning text-capitalize ms-2">Incentive Received</span></div>
                                <div className="installment-info fs-6 fw-medium text-white text-opacity-50">Installment {item?.installment} of {item?.total_installments}</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="d-flex flex-column flex-sm-row align-items-center justify-content-center gap-2 gap-xxl-3 my-4 my-lg-0">
                            <div className="left-info text-center text-sm-end">
                                <div className="fs-5 fw-semibold text-white lh-sm">₹{item?.total_shared_amount}</div>
                                <div className="fs-6 text-warning text-capitalize">Shared with you</div>
                            </div>
                            <div className="middle-info d-flex align-items-center gap-2 gap-xxl-3 text-center">
                                <div className="left-arrow">
                                    <img src={require('../../resources/images/wallet/left-arrow.png')} alt="Left Arrow" />
                                </div>
                                <div className="circle-info text-white d-flex flex-column align-items-center justify-content-center border border-1 rounded-circle" style={{ height: '95px', width: '95px' }}>
                                    <div className="amount fs-18 fw-semibold">₹{item?.amount}</div>
                                    <div className="fs-6 text-white text-opacity-50">Total</div>
                                </div>
                                <div className="right-arrow">
                                    <img src={require('../../resources/images/wallet/right-arrow.png')} alt="Right Arrow" />
                                </div>
                            </div>
                            <div className="right-info text-center text-sm-start">
                                <div className="fs-5 fw-semibold text-white lh-sm">₹{item?.amount - item?.total_shared_amount}</div>
                                <div className="fs-6 text-warning text-capitalize">He/she Kept it</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-end column-gap-3">
                            <span className="fs-6 text-warning text-capitalize">Shared By</span>
                            <span className="d-flex align-items-center gap-2">
                                <div className="img-wrapper">
                                    <img src={require('../../resources/images/Avatar.png')} alt="Shared User" className="img-fluid rounded-circle" style={{ height: '45px', width: '45px' }} />
                                </div>
                                <div className="shared-user-info text-capitalize">
                                    <div className="name text-white">{opInfo?.BDMName}</div>
                                    {/* <div className="name text-white text-opacity-75">Web Designer | TK10000</div> */}
                                </div>
                            </span>
                        </div>
                    </div>
                </div>
                <div className="mt-3">
                    <hr class="divider border border-1 border-top-2 border-secondary border-opacity-75 mt-2 mb-3" />
                    <div className="description fs-6 fw-medium text-white mb-3">To assist him in converting the opportunity and points below:</div>
                    <div className="row row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 gx-2 gy-3 mb-4">
                        <div className="info-box text-capitalize">
                            <div className="label fs-14 text-warning">Opportunity ID</div>
                            <div className="value text-white text-opacity-75 text-break">{opInfo?.new_opportunityid}</div>
                        </div>
                        <div className="info-box text-capitalize">
                            <div className="label fs-14 text-warning">Opportunity Type</div>
                            <div className="value text-white text-opacity-75">{new_opportunitytype[opInfo?.new_opportunitytype]}</div>
                        </div>
                        <div className="info-box text-capitalize">
                            <div className="label fs-14 text-warning">Client Name</div>
                            <div className="value text-white text-opacity-75">{opInfo?.new_fullname}</div>
                        </div>
                        <div className="info-box text-capitalize">
                            <div className="label fs-14 text-warning">Client Type</div>
                            <div className="value text-white text-opacity-75">Additional New</div>
                        </div>
                        <div className="info-box text-capitalize">
                            <div className="label fs-14 text-warning">Opportunity Status</div>
                            <div class="value fw-medium" style={{ color: 'rgb(3, 255, 133)' }}>{statuscode[opInfo?.statuscode]}</div>
                        </div>
                    </div>
                    <hr class="divider border border-1 border-top-2 border-secondary border-opacity-75 my-3" />
                    <div className="row g-3">
                        {item?.installment ===1 && (
                        <div className="col-lg-8">
                            <div className="checklist-wrapper">
                                <div className="checklist-label fs-6 fw-semibold text-warning mb-3">Points</div>
                                <ul className="m-0">
                                    {item?.assisted_points && item?.assisted_points.length>0 && item?.assisted_points?.map((item_)=>(
                                    <li>{item_}</li>
                                ))}
                                </ul>
                            </div>
                        </div>
       )}
                        <div className="col-lg-4">
                            <div className="btn-wrapper text-lg-end">
                                {item?.is_account_credited?
                                <>
                                    Credit details
                                </>
                                
                                :item?.is_requested_for_accounts?
                                 <Button variant="info" className="text-center border-0 rounded-1 px-3 px-md-4 py-1" style={{ minWidth: '240px' }}>
                                 <div className="fs-5 fw-semibold text-white lh-sm">Redeemed ₹{item?.total_shared_amount}</div>
                                 <div className="fs-12 text-white text-opacity-75">Awaiting credit from Accounts</div>
                             </Button>
                                :
                                <Button variant="success" className="text-center border-0 rounded-1 px-3 px-md-4 py-1" onClick={()=>{openRedeemAmountModal();setData(item)}} style={{ minWidth: '240px' }}>
                                    <div className="fs-5 fw-semibold text-white lh-sm">Redeem ₹{item?.total_shared_amount}</div>
                                    <div className="fs-12 text-white text-opacity-75">You can redeem now</div>
                                </Button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
            
               );
         
            
           
         });
   
       // Once all promises are resolved, set the invoiceHtml
       Promise.all(promises).then(setInvoiceHtml);
     } 
   

   
           
       
          
         }, [opInfo]); // Add opInfo as a dependency

    return (
       <>
       {invoiceHtml}

       <RedeemAmountModal  show={showRedeemAmountModal} close={closeRedeemAmountModal} redeemAmount={redeemAmount} walletOppInfo={opInfo.wallet_opportunities} userRole={userRole} whichMonthRedeem={month} setShowRedeemAmountModal={setShowRedeemAmountModal} loadWallet={loadWallet} opInfo={opInfo} cardType={opInfo.cardType} type={"sbdm"} invoiceId={invoiceid}/>
       </>
    )

}