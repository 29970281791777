import React, { useEffect, useState } from "react";
import '../WalletSection/wallet.scss';
import { Tab, Tabs } from 'react-bootstrap'
import { AccountHeader } from "./Components/AccountHeader";
import { Incentivepending } from "./Components/Incentivepending";
import { Incentivepaid } from "./Components/Incentivepaid";
// import { Showloader } from "./Components/Loader";

import { Button } from 'react-bootstrap'
import { SendAmountModal } from "./SendAmountModal";
import PendingIcon from "../resources/images/wallet/pending.svg";
import moment from 'moment';
import { connect } from "react-redux";
import { setUserInfo } from "../store/actions";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebaseConfig";
import { useLocation, useNavigate } from "react-router-dom";
import { callAxios } from "../utils/utils";
import { environment } from "../environment";
import { Loader } from "../Loader/Loader";
import { toast } from "react-toastify";

const AccountDashboard = ({ setLoggedinUser, userRole }) => {
    // loggedin variables
    const [user, loading, error] = useAuthState(auth);
 
    
    let navigate = useNavigate();
    const {state} = useLocation()

    const [detailsTab, setDetailsTab] = useState('All');
    const [formData, setFormData] = useState({});
    const [getUserinstallmentdata, setUserinstallmentdata] = useState('')
    const [getLoading, setLoading] = useState(true)
    const [getAlldata, setAlldata] = useState('')
    const [getcreditedtrue, setcreditedtrue] = useState([])
    const [getcreditedfalse, setcreditedfalse] = useState([])
    const [progress, setProgress] = useState(0);

   
     useEffect(() => {
                if (loading) return;
                if (!user) navigate("/");
                else {
            
                  const userInfo = {
                    uid: user?.uid,
                    email: user?.email,
                    displayName: user?.displayName,
                    accessToken: user?.accessToken,
                    idToken: user?.idToken,
                  };
                  
            
                  setLoggedinUser(userInfo);
                 
                  getUserInstallment(formData)
           
                }
              }, [user, loading]);

    
    // Account amount modal
    const [showSendAmountModal, setShowSendAmountModal] = useState(false);
    const closeSendAmountModal = () => setShowSendAmountModal(false);
    const openSendAmountModal = () => {
        setShowSendAmountModal(true)
    };

    const handleFormData = (data) => {
        setFormData(data)
        getUserInstallment(data)
    };

    // collectArray
    const accountCreditTrue = (data) => {
        return data.map((client) => {
            const creditedUsers = client.userList.filter(user => user.is_account_credited);
            if (creditedUsers.length > 0) {
                return {
                    ...client,
                    userList: creditedUsers,
                };
            }
        });
    };
    const accountCreditFalse = (data) => {
        return data.map((client) => {
            const creditedUsers = client.userList.filter(user => !user.is_account_credited);
            if (creditedUsers.length > 0) {
                return {
                    ...client,
                    userList: creditedUsers,
                };
            }
        });
    };
    // collectArray

    const getUserInstallment = async (getdata, customParameter=null) => {
        // console.log(customParameter)
        setLoading(true);
       
        const postdata = {
            "fromDate": getdata?.requestedFrom && getdata?.requestedFrom !== ''
                ? getdata?.requestedFrom
                : moment().subtract(15, 'days').format('YYYY-MM-DD'),

            "toDate": getdata?.requestedTo && getdata?.requestedTo !== ''
                ? getdata?.requestedTo
                : moment().format('YYYY-MM-DD'),

            "searchString": getdata?.getSearchvalue && getdata?.getSearchvalue !== ''
                ? getdata?.getSearchvalue
                : "",

            "client_type": getdata?.getClienttype && getdata?.getClienttype !== ''
                ? getdata?.getClienttype
                : "",

            "status": detailsTab.toLowerCase() === "all"
                ? ""
                : detailsTab.toLowerCase() === "pending"
                    ? 0
                    : detailsTab.toLowerCase() === "approved"
                        ? 1
                        : null
        }
        try {
            const res = await callAxios(`${environment.REACT_APP_API_URL_Live}api/v1/wallet/get-wallet-users-installment`, postdata, "post");
            const resData = res?.data;
            if (resData?.statusCode === 200) {
                if(customParameter === "fromSendModal"){
                    toast.success('Transaction details updated!', {
                    position: "top-right", // Position of the toast
                    autoClose: 5000,           // Toast will close after 5 seconds (5000 ms)
                    hideProgressBar: false,     // Hide the progress bar
                    closeOnClick: true,        // Close the toast when clicked
                    pauseOnHover: true,        // Pause the toast when hovering over it
                    draggable: true,           // Make the toast draggable
                });
                }
                const getdata = resData?.list
                setAlldata(resData)
                if (getdata.length >= 1) {
                    setUserinstallmentdata(resData)
                    const getalldatacredittrue = await accountCreditTrue(resData?.list)
                    setcreditedtrue(getalldatacredittrue)
                    const getalldatacreditfalse = await accountCreditFalse(resData?.list)
                    setcreditedfalse(getalldatacreditfalse)
                } else {
                    setUserinstallmentdata([])
                }
            }
            setLoading(false);
        } catch (error) {
            console.log("Error:", error);
            setLoading(false);
        }
    }
    // useEffect(() => {
    //     if (detailsTab)
    //         getUserInstallment(formData)
    // }, [detailsTab])
    // console.log("Loader status:::",getLoading)
    return (
        <div className="incentives-account-page py-3">
            <div className="container-fluid px-xxl-5">
                {getLoading ? <Loader isNeedProgressBar={false} progress={progress} /> : ""}
                <div className="Wallet-page d-flex flex-column flex-xxl-row flex-xxl-wrap align-items-xxl-center justify-content-between row-gap-2 column-gap-5 mb-3">
                    <div className="fs-3 fw-semibold text-light lh-sm text-nowrap">Account Dashboard</div>
                    <AccountHeader sendDataToParent={handleFormData} />
                </div>

                <div className="tabs-wrapper">
                    <Tabs id="controlled-tab-example" activeKey={detailsTab} onSelect={(k) => setDetailsTab(k)} className="bg-transparent rounded-top-3 mb-3">
                        <Tab eventKey="All" title="All">
                            <div className='tab-content-wrapper incentives-account-wrapper'>
                                {getUserinstallmentdata !== "" ?
                                    <div className="d-flex flex-column gap-3">
                                        <Incentivepending incentivependingData={getUserinstallmentdata} UserInstallmentFunc={getUserInstallment} allpendingcredit={getcreditedfalse} formData={formData} setLoading={setLoading} user={user}/>
                                        
                                        <Incentivepaid incentivepaidData={getUserinstallmentdata} allpaidcredit={getcreditedtrue} formData={formData} setLoading={setLoading} user={user}/>
                                    </div>
                                    : "No Data Found."}
                            </div>
                        </Tab>
                        <Tab eventKey="Pending" title={`Pending(${getAlldata?.pendingCount !== undefined ? getAlldata?.pendingCount : "0"})`}>
                            <div className='tab-content-wrapper incentives-account-wrapper'>
                                {getUserinstallmentdata !== "" ?
                                    <Incentivepending incentivependingData={getUserinstallmentdata} UserInstallmentFunc={getUserInstallment} allpendingcredit={getcreditedfalse} formData={formData} setLoading={setLoading} user={user}/>
                                    : "No Data Found."}
                            </div>
                        </Tab>
                        <Tab eventKey="Approved" title={`Approved(${getAlldata?.approvedCount != undefined ? getAlldata?.approvedCount : "0"})`}>
                            <div className='tab-content-wrapper incentives-account-wrapper'>
                                {getUserinstallmentdata !== "" ?
                                    <Incentivepaid incentivepaidData={getUserinstallmentdata} UserInstallmentFunc={getUserInstallment} allpaidcredit={getcreditedtrue} formData={formData} setLoading={setLoading} user={user}/>
                                    : "No Data Found."}
                            </div>
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
      setLoggedinUser: (user) => dispatch(setUserInfo(user))
    };
  };
  
  const mapStoreStateToProps = (state) => {
    return {
      ...state,
    };
  };
   
  export default connect(
    mapStoreStateToProps,
    mapDispatchToProps
  )(AccountDashboard);
