import React from "react";
import { Accordion, Card, Button, useAccordionButton } from 'react-bootstrap';
import { useSdm } from "../Context/SdmContext";
import { toast } from "react-toastify";
import { convertReadable } from "../utils/utils";


const CustomDayViewContent = (props) => {
    const {sdmListing,setShowWorkUpdateModal,todayDate,sdmData} = useSdm();  // Use the context hook
    // console.log('Custom Data from Context:', sdmListing);

    const hasEvents = sdmListing && sdmListing.length > 0;

    const openModal = () =>{
        if(sdmData.length ===0){
             toast.error('No billing found on given date. you can not add work report', {
                            position: "top-right", // Position of the toast
                            autoClose: 5000,           // Toast will close after 5 seconds (5000 ms)
                            hideProgressBar: false,     // Hide the progress bar
                            closeOnClick: true,        // Close the toast when clicked
                            pauseOnHover: true,        // Pause the toast when hovering over it
                            draggable: true,           // Make the toast draggable
                        });
        } else{
setShowWorkUpdateModal(true)
        }
        
    }
    function CustomToggle({ children, eventKey }) {
        const decoratedOnClick = useAccordionButton(eventKey, () =>
            console.log('Accordion toggled!')
        );

        return (
            <div className="custom-bg" onClick={decoratedOnClick}>
                {children}
            </div>
        );
    }
    const htmlSdm = sdmListing && sdmListing.length >0 && sdmListing.map((item,index)=>{
        if(item?.client_intraction === "no"){
            return(
                <div className="info-card dark-theme-bg2 rounded-3 overflow-hidden">
                <Card.Header className="position-relative p-3" style={{ backgroundColor: '#7D2930' }}>
                    <CustomToggle eventKey={index}>
                        <div className="row gx-2 gy-0">
                            <div className="col-md-4 left-section">
                                <div className="client-name text-white fs-6 fw-medium text-capitalize">{item.clientName}</div>
                            </div>
                            <div className="col-md-4 middle-section text-md-center">
                                <div className="interaction-status text-white fs-6 fw-medium text-capitalize">Total Incentive: ₹{item?.total_incentive}</div>
                            </div>
                            <div className="col-md-4 right-section">
                            </div>
                        </div>
                    </CustomToggle>
                </Card.Header>
                <Accordion.Collapse eventKey={index}>
                    <Card.Body className="p-3">
                        <div className="row g-3 mb-4">
                            <div className="col-md-7 col-xl-8">
                                <div className="info-box">
                                    <div className="client-name text-white fs-4 fw-semibold text-capitalize lh-sm">{item?.clientName}</div>
                                    <div className="client-company text-light text-opacity-50 fs-14 text-capitalize mb-2">
                                        <span className='client-company'>{item?.opportunity_belongs_to}</span>
                                        <span className='client-country fs-14'>{item?.opportunity_country}</span>
                                    </div>
                                    <div className="fs-6 text-white text-capitalize d-flex flex-wrap gap-2">
                                        <span className='work-type'>{item?.clientType?.replace(/\s+\S+$/, '')}</span> |
                                        <span className='text-uppercase'>{item?.service_id}</span> |
                                        <span>{item?.opportunity_type}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-5 col-xl-4">
                                {item?.front_end_developer && item?.front_end_developer?.name?
                                <div className="info-box">
                                    <div className="resource-name text-white fs-4 fw-semibold text-capitalize lh-sm">{item?.front_end_developer?.name}</div>
                                    <div className="resource-email text-light text-opacity-50 fs-14 text-lowercase text-break mb-2">{item?.front_end_developer?.email}</div>
                                    <div className="fs-6 text-white text-capitalize">
                                        <span className='resource-designation'>Front End</span>
                                        <span className='total-assigned-hours text-primary fw-semibold ms-3'>{item?.front_end_developer?.hourConsumed} hrs / {item?.front_end_developer?.hourAssigned} hrs</span>
                                    </div>
                                </div>
                                :null}

                                {item?.backen_developer && item?.backen_developer?.name?
                                <div className="info-box">
                                    <div className="resource-name text-white fs-4 fw-semibold text-capitalize lh-sm">{item?.backen_developer?.name}</div>
                                    <div className="resource-email text-light text-opacity-50 fs-14 text-lowercase text-break mb-2">{item?.backen_developer?.email}</div>
                                    <div className="fs-6 text-white text-capitalize">
                                        <span className='resource-designation'>Backend</span>
                                        <span className='total-assigned-hours text-primary fw-semibold ms-3'>{item?.backen_developer?.hourConsumed} hrs / {item?.backen_developer?.hourAssigned} hrs</span>
                                    </div>
                                </div>
                                :null}
                                {item?.designer && item?.designer?.name?
                                <div className="info-box">
                                    <div className="resource-name text-white fs-4 fw-semibold text-capitalize lh-sm">{item?.designer?.name}</div>
                                    <div className="resource-email text-light text-opacity-50 fs-14 text-lowercase text-break mb-2">{item?.designer?.email}</div>
                                    <div className="fs-6 text-white text-capitalize">
                                        <span className='resource-designation'>Designer</span>
                                        <span className='total-assigned-hours text-primary fw-semibold ms-3'>{item?.designer?.hourConsumed} hrs / {item?.designer?.hourAssigned} hrs</span>
                                    </div>
                                </div>
                                :null}
                                {item?.tester && item?.tester?.name?
                                <div className="info-box">
                                    <div className="resource-name text-white fs-4 fw-semibold text-capitalize lh-sm">{item?.tester?.name}</div>
                                    <div className="resource-email text-light text-opacity-50 fs-14 text-lowercase text-break mb-2">{item?.tester?.email}</div>
                                    <div className="fs-6 text-white text-capitalize">
                                        <span className='resource-designation'>Tester</span>
                                        <span className='total-assigned-hours text-primary fw-semibold ms-3'>{item?.tester?.hourConsumed} hrs / {item?.tester?.hourAssigned} hrs</span>
                                    </div>
                                </div>
                                :null}
                            </div>
                        </div>
                        {item?.feedback?
                        <div className="info-box fs-6 mb-3">
                            <div className="label text-warning mb-1">Reason</div>
                            <div className="reason-description text-white fs-14">
                               {item?.feedback}
                            </div>
                        </div>
                        :null}
                    </Card.Body>
                </Accordion.Collapse>
            </div>
            )
        } else{
            return(
                <div className="info-card dark-theme-bg2 rounded-3 overflow-hidden">
                        <Card.Header className="position-relative p-3" style={{ backgroundColor: '#1C5138' }}>
                            <CustomToggle eventKey={index}>
                                <div className="row gx-2 gy-0">
                                    <div className="col-md-4 left-section">
                                        <div className="client-name text-white fs-6 fw-medium text-capitalize">{item?.clientName}</div>
                                    </div>
                                    <div className="col-md-4 middle-section text-md-center">
                                        <div className="interaction-status text-white fs-6 fw-medium text-capitalize">Total Incentive: ₹{item?.total_incentive}</div>
                                    </div>
                                    <div className="col-md-4 right-section">
                                    </div>
                                </div>
                            </CustomToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey={index}>
                            <Card.Body className="p-3">
                                <div className="row g-3 mb-4">
                                    <div className="col-md-7 col-xl-8">
                                        <div className="info-box">
                                            <div className="client-name text-white fs-4 fw-semibold text-capitalize lh-sm">{item?.clientName}</div>
                                            <div className="client-company text-light text-opacity-50 fs-14 text-capitalize mb-2">
                                            <span className='client-company'>{item?.opportunity_belongs_to}</span>
                                            <span className='client-country fs-14'>{item?.opportunity_country}</span>
                                            </div>
                                            <div className="fs-6 text-white text-capitalize d-flex flex-wrap gap-2">
                                                <span className='work-type'>{item?.clientType?.replace(/\s+\S+$/, '')}</span> |
                                                <span className='text-uppercase'>{item?.service_id}</span> |
                                                <span>{item?.opportunity_type}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-5 col-xl-4">
                                {item?.front_end_developer && item?.front_end_developer?.name?
                                <div className="info-box">
                                    <div className="resource-name text-white fs-4 fw-semibold text-capitalize lh-sm">{item?.front_end_developer?.name}</div>
                                    <div className="resource-email text-light text-opacity-50 fs-14 text-lowercase text-break mb-2">{item?.front_end_developer?.email}</div>
                                    <div className="fs-6 text-white text-capitalize">
                                        <span className='resource-designation'>Front End</span>
                                        <span className='total-assigned-hours text-primary fw-semibold ms-3'>{item?.front_end_developer?.hourConsumed} hrs / {item?.front_end_developer?.hourAssigned} hrs</span>
                                    </div>
                                </div>
                                :null}

                                {item?.backen_developer && item?.backen_developer?.name?
                                <div className="info-box">
                                    <div className="resource-name text-white fs-4 fw-semibold text-capitalize lh-sm">{item?.backen_developer?.name}</div>
                                    <div className="resource-email text-light text-opacity-50 fs-14 text-lowercase text-break mb-2">{item?.backen_developer?.email}</div>
                                    <div className="fs-6 text-white text-capitalize">
                                        <span className='resource-designation'>Backend</span>
                                        <span className='total-assigned-hours text-primary fw-semibold ms-3'>{item?.backen_developer?.hourConsumed} hrs / {item?.backen_developer?.hourAssigned} hrs</span>
                                    </div>
                                </div>
                                :null}
                                {item?.designer && item?.designer?.name?
                                <div className="info-box">
                                    <div className="resource-name text-white fs-4 fw-semibold text-capitalize lh-sm">{item?.designer?.name}</div>
                                    <div className="resource-email text-light text-opacity-50 fs-14 text-lowercase text-break mb-2">{item?.designer?.email}</div>
                                    <div className="fs-6 text-white text-capitalize">
                                        <span className='resource-designation'>Designer</span>
                                        <span className='total-assigned-hours text-primary fw-semibold ms-3'>{item?.designer?.hourConsumed} hrs / {item?.designer?.hourAssigned} hrs</span>
                                    </div>
                                </div>
                                :null}
                                {item?.tester && item?.tester?.name?
                                <div className="info-box">
                                    <div className="resource-name text-white fs-4 fw-semibold text-capitalize lh-sm">{item?.tester?.name}</div>
                                    <div className="resource-email text-light text-opacity-50 fs-14 text-lowercase text-break mb-2">{item?.tester?.email}</div>
                                    <div className="fs-6 text-white text-capitalize">
                                        <span className='resource-designation'>Tester</span>
                                        <span className='total-assigned-hours text-primary fw-semibold ms-3'>{item?.tester?.hourConsumed} hrs / {item?.tester?.hourAssigned} hrs</span>
                                    </div>
                                </div>
                                :null}
                            </div>
                                </div>
                                <div className="info-box fs-6 mb-4">
                                    <div className="label text-warning mb-1">Communication Overview</div>
                                    <div className="text-white d-flex flex-wrap gap-2 text-capitalize">
                                        <span className='comm-type'>{item?.communication_mode}</span> |
                                        {item?.communication_medium.length>0?
                                        <>
                                        <span className='comm-mode'>{item?.communication_medium}</span> |
                                        </>
                                        
                                        :null}
                                        {item?.communication_tool.length>0 && item?.communication_mode === "written"?
                                        <>
                                        <span className='comm-palteform'>{item?.communication_tool}</span> 
                                        </>
                                        
                                        :
                                         <>
                                        <span className='comm-palteform'>{item?.communication_tool}</span> |
                                        </>
                                        }
                                        {item?.communication_mode === "verbal"?
                                        <span className='comm-duration'> {item?.communication_duration}  {item?.communication_unit}</span>
                                        :null}
                                       
                                       
                                        
                                      
                                    </div>
                                </div>
                                <div className="info-box fs-6 mb-4">
                                    <div className="label text-warning mb-1">Feedback</div>
                                    <div className="feedback-type text-white d-flex flex-wrap gap-2 text-capitalize mb-2">
                                        {item?.interaction_status && item?.interaction_status === "concerned"?
                                        <>
                                         <span className='feedback-type text-danger'>Concerned</span> |
                                        </>
                                       
                                        :
                                        <>
                                        <span className='feedback-type text-green'>Positive</span> | 
                                        </>
                                         
                                        }
                                        <span className='resource-name text-white'>{item?.resource_name}</span>
                                    </div>
                                    {item?.feedback?
                                    <div className="feedback-description text-white fs-14">
                                       {item?.feedback}
                                    </div>
                                    :null}
                                </div>
                                {item?.sdm_concerns?
                                <div className="info-box mb-4">
                                    <div className="label fs-14 text-warning mb-1">Challenges or Concerns</div>
                                    <div className="feedback-description text-white fs-14">
                                       {item?.sdm_concerns}
                                    </div>
                                </div>
                                :null}
                                {item?.pending_action?
                                <div className="info-box mb-3">
                                    <div className="label fs-14 text-warning mb-1">Follow-up</div>
                                   
                                    <div className="value text-green text-capitalize mb-1">{item?.pending_action}</div>
                                    {item?.pending_action === "yes"?
                                    <div className="feedback-description text-white fs-14">
                                        {item?.pending_action_notes}
                                    </div>
                                    :null}
                                </div>
                                :null}
                            </Card.Body>
                        </Accordion.Collapse>
                    </div>
            )
        }
        
    })
    return (
        <div className="DayViewLayout h-100">
            

            {hasEvents ? (
                <Accordion defaultActiveKey="0" alwaysOpen className="work-cards-wrapper d-flex flex-column gap-3">
                    {htmlSdm}
                                                
                </Accordion>
            ) : (
                <div className="content-wrapper h-100 border border-secondary rounded-3 d-flex flex-column justify-content-center flex-fill text-center overflow-auto p-3">
                                                <div className="icon text-center mb-3 mb-md-4">
                                                    <i className="bi bi-exclamation-octagon-fill fs-1 lh-1" style={{ color: '#acaa54' }}></i>
                                                </div>
                                                <div className="description text-light fw-medium text-center lh-sm mb-4">
                                                    No work update submitted <span style={{ color: '#e6ef13' }}>{convertReadable(todayDate)}</span>. SDM incentives require daily updates for each billing.<br />
                                                    Incentives will be released after Sheela receives all updates for the billing hours.
                                                </div>
                                                <div className="btn-wrapper text-center">
                                                    <Button variant="outline-warning" className="px-4" onClick={openModal}><i className="bi bi-plus-circle-fill"></i></Button>
                                                </div>
                                            </div>
            )}
        </div>
    );
};

export default CustomDayViewContent;
