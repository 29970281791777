import React, { useEffect, useState } from "react";
import moment from 'moment';
import '../WalletSection/wallet.scss';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { WalletUsers } from "./Components/WalletUsers";
import { EarningSection } from "./Components/EarningSection";
import { Approvedtab } from "./Components/ApprovedTab";
import { Disapprovedtab } from "./Components/DisapprovedTab";
import { Pendingtab } from "./Components/PendingTab";
import { callAxios } from "../utils/utils";
import { Showloader } from "../WalletSection/Components/Loader";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebaseConfig";
import { useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { setUserInfo } from "../store/actions";
import { environment } from "../environment";
import { Button, Form, ProgressBar } from "react-bootstrap";

import SearchIcon from '../resources/images/search.svg';


const ManagementWalletDashboard = ({ setLoggedinUser, userRole }) => {
    const [user, loading, error] = useAuthState(auth);
 
    
    let navigate = useNavigate();
       const {state} = useLocation()
       const [activeIndex_, setActiveIndex_] = useState(state?.activeIndex_ ??0);
       useEffect(()=>{
        setActiveIndex_(state?.activeIndex_)
       },[state])
    //    console.log(activeIndex_,state)
    // console.log(state)
    const [getLoading, setLoading] = useState(false)
    const [detailsTab, setDetailsTab] = useState('Pending');

    const [getallwalletUser, setallwalletUsers] = useState([])
    const [getdashboardData, setdashboardData] = useState('')
    const [getuserInstallments, setuserInstallments] = useState([])
    const [getuserInstallmentsApproved, setuserInstallmentsApproved] = useState([])
    const [getuserInstallmentsRejected, setuserInstallmentsRejected] = useState([])
    const [disputeCases, setDispute] = useState([])
    const [getBalances, setBalances] = useState('')
    const [getwalletInfo, setwalletInfo] = useState('')
    const [getUserInfo, setUserInfo] = useState('')
    

    const [pendingTitle, setPendingTitle] = useState(0)
    const [approvedTitle, setApprovedTitle] = useState(0)
    const [rejectedTitle, setRejectedTitle] = useState(0)

   

    const [approvedTotal, setApprovedTotal] = useState(0)
    const [pendingTotal, setPendingTotal] = useState(0)

     const [progress, setProgress] = useState(0);

       const [startDate, setStartDate] = useState(() => {
             const startDD = `${new Date().getFullYear()}-${String(new Date().getMonth() + 1).padStart(2, '0')}-01`;
             return startDD;
         });
         const [endDate, setEndDate] = useState(() => {
             const date = new Date();
             const syear = date.getFullYear();
             const smonth = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
             const sday = String(date.getDate()).padStart(2, '0');
             return `${syear}-${smonth}-${sday}`;
         });
         

    // const approvedTotal = getuserInstallmentsApproved.map((item)=> )
       useEffect(() => {
            if (loading) return;
            if (!user) navigate("/");
            else {
        
              const userInfo = {
                uid: user?.uid,
                email: user?.email,
                displayName: user?.displayName,
                accessToken: user?.accessToken,
                idToken: user?.idToken,
              };
              const callExistingData = async(user)=>{
                const getData = await managementDashboard(user);
                if (getData)
                    setLoading(false);
              }
        
              setLoggedinUser(userInfo);
              if(state){
                // console.log(state?.getallwalletUser,state?.getdashboardData)
                setallwalletUsers(state?.getallwalletUser)
                callExistingData(state?.getdashboardData?.walletInfo);
               
              } else{
                getWalletusers()
              }
              
       
            }
          }, [user, loading]);

    // useEffect(()=>{
    //     const callExistingData = async(user)=>{
    //         const getData = await managementDashboard(user);
    //         if (getData)
    //             setLoading(false);
    //       }
         
    //         if(state){
    //         // console.log(state?.getallwalletUser,state?.getdashboardData)
    //         setallwalletUsers(state?.getallwalletUser)
    //         callExistingData(state?.getdashboardData?.walletInfo);
           
    //       } else{
    //         getWalletusers()
    //       }
          
        
    // },[mfaApproved])

    const getWalletusers = async () => {
        setLoading(true);
        const postdata = {
            "data": "data"
        };
        try {
            const res = await callAxios(`${environment.REACT_APP_API_URL_Live}api/v1/wallet/get-wallet-user-management`, postdata, "post");
            const resData = res?.data;
            if (resData?.statuscode === 200) {
                const getUsers = resData?.walletUsers;
                setallwalletUsers(getUsers);
                const getData = await managementDashboard(getUsers[0]);
                
                if (getData)
                    setLoading(false);
            }
        } catch (error) {
            console.log("Error:", error);
            setLoading(false);
        }
    };


    const managementDashboard = async (getUsers) => {
        setUserInfo(getUsers)
        setLoading(true);
        const progressInterval = setInterval(() => {

            setProgress((prev) => (prev < 90 ? prev + 1 : prev)); // Increment by 1%

          }, 900);
        const postdata = {
            "userType": getUsers?.userType,
            "email": getUsers?.email,
            "card_type": ["individual"],
            "startDate": startDate,
            "endDate": endDate
        }
        callAxios(`${environment.REACT_APP_API_URL_Live}api/v1/wallet/management-dashboard`, postdata, "post").then((res) => {
            setProgress(100);

            setTimeout(() => {
                clearInterval(progressInterval);
                setProgress(0);
            const resData = res?.data
            setdashboardData(resData)
            setwalletInfo(resData?.walletInfo)
            setuserInstallments(resData?.userInstallments)
            setPendingTitle(resData?.userInstallments.length)
            setuserInstallmentsApproved(resData?.userInstallmentsApproved)
            setApprovedTitle(resData?.userInstallmentsApproved.length)
            setuserInstallmentsRejected(resData?.userInstallmentsRejected)
            setDispute(resData?.disputedCases)
            setRejectedTitle(resData?.userInstallmentsRejected.length)
            setBalances(resData?.Balances)
            setApprovedTotal(resData?.totalApprovedAmount)
            setPendingTotal(resData?.totalPendingAmount)
            setLoading(false)
        }, 1000);
        }).catch((error) => {
            console.log("====>", error)
            setLoading(false)
        })
    }

  
    const formatDate = (date) => {
        const dateObj = new Date(date);
        return dateObj.toLocaleDateString('en-CA');
    };

    const getUser = async (getdata) => {
        managementDashboard(getdata)
    }

    const handalSearch = async () => {
        managementDashboard(getdashboardData?.walletInfo)
        // loadDashboard(userEmail)
    }

    return (
        <div className="ManagementWalletDashboard-page WalletDashboard py-3">
            <div className="container-fluid px-xxl-5">
                {getLoading ? <div className="loader-overlay" id="loader" style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                height: '100vh',
                background: 'rgba(0, 0, 0, 0.8)',
                backdropFilter: 'blur(2px)'
            }}>
                <img src={require('../resources/images/wallet/walletpreloader.gif')} alt="Balance" className="img-fluid" style={{ maxWidth: '200px', margin: "0 auto" }} />

                <div className="text-center w-50">
                   <p>Please wait ....</p>
                   <p><ProgressBar animated now={progress} label={`${progress}%`}/></p>
                </div>

            </div>
            :null}
                {/* All Wallet User listing */}
                <div className="wallet-user-listing d-flex flex-nowrap gap-2 overflow-auto mb-3 py-2">
                    {getallwalletUser && getallwalletUser.length >= 1 ?
                        <WalletUsers allwalletusers={getallwalletUser} userData={getUser} setActiveIndex_={setActiveIndex_} activeIndex_={activeIndex_}/>
                        : "No data found."
                    }
                </div>

                
                <div className="Wallet-page d-flex flex-wrap flex-column flex-md-row align-items-md-center justify-content-between gap-2 mb-3">
                    <div className="d-flex flex-wrap align-items-center">
                        <div className="fs-3 fw-semibold text-light lh-sm text-nowrap">Management Dashboard</div>
                        <div className="info-box fs-14 text-capitalize border-start border-secondary ms-3 ps-3 text-truncate">
                            <div className="name text-warning text-truncate">{getUserInfo?.name}</div>
                            {/* <div className="designation text-white text-opacity-75 text-truncate">Web Designer</div> */}
                        </div>
                    </div>
                    <div className="date-wrapper d-flex flex-wrap align-items-md-center gap-2 gap-lg-3">
                        <Form.Group className="d-flex flex-column flex-md-row align-items-md-center flex-fill gap-1 gap-md-2 gap-xl-3" controlId="dateFrom">
                            <Form.Label className="m-0">From</Form.Label>
                            <Form.Control type="date" className="bg-transparent py-2"
                                defaultValue={`${new Date().getFullYear()}-${String(new Date().getMonth() + 1).padStart(2, '0')}-01`}
                                onChange={(e) => setStartDate(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="d-flex flex-column flex-md-row align-items-md-center flex-fill gap-1 gap-md-2 gap-xl-3" controlId="dateTo">
                            <Form.Label className="m-0">To</Form.Label>
                            <Form.Control type="date" className="bg-transparent py-2"
                                defaultValue={new Date().toJSON().slice(0, 10)}
                                onChange={(e) => setEndDate(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="d-flex flex-column justify-content-end flex-fill">
                            <Button variant="secondary" className="rounded-2 py-2" onClick={handalSearch}>
                                <img src={SearchIcon} alt="" className="img-fluid align-text-top" />
                                {/* <span className="d-md-none ms-1">Search</span> */}
                            </Button>
                        </Form.Group>
                    </div>
                </div>

                <div className="earning-section info-card h-100 rounded-3 mb-3 px-2 px-sm-3 py-3" style={{ backgroundColor: 'var(--wallet-color1)' }}>
                    {getwalletInfo ?
                        <EarningSection walletData={getwalletInfo} balancesData={getBalances} disputeCases={disputeCases} approvedTotal={approvedTotal} pendingTotal={pendingTotal} getdashboardData={getdashboardData} startDate={startDate} endDate={endDate}/>
                        : "No data found."}
                </div>

                <div className="tabs-wrapper">
                    <Tabs id="controlled-tab-example" activeKey={detailsTab} onSelect={(k) => setDetailsTab(k)} className="bg-transparent rounded-top-3 mb-3">
                        <Tab eventKey="Pending" title={"Pending (" +pendingTitle+")"}>
                            {getuserInstallments && getuserInstallments.length >= 1 ?
                                <Pendingtab userInstallmentsdata={getuserInstallments} dateFormate={formatDate} getdashboardData={getdashboardData} getallwalletUser={getallwalletUser} activeIndex_={activeIndex_}/>
                                : "No data found."}
                        </Tab>
                        <Tab eventKey="Approved" title={"Approved (" +approvedTitle+")"}>
                            {getuserInstallmentsApproved && getuserInstallmentsApproved.length >= 1 ?
                                <Approvedtab userInstallmentsdata={getuserInstallmentsApproved} dateFormate={formatDate} getdashboardData={getdashboardData} getallwalletUser={getallwalletUser} activeIndex_={activeIndex_}/>
                                : "No data found."}
                        </Tab>
                        <Tab eventKey="Disapproved" title={"Disapproved (" +rejectedTitle+")"}>
                            {getuserInstallmentsRejected && getuserInstallmentsRejected.length >= 1 ?
                                <Disapprovedtab userInstallmentsdata={getuserInstallmentsRejected} dateFormate={formatDate} by={user?.email} userRole={userRole} getdashboardData={getdashboardData} getallwalletUser={getallwalletUser} activeIndex_={activeIndex_}/>
                                : "No data found."}
                        </Tab>
                    </Tabs>
                </div>
            </div>
            
        </div>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
      setLoggedinUser: (user) => dispatch(setUserInfo(user))
    };
  };
  
  const mapStoreStateToProps = (state) => {
    return {
      ...state,
    };
  };
   
  export default connect(
    mapStoreStateToProps,
    mapDispatchToProps
  )(ManagementWalletDashboard);

