import React, { useEffect, useState } from "react";
import { Button, Modal, Form, InputGroup } from 'react-bootstrap';
import './DailyWorkUpdate.scss';
import { callAxios, formatMonthYear } from "../utils/utils";
import { environment } from "../environment";
import { toast } from "react-toastify";
import { nm__clienttype, nm_paymentstatus, nm_servicestatus } from "../utils/codes";

export const WorkUpdateModal = ({ showWorkUpdateModal, closeWorkUpdateModal, allData,defaultItem,setDefaultItem,setIsLoading,setShowWorkUpdateModal, date, email, getListingDataFromStore_refresh, dropdownItem, setClientName, client_name,clientInteraction,setClientInteraction }) => {
    const [billingInfoCard, setBillingInfoCard] = useState();
    const [selectedOption, setSelectedOption] = useState('');
    // const [clientInteraction, setClientInteraction] = useState('');
    const [pendingActions, setPendingActions] = useState('');
    const [currentMonth, setCurrentMonth] = useState('');
    const [technicalDesc, setTechnicalDesc] = useState('')
    const [communicationModes, setCommunicationModes] = useState('');
    const [mediumModes, setMediumModes] = useState([]);
    const [searchQuery, setSearchQuery] = useState(defaultItem?.clientName);

    // const [client_name, setClientName] = useState('');

    // console.log("submitting data for::",date)
    useEffect(() => {
        if (allData?.month) {
            setCurrentMonth(formatMonthYear(allData.month));
        } else {
            console.warn("⚠️ allData.month is undefined or null");
        }
    }, [allData]);

    const [formData, setFormData] = useState({
        "opportunityid": defaultItem?.opportunityid,
        "topic": defaultItem?.topic,
        "opportunity_status": defaultItem?.opportunity_status,
        "service_id": defaultItem?.service_id,
        "opportunity_type": defaultItem?.opportunity_type === 232520000?"technical":"non technical",
        "opportunity_belongs_to": defaultItem?.opportunity_belongs_to,
        "opportunity_country": defaultItem?.opportunity_country,
        "sdm_name": defaultItem?.sdmData?.name,
        "sdm_email": defaultItem?.sdmData?.email,
        "total_billing_hours": defaultItem?.totalBillingHours,
        "consumed_billing_hours": defaultItem?.totalConsumedHours,
        "front_face": defaultItem?.frontFace && defaultItem?.frontFace?.name?defaultItem?.frontFace?.name:"-",
        "front_end_developer": defaultItem?.front_end_developer && defaultItem?.front_end_developer?.name?defaultItem?.front_end_developer:{},
        "backen_developer": defaultItem?.backen_developer && defaultItem?.backen_developer?.name?defaultItem?.backen_developer:{},
        "designer": defaultItem?.designer && defaultItem?.designer?.name?defaultItem?.designer:{},
        "tester": defaultItem?.tester && defaultItem?.tester?.name?defaultItem?.tester:{},
        "client_intraction": "",
        "communication_mode": '',
        "communication_medium": '',
        "communication_tool": "",
        "communication_duration": "",
        "communication_unit":"min",
        "interaction_status": "",
        "resource_name": "",
        "feedback": "",
        "sdm_concerns": "",
        "pending_action": "",
        "pending_action_notes": "",
        "total_client":allData && allData?.sdmData?.length,
        "clientName":defaultItem?.clientName,
        "clientType": nm__clienttype[defaultItem?.clienttype],
        "date":date,
        "total_incentive":defaultItem?.total_incentive
    })

    useEffect(()=>{
        if(defaultItem){
            
        }
        setFormData({
            "opportunityid": defaultItem?.opportunityid,
            "topic": defaultItem?.topic,
            "opportunity_status": defaultItem?.opportunity_status,
            "service_id": defaultItem?.service_id,
            "opportunity_type": defaultItem?.opportunity_type === 232520000?"technical":"non technical",
            "opportunity_belongs_to": defaultItem?.opportunity_belongs_to,
            "opportunity_country": defaultItem?.opportunity_country,
            "sdm_name": defaultItem?.sdmData?.name,
            "sdm_email": defaultItem?.sdmData?.email,
            "total_billing_hours": defaultItem?.totalBillingHours,
            "consumed_billing_hours": defaultItem?.totalConsumedHours,
            "front_face": defaultItem?.frontFace && defaultItem?.frontFace?.name?defaultItem?.frontFace?.name:"-",
            "front_end_developer": defaultItem?.front_end_developer && defaultItem?.front_end_developer?.name?defaultItem?.front_end_developer:{},
            "backen_developer": defaultItem?.backen_developer && defaultItem?.backen_developer?.name?defaultItem?.backen_developer:{},
            "designer": defaultItem?.designer && defaultItem?.designer?.name?defaultItem?.designer:{},
            "tester": defaultItem?.tester && defaultItem?.tester?.name?defaultItem?.tester:{},
            "client_intraction": "",
            "communication_mode": '',
            "communication_medium": '',
            "communication_tool": "",
            "communication_duration": "",
            "communication_unit":"min",
            "interaction_status": "",
            "resource_name": "",
            "feedback": "",
            "sdm_concerns": "",
            "pending_action": "",
            "pending_action_notes": "",
            "date":date,
            "total_client":allData && allData?.sdmData?.length,
            "clientName":defaultItem?.clientName,
            "clientType": nm__clienttype[defaultItem?.clienttype],
            "total_incentive":defaultItem?.total_incentive
        })
    },[defaultItem])

    const handleCheckboxChange = (event) => {
        const { value, checked } = event.target;
      
        if (checked) {
          // Add value to the communicationModes array
          setCommunicationModes((prev) => {
            const updatedModes = [...prev, value];
            setFormData((prevFormData) => ({
              ...prevFormData,
              communication_mode: updatedModes.join(',') // Sync with formData
            }));
            return updatedModes;
          });
        } else {
          // Remove value from the communicationModes array
          setCommunicationModes((prev) => {
            const updatedModes = prev.filter((mode) => mode !== value);
            setFormData((prevFormData) => ({
              ...prevFormData,
              communication_mode: updatedModes.join(',') // Sync with formData
            }));
            return updatedModes;
          });
        }
      };
      

      const handleCheckboxChange_ = (event) => {
        const { value, checked } = event.target;
      
        // setMediumModes((prev) => {
        //   let updatedModes;
      
        //   if (checked) {
        //     // Add value when checked
        //     updatedModes = [...prev, value];
        //   } else {
        //     // Remove value when unchecked
        //     updatedModes = prev.filter((mode) => mode !== value);
        //   }
      
        //   // Update formData after updating modes
        //   setFormData((prevFormData) => ({
        //     ...prevFormData,
        //     communication_medium: updatedModes.join(',') // Convert array to comma-separated string
        //   }));
      
        //   return updatedModes; // Return the updated modes for setMediumModes
        // });
        setMediumModes(event.target.value)
        
        setFormData((prevFormData) => ({
            ...prevFormData,
            communication_medium: event.target.value  // Sync with formData
          }));  
    
    };
      
        //  console.log(communicationModes,mediumModes)
    const textarea = document.getElementById('technicalDesc-');
        const charCounter = document.getElementById('charCounter');
        const minChars = 100;
        if(textarea){
            textarea.addEventListener('input', function() {
                const currentLength = textarea.value.length;
                charCounter.textContent = `${currentLength} / ${minChars}`;
            
                // Change counter color if less than 100 characters
                if (currentLength < minChars) {
                  charCounter.classList.add('error');
                } else {
                  charCounter.classList.remove('error');
                }
              });
        }
   
      
    //   console.log(defaultItem)

    
    
    // console.log(defaultItem)
    // console.log(currentMonth)

    // const toggleBillingCard = () => {
    //     setBillingInfoCard(!billingInfoCard);
    // }

    const handleChange_modecomm = (event) => {
        setCommunicationModes(event.target.value)
        
        setFormData((prevFormData) => ({
            ...prevFormData,
            communication_mode: event.target.value  // Sync with formData
          }));
    };

    const handleChange = (event) => {
        if(event.target.value === "no"){
            setCommunicationModes([]);
            setMediumModes([])
            setClientInteraction('')
            setPendingActions('')
        }
        setSelectedOption(event.target.value);
        setFormData((prevFormData) => ({
            ...prevFormData,
            client_intraction: event.target.value  // Sync with formData
          }));
    };
    

    const clientInteractionChange = (event) => {
        setClientInteraction(event.target.value);
        setFormData((prevFormData) => ({
            ...prevFormData,
            interaction_status: event.target.value  // Sync with formData
          }));
    };

    const pendingActionsChange = (event) => {
        setPendingActions(event.target.value);
        setFormData((prevFormData) => ({
            ...prevFormData,
            pending_action: event.target.value  // Sync with formData
          }));
    };



    // Toggle billing card dropdown
    const toggleBillingCard = () => {
      
      setBillingInfoCard((prev) => !prev);
    };
  
  
    // Handle selecting a billing card
    const handleSelectBilling = (item) => {
        setDefaultItem(item);  
        setClientName(item?.clientName)
        setFormData((prevFormData) => ({
            ...prevFormData,
            communication_mode: '', // Sync with formData
            communication_medium:''
          }));
        setCommunicationModes([]);
        setMediumModes([])
        setClientInteraction('')
        setPendingActions('')
        setSelectedOption('')
                  // Set selected item
        setSearchQuery(item.clientName); // Show selected client name in input
        setBillingInfoCard(false);       // Close dropdown after selection
      };

 
     
    const handleResponsible = (name)=>{
        setFormData((prevFormData) => ({
            ...prevFormData,
            resource_name: name  // Sync with formData
          }));
    }
    const handleTextareaChange = (e) => {
        const newDesc = e.target.value;
        setTechnicalDesc(newDesc); // Update technicalDesc
        setFormData((prevFormData) => ({
          ...prevFormData,
          feedback: newDesc  // Sync with formData
        }));
      };
      const handleTextareaChange_ = (e) => {
        const newDesc = e.target.value;
       
        setFormData((prevFormData) => ({
          ...prevFormData,
          feedback: newDesc  // Sync with formData
        }));
      };

      const setSpecificConcern = (e) =>{
        setFormData((prevFormData) => ({
            ...prevFormData,
            sdm_concerns: e.target.value  // Sync with formData
          }));
      }

      const setpendingNotes = (e) =>{
        setFormData((prevFormData) => ({
            ...prevFormData,
            pending_action_notes: e.target.value  // Sync with formData
          }));
      }

      const setToolCommunition = (e) =>{
        setFormData((prevFormData) => ({
            ...prevFormData,
            communication_tool: e.target.value  // Sync with formData
          }));
      }
      const setcomDuration = (e) =>{
        
        setFormData((prevFormData) => ({
            ...prevFormData,
            communication_duration: e.target.value  // Sync with formData
          }));
      }

      const setHourmin = (e)=>{
        setFormData((prevFormData) => ({
            ...prevFormData,
            communication_unit: e.target.value  // Sync with formData
          }));
      }
    //   console.log(formData)

    const validateForm = () => {
        if (client_name === "") {
            toast.error("Select client from list first", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            return false;
        }
        
        // You can add more checks for other required fields like clientInteraction, pendingActions, etc.
        return true;
    };
      const saveSdmWorkReport = async(e)=>{
        e.preventDefault();
        
        // if(client_name === ""){
        //     toast.error(`Select client from list first`, {
        //         position: "top-right", // Position of the toast
        //         autoClose: 5000,           // Toast will close after 5 seconds (5000 ms)
        //         hideProgressBar: false,     // Hide the progress bar
        //         closeOnClick: true,        // Close the toast when clicked
        //         pauseOnHover: true,        // Pause the toast when hovering over it
        //         draggable: true,           // Make the toast draggable
        //     });
        //     return false
        // }

        if (!validateForm()) {
            return; // Stop form submission if validation fails
        }

        setIsLoading(true)

         callAxios(`${environment.REACT_APP_API_URL_Live}api/v1/sdm-update/add-work`,formData,"post").then((workReportAdded)=>{
                    if(workReportAdded.status === 201){
                        e.target.reset();
                        setIsLoading(true)
                        setShowWorkUpdateModal(false);
                         setIsLoading(false)
                         setClientName('')
                        // setData(monthData.data)
                        // setDefaultItem(monthData.data.sdmData[0])
                        //   toast.success(`Work report submitted for client ${defaultItem.clientName}`, {
                        //                 position: "top-right", // Position of the toast
                        //                 autoClose: 5000,           // Toast will close after 5 seconds (5000 ms)
                        //                 hideProgressBar: false,     // Hide the progress bar
                        //                 closeOnClick: true,        // Close the toast when clicked
                        //                 pauseOnHover: true,        // Pause the toast when hovering over it
                        //                 draggable: true,           // Make the toast draggable
                        //             });
                                    setFormData((prevFormData) => ({
                                        ...prevFormData,
                                        communication_mode: '', // Sync with formData
                                        communication_medium:''
                                      }));
                                    setCommunicationModes('');
                                    setMediumModes('')
                                    setClientInteraction('')
                                    setPendingActions('')
                                    setSelectedOption('')
                                    getListingDataFromStore_refresh(date, "himansunayak@virtualemployee.com","afterSaveRecord")
                    }
                    if(workReportAdded.status ===200){
                        e.target.reset();
                        setIsLoading(false)
                        if(workReportAdded.data.statusCode === 501){
                            toast.error(`${workReportAdded.data.message}`, {
                                position: "top-right", // Position of the toast
                                autoClose: 5000,           // Toast will close after 5 seconds (5000 ms)
                                hideProgressBar: false,     // Hide the progress bar
                                closeOnClick: true,        // Close the toast when clicked
                                pauseOnHover: true,        // Pause the toast when hovering over it
                                draggable: true,           // Make the toast draggable
                            });
                        }
                    }
                }).catch((error)=>{
                    setIsLoading(false)
                    // setData([])
                    //  toast.error('Something went wrong!', {
                    //                     position: "top-right", // Position of the toast
                    //                     autoClose: 5000,           // Toast will close after 5 seconds (5000 ms)
                    //                     hideProgressBar: false,     // Hide the progress bar
                    //                     closeOnClick: true,        // Close the toast when clicked
                    //                     pauseOnHover: true,        // Pause the toast when hovering over it
                    //                     draggable: true,           // Make the toast draggable
                    //                 });
                })
      }
    return (
        <Modal show={showWorkUpdateModal} centered onHide={closeWorkUpdateModal} backdrop="static" keyboard={false} className="p-0" dialogClassName="work-update-modal" contentClassName='rounded overflow-hidden'>
            <Modal.Body className="text-dark px-lg-4 px-xl-5 py-4">
                <div className="heading fs-3 fw-semibold lh-sm mb-3 mb-lg-4">Work Update</div>
                <div className="billing-info-section mb-4">
                    <div className="row g-3 gx-xl-4">
                        <div className="col-md-4 col-xl-3 col-xxl-2 pe-xl-0">
                            <Form.Group controlId="billingMonth">
                                <Form.Label>Billing Month</Form.Label>
                                <InputGroup>
                                    <Form.Control type="month" className='border-end-0 pe-0' id="billingMonth" defaultValue={currentMonth} autoComplete='off' disabled />
                                    <InputGroup.Text id="billingMonth" className="ps-0"><i className="bi bi-lock-fill text-secondary"></i></InputGroup.Text>
                                </InputGroup>
                            </Form.Group>
                        </div>
                        <div className="col-md-8 col-xl-3 col-xxl-4">
                        <Form.Group controlId="workUpdateForBilling">
  <Form.Label>Work Update for the billing</Form.Label>
  <div className="position-relative">
    {/* Input Field (Clickable to Show Dropdown) */}
    <Form.Control 
      onClick={toggleBillingCard} 
      className="form-select shadow-none" 
      placeholder="Select client name"
      value={defaultItem?.clientName}
      autoComplete="off" 
      readOnly
    />
    
    {/* Dropdown for Billing Info */}
    {billingInfoCard && (
      <div className="all-biilings-wrapper bg-white position-absolute start-0 top-100 end-0 d-flex flex-column gap-2 rounded-3 overflow-auto shadow mt-1 p-1" style={{ maxHeight: '300px' }}>
        
        {/* Render Billing Data Without Search Filter */}
        {allData?.dropdownItem.length > 0 ? (
          allData?.dropdownItem.map((item, index) => (
            <div 
              className="billing-info-card border rounded-3 text-capitalize p-3 z-1" 
              key={index} 
              onClick={() => handleSelectBilling(item)}
              style={{ cursor: 'pointer' }}
            >
              <div className="d-flex flex-wrap justify-content-between gap-1 mb-2">
                <div className="left-info">
                  <div className="client-name fs-5 fw-semibold lh-sm">{item?.clientName}</div>
                  <div className="comp-name text-muted fs-14 fw-light">{item?.opportunity_belongs_to}</div>
                </div>
                <div className="country-name">{item?.opportunity_country}</div>
              </div>
              <div className="d-flex flex-wrap column-gap-2 mb-2">
                <span>{nm__clienttype[item?.clienttype]}</span> |
                <span className="text-uppercase">{item?.service_id}</span> |
                <span>{item?.opportunity_type === 232520000 ? "Technical" : "Non Technical"}</span>
              </div>
              <div className="d-flex flex-wrap justify-content-between column-gap-2">
                <div className="left text-primary">Service Status: <span className="text-success fw-semibold ps-1">{nm_servicestatus[item?.servicestatus]}</span></div>
                <div className="right text-primary">Payment Status: <span className="text-dark fw-semibold px-1">{nm_paymentstatus[item?.paymentstatus]}</span></div>
              </div>
            </div>
          ))
        ) : (
          <div className="text-center text-muted py-2">No results found.</div>
        )}

      </div>
    )}
  </div>
</Form.Group>



                        </div>
                        <div className="col-12 col-xl-6">
                            <div className="billing-info">
                                <Form.Label className="d-none d-xl-block">&nbsp;</Form.Label>
                                <div className="billing-card bg-secondary bg-opacity-25 rounded d-flex flex-wrap justify-content-between gap-1 px-3 py-2">
                                    <div className="billing-hrs d-flex flex-wrap align-items-center row-gap-1 column-gap-3">
                                        <div className="label text-primary fs-14">Total Billing Hours</div>
                                        <div className="value fw-semibold">{defaultItem?.totalBillingHours}</div>
                                    </div>
                                    <div className="billing-hrs d-flex flex-wrap align-items-center row-gap-1 column-gap-3">
                                        <div className="label text-primary fs-14">Consumed Billing Hours</div>
                                        <div className="value fw-semibold">{defaultItem?.totalConsumedHours}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="emp-info-section mb-4">
                    {defaultItem?.frontFace && defaultItem?.frontFace?.name?
                    <div className="emp-info border-bottom border-2 pb-3 mb-3">
                        <div className="emp-name text-dark fs-5 fw-bold text-capitalize">{defaultItem?.frontFace?.name}</div>
                        <div className="emp-role">Front Face for Client</div>
                    </div>
                    :null}
                    <div className="row g-3 gx-xl-4">
                    {defaultItem?.front_end_developer && defaultItem?.front_end_developer?.name?
                        <div className="col-sm-6 col-xl-3">
                            <div className="info-box rounded-3 h-100 text-capitalize p-3">
                                <div className="resource-designation text-muted fw-light lh-sm mb-1">Front End</div>
                                <div className="resource-name text-dark fs-5 fw-bold lh-sm">{defaultItem?.front_end_developer?.name}</div>
                                <div className="resource-email fw-light text-lowercase text-break">{defaultItem?.front_end_developer?.email}</div>
                                <div className="resource-billing-time text-primary fs-6 fw-bold mt-1">{defaultItem?.front_end_developer?.hourConsumed}Hrs/{defaultItem?.front_end_developer?.hourAssigned}Hrs</div>
                            </div>
                        </div>
                        :null}
                        {defaultItem?.backen_developer && defaultItem?.backen_developer?.name?
                        <div className="col-sm-6 col-xl-3">
                            <div className="info-box rounded-3 h-100 text-capitalize p-3">
                                <div className="resource-designation text-muted fw-light lh-sm mb-1">Back End</div>
                                <div className="resource-name text-dark fs-5 fw-bold lh-sm">{defaultItem?.backen_developer?.name}</div>
                                <div className="resource-email fw-light text-lowercase text-break">{defaultItem?.backen_developer?.email}</div>
                                <div className="resource-billing-time text-primary fs-6 fw-bold mt-1">{defaultItem?.backen_developer?.hourConsumed}Hrs/{defaultItem?.backen_developer?.hourAssigned}Hrs</div>
                            </div>
                        </div>
                        :null}
                        {defaultItem?.designer && defaultItem?.designer?.name?
                        <div className="col-sm-6 col-xl-3">
                            <div className="info-box rounded-3 h-100 text-capitalize p-3">
                                <div className="resource-designation text-muted fw-light lh-sm mb-1">Designer</div>
                                <div className="resource-name text-dark fs-5 fw-bold lh-sm">{defaultItem?.designer?.name}</div>
                                <div className="resource-email fw-light text-lowercase text-break">{defaultItem?.designer?.email}</div>
                                <div className="resource-billing-time text-primary fs-6 fw-bold mt-1">{defaultItem?.designer?.hourConsumed}Hrs/{defaultItem?.designer?.hourAssigned}Hrs</div>
                            </div>
                        </div>
                        :null}
                        {defaultItem?.tester && defaultItem?.tester?.name?
                        <div className="col-sm-6 col-xl-3">
                            <div className="info-box rounded-3 h-100 text-capitalize p-3">
                                <div className="resource-designation text-muted fw-light lh-sm mb-1">Tester</div>
                                <div className="resource-name text-dark fs-5 fw-bold lh-sm">{defaultItem?.tester?.name}</div>
                                <div className="resource-email fw-light text-lowercase text-break">{defaultItem?.tester?.email}</div>
                                <div className="resource-billing-time text-primary fs-6 fw-bold mt-1">{defaultItem?.tester?.hourConsumed}Hrs/{defaultItem?.tester?.hourAssigned}Hrs</div>
                            </div>
                        </div>
                        :null}
                    </div>
                </div>

                <div className="form-info-section">
                    <div className="heading text-dark fs-5 fw-bold mb-4 ms-1 ps-2">Client Communication</div>
                    <Form onSubmit={saveSdmWorkReport}>
                        <div className="info-box mb-3">
                            <div className="form-label mb-2">Did you interact with the client today?</div>
                            <div className="radio-wrapper">
                                <div className="form-check form-check-inline me-4 me-sm-5">
                                    <input className="form-check-input" type="radio" name="interactWithClient" value="yes" id="YesInteracted" checked={selectedOption === 'yes'} onChange={handleChange} required/>
                                    <label className="form-check-label lh-sm" for="YesInteracted">Yes</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" name="interactWithClient" value="no" id="NoInteracted" checked={selectedOption === 'no'} onChange={handleChange} required/>
                                    <label className="form-check-label lh-sm" for="NoInteracted">No</label>
                                </div>
                            </div>
                        </div>

                        {selectedOption === 'no' && (
                            <Form.Group className="mb-3">
                                <Form.Label className="d-flex justify-content-between gap-3">Mention the reason here <span className="text-muted fs-14"  id="charCounter">0 / 100</span></Form.Label>
                                <Form.Control as="textarea" rows={5} className="py-2" placeholder="Write here..." autoComplete="off" id="technicalDesc-" onChange={handleTextareaChange} minLength={100} required/>
                            </Form.Group>
                        )}

                        {selectedOption === 'yes' && (
                            <div className="row g-3 gx-xl-4 gx-xxl-5 mb-4">
                                <div className="col-12">
                                    <div className="info-box">
                                        <div className="form-label mb-2">Mode of communication?</div>
                                        <div className="checks-wrapper">
                                            <div className="form-check form-check-inline me-4 me-sm-5">
                                                <input className="form-check-input" name="modeofcomm" type="radio" value="written" id="Written" checked={communicationModes === 'written'} onChange={handleChange_modecomm}
              />
                                                <label className="form-check-label lh-sm" for="Written">Written</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" name="modeofcomm" type="radio" value="verbal" id="Verbal"  checked={communicationModes === 'verbal'} onChange={handleChange_modecomm}
              />
                                                <label className="form-check-label lh-sm" for="Verbal">Verbal</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {communicationModes.length >0?
                                <div className="col-lg-6">
                                    <div className="info-box">
                                        <div className="form-label mb-2">Medium of communication?</div>
                                        <div className="checks-wrapper">
                                            {communicationModes === 'written'?
                                            <>
                                            <div className="form-check form-check-inline me-4 me-sm-5">
                                                <input className="form-check-input" name="audiovideo" type="radio" value="Email" id="Email" onChange={handleCheckboxChange_}
              checked={mediumModes === 'Email'}/>
                                                <label className="form-check-label lh-sm" for="Email">Email</label>
                                            </div>

                                            <div className="form-check form-check-inline me-4 me-sm-5">
                                                <input className="form-check-input" name="audiovideo" type="radio" value="Chat" id="Chat" onChange={handleCheckboxChange_}
              checked={mediumModes === 'Chat'}/>
                                                <label className="form-check-label lh-sm" for="Email">Chat</label>
                                            </div>
                                            </>
                                            
                                            
                                            :null}
                                            {communicationModes === 'verbal'?
                                            <>
                                                <div className="form-check form-check-inline me-4 me-sm-5">
                                                <input className="form-check-input" name="audiovideo" type="radio" value="AudioCall" id="AudioCall" onChange={handleCheckboxChange_}
              checked={mediumModes==="AudioCall"}/>
                                                <label className="form-check-label lh-sm" for="AudioCall">Audio Call</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" name="audiovideo" type="radio" value="VideoCall" id="VideoCall" onChange={handleCheckboxChange_}
              checked={mediumModes === 'VideoCall'}/>
                                                <label className="form-check-label lh-sm" for="VideoCall">Video Call</label>
                                            </div>
                                            </>
                                            :null}
                                            
                                        </div>
                                    </div>
                                </div>
                                :null}
                                 {communicationModes.length >0?
                                <div className="col-lg-6">
                                    <div className="row g-3 gx-xl-4 gx-xxl-5">
                                        <div className="col-sm-6">
                                            <Form.Group controlId="exampleForm.ControlInput1">
                                                <Form.Label>Tools of communication</Form.Label>
                                                <Form.Control type="text" placeholder="Slack, outlook ..etc"  autoComplete="off" onChange={setToolCommunition} required/>
                                            </Form.Group>
                                        </div>
                                        {communicationModes === 'verbal'?
                                        <div className="col-sm-6">
                                            <Form.Group controlId="exampleForm.ControlInput1">
                                                <Form.Label>Communication duration</Form.Label>
                                                <div className="d-flex gap-3">
                                                    <Form.Control type="number" placeholder="Duration" defaultValue={0} autoComplete="off" onChange={setcomDuration}  required/>
                                                    <Form.Select aria-label="Default select example" style={{ width: '120px' }} onChange={setHourmin} required>
                                                        <option value="min">Minutes</option>
                                                        <option value="hour">Hour</option>
                                                    </Form.Select>
                                                </div>
                                            </Form.Group>
                                        </div>
                                        :null}
                                    </div>
                                </div>
                                :null}
                              
                                <div className="col-12">
                                    <div className="info-box">
                                        <div className="form-label mb-2">How was the client interaction?</div>
                                        <div className="radio-wrapper">
                                            <div className="form-check form-check-inline me-4 me-sm-5">
                                                <input className="form-check-input" type="radio" name="clientInteraction" value="positive" id="PositiveInteraction" checked={clientInteraction === 'positive'} onChange={clientInteractionChange} required/>
                                                <label className="form-check-label lh-sm" for="PositiveInteraction">Positive</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="radio" name="clientInteraction" value="concerned" id="ConcernedInteraction" checked={clientInteraction === 'concerned'} onChange={clientInteractionChange} required />
                                                <label className="form-check-label lh-sm" for="ConcernedInteraction">Concerned</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {clientInteraction === 'positive' && (
                                    <>
                                        <div className="col-lg-6">
                                            <Form.Group>
                                                <Form.Label>Resource Responsible for Client Happiness</Form.Label>
                                                <Form.Control type="text" placeholder="Enter name" autoComplete="off" onChange={(e)=>{handleResponsible(e.target.value)}} required/>
                                            </Form.Group>
                                        </div>
                                        <div className="col-lg-6">
                                            <Form.Group>
                                                <Form.Label className="d-flex justify-content-between gap-3">Reason for Positive Feedback<span className="text-muted fs-14"  id="charCounter">0 / 100</span></Form.Label>
                                                <Form.Control as="textarea" rows={5} className="py-2" placeholder="Write here..." autoComplete="off" id="technicalDesc-" onChange={handleTextareaChange_} minLength={100} required/>
                                            </Form.Group>
                                        </div>
                                    </>
                                )}

                                {clientInteraction === 'concerned' && (
                                    <>
                                        <div className="col-lg-6">
                                            <Form.Group>
                                                <Form.Label>Resource Responsible for Client Concerned</Form.Label>
                                                <Form.Control type="text" placeholder="Enter name" autoComplete="off" onChange={(e)=>{handleResponsible(e.target.value)}} required/>
                                            </Form.Group>
                                        </div>
                                        <div className="col-lg-6">
                                            <Form.Group>
                                                <Form.Label className="d-flex justify-content-between gap-3">Factors that making client Concern<span className="text-muted fs-14"  id="charCounter">0 / 100</span></Form.Label>
                                                <Form.Control as="textarea" rows={5} className="py-2" placeholder="Write here..." autoComplete="off" id="technicalDesc-" onChange={handleTextareaChange_} minLength={100} required/>
                                            </Form.Group>
                                        </div>
                                    </>
                                )}

                                <div className="col-12">
                                    <Form.Group>
                                    
                                        <Form.Label className="d-flex justify-content-between gap-3">Any specific challanges or concerns you'd like to highlight?</Form.Label>
                                        <Form.Control as="textarea" rows={5} className="py-2" placeholder="Write here..." autoComplete="off"  onChange={setSpecificConcern} required/>
                                    </Form.Group>
                                </div>

                                <div className="col-12">
                                    <div className="info-box">
                                        <div className="form-label mb-2">Are there any pending actions or follow-ups for the client?</div>
                                        <div className="radio-wrapper">
                                            <div className="form-check form-check-inline me-4 me-sm-5">
                                                <input className="form-check-input" type="radio" name="pendingActions" value="yes" id="YesPending" checked={pendingActions === 'yes'} onChange={pendingActionsChange} required/>
                                                <label className="form-check-label lh-sm" for="YesPending">Yes</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="radio" name="pendingActions" value="no" id="NoPending" checked={pendingActions === 'no'} onChange={pendingActionsChange} required/>
                                                <label className="form-check-label lh-sm" for="NoPending">No</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {pendingActions === 'yes' && (
                                    <div className="col-12">
                                        <Form.Group>
                                            <Form.Label>Describe the follow-ups</Form.Label>
                                            <Form.Control as="textarea" rows={5} className="py-2" placeholder="Write here..." autoComplete="off" onChange={setpendingNotes} required/>
                                        </Form.Group>
                                    </div>
                                )}
                            </div>
                        )}

                        <div className="btn-wrapper d-flex flex-wrap flex-column flex-sm-row justify-content-center gap-2 gap-sm-3 pt-2">
                            <Button type="reset" variant='secondary' className='btn-custom fs-18 px-5 py-2' onClick={closeWorkUpdateModal}>Cancel</Button>
                            <Button type="submit" variant='success' className='btn-custom fs-18 px-5 py-2'>Submit</Button>
                        </div>
                    </Form>
                </div>
            </Modal.Body>
        </Modal>
    )
}
