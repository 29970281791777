import React, { createContext, useContext } from 'react';

const SdmContext = createContext();

export const useSdm = () => useContext(SdmContext);

export const SdmProvider = ({ children, sdmListing, setShowWorkUpdateModal,todayDate, sdmData }) => (
    <SdmContext.Provider value={{sdmListing,setShowWorkUpdateModal,todayDate, sdmData}}>
        {children}
    </SdmContext.Provider>
);
