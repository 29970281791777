


import React, { useState } from "react";
import './walletnotification.css';
import imgDefault from '../resources/images/Avatar.png';
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { formatDate, formatTodayDate } from "../utils/utils";

export const WalletNotification = ({message,isShow, userRole,topic, setWalletNotification, setWalletOpp,setUtype,userT}) => {
  
    // console.log(message)
    let navigate = useNavigate();

    const [messageCard, setMessageCard] = useState(false);



    

    const handleClose=()=>{
        // console.log("in close notification")
        setWalletNotification(null)
        setWalletOpp(null)
        setUtype(null)
    }


    const handleRedeemFilter = (filter) =>{
        setWalletNotification(null)
        setWalletOpp(null)
        setUtype(null)
        const urole = "BDM";
        // const todayDate= formatDate(new Date())
        navigate("/wallet",{state:{filterOption:"redeem-pending",userRole:urole,startDate:"2024-01-01", endDate:formatTodayDate(),tab:urole}})
    }
    
    return (
        <>
           <div className="notification-wallet" >
           <button class="close-btn" onClick={handleClose}>&times;</button>
        <div className="notification-icon pointer" onClick={handleRedeemFilter}>
            <img src={require('../resources/images/wallet/walletnotification.png')} alt="Wallet Icon" />
        </div>
        <div className="notification-content pointer" onClick={handleRedeemFilter}>
            <h3>Wallet Notification | <span className="text-uppercase">{userT}</span></h3>
            {/* <p><h4>{userT}</h4></p> */}
            <p>Management has approved your incentive for</p>
            <p className="highlight">{topic}</p>
            <p>You can redeem now.</p>
        </div>
    </div>
        </>
    )
}