import React from "react";
import moment from 'moment';
import PaidIcon from "../../resources/images/wallet/paid.svg";
import { nm__clienttype, nm_paymentstatus, nm_servicemonth } from "../../utils/codes";


export const Incentivepaid = ({ incentivepaidData, allpaidcredit }) => {
    incentivepaidData?.list?.forEach(item => {
        const areBothTrue = item.userList.every(user => user.is_account_credited);
        if (areBothTrue) {
            item.clientDetails.bothAreTrue = "paid";
        } else {
            item.clientDetails.bothAreTrue = "pending";
        }
    });

    return (
        <>
            {/* {incentivepaidData?.list?.length >= 1 ? */}
            {allpaidcredit?.length >= 1 ?
                <>
                    {allpaidcredit?.map((items, index) => (
                        <>
                            {/* {items?.clientDetails.bothAreTrue == "paid" ? */}
                            {items !== undefined &&
                            <div className="info-card dark-theme-bg2 rounded-3 overflow-hidden">
                                <div className="p-3" style={{ backgroundColor: '#1C5138' }}>
                                    <div className="d-flex flex-wrap justify-content-between gap-2">
                                        <div className="left-section info-box text-capitalize">
                                            <div className="label fs-5 fw-medium text-warning lh-sm">{items?.clientDetails?.client_name}</div>
                                            <div className="value text-white text-opacity-75 text-break">{items?.clientDetails.topic}</div>
                                        </div>
                                        <div className="right-section info-box text-capitalize text-end me-xl-5 pe-xl-5">
                                            <img src={PaidIcon} alt="PaidIcon" className="icon-img" style={{ width: '80px' }} />
                                        </div>
                                    </div>
                                </div>
                                <div className="p-3">
                                    <div className="row row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 gx-2 gy-3">
                                        <div className="info-box text-capitalize">
                                            <div className="label fs-14 text-warning">Service Month</div>
                                            <div className="value text-white text-opacity-75">{nm_servicemonth[items?.clientDetails.service_month]}</div>
                                        </div>
                                        <div className="info-box text-capitalize">
                                            <div className="label fs-14 text-warning">Service in CRM</div>
                                            <div className="value text-white text-opacity-75 text-uppercase">{items?.clientDetails.service_id}</div>
                                        </div>
                                        <div className="info-box text-capitalize">
                                            <div className="label fs-14 text-warning">Zoho Client ID</div>
                                            <div className="value text-white text-opacity-75 text-uppercase">{items?.clientDetails.client_id}</div>
                                        </div>
                                        <div className="info-box text-capitalize">
                                            <div className="label fs-14 text-warning">Zoho Invoice ID</div>
                                            <div className="value text-white text-opacity-75 text-uppercase">{items?.clientDetails.invoice_id}</div>
                                        </div>
                                        <div className="info-box text-capitalize">
                                            <div className="label fs-14 text-warning">Billing ID</div>
                                            <div className="value text-white text-opacity-75 text-uppercase">{items?.clientDetails.billing_id}</div>
                                        </div>
                                        <div className="info-box text-capitalize">
                                            <div className="label fs-14 text-warning">Payment Status</div>
                                            <div className="value text-white text-opacity-75">{nm_paymentstatus[items?.clientDetails.payment_status]}</div>
                                        </div>
                                        <div className="info-box text-capitalize">
                                            <div className="label fs-14 text-warning">Installment Approver</div>
                                            <div className="value text-white text-opacity-75">{items?.clientDetails.billing_manager}</div>
                                        </div>
                                        <div className="info-box text-capitalize">
                                            <div className="label fs-14 text-warning">Client Type</div>
                                            <div className="value text-white text-opacity-75">{nm__clienttype[items?.clientDetails.client_type].replace(/\s+\S+$/, '')}</div>
                                        </div>
                                    </div>
                                    <hr className="divider border border-top-2 border-secondary border-opacity-75 my-3" />
                                    <div className="account-user d-flex flex-column gap-2">
                                        {items?.userList?.length >= 1 ?
                                            <>
                                                {items?.userList?.map((itemsData, index2) => (
                                                    <div className="dark-theme-bg3 rounded-3 p-3">
                                                        <div className="">
                                                            <div className="row row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 gx-2 gy-3">
                                                                <div className="info-box text-capitalize">
                                                                    <div className="d-flex align-items-center gap-2">
                                                                        <div className="user-img">
                                                                            <img src={require('../../resources/images/Avatar.png')} alt="User" className="img-fluid rounded-circle" style={{ height: '45px', width: '45px', minWidth: '45px' }} />
                                                                        </div>
                                                                        <div className="user-info text-truncate text-capitalize">
                                                                            <div className="user-name text-white fw-bold text-truncate">{itemsData?.name}</div>
                                                                            {/* <div className="opportunity-added fs-14 text-white text-wrap">Web Designer | <span className="ms-1">08-08-2024</span></div> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="info-box text-capitalize">
                                                                    <div className="label fs-14 text-warning">Role</div>
                                                                    <div className="value text-white text-uppercase">{itemsData?.type}</div>
                                                                </div>
                                                                <div className="info-box text-capitalize">
                                                                    <div className="label fs-14 text-warning">Requested Amount</div>
                                                                    <div className="value text-white fw-bold text-uppercase">₹{itemsData?.redeem_amount}</div>
                                                                </div>
                                                                <div className="info-box text-capitalize">
                                                                    <div className="label fs-14 text-warning">Requested on</div>
                                                                    <div className="value text-white text-uppercase">{itemsData?.requested_date != null ? moment(`${itemsData?.requested_date}`).format('DD-MM-YYYY') : "Yet to request"}</div>
                                                                </div>
                                                                {itemsData?.is_account_credited ?
                                                                    <div className="info-box fs-14 text-capitalize ps-xl-2">
                                                                        <div className="label text-warning">Credit Transaction Details</div>
                                                                        <div className="value text-white text-uppercase">{itemsData?.send_money_transaction_id} <span className="d-block">{itemsData?.credited_date != null && itemsData?.credited_date !== undefined && itemsData?.credited_date !== '' ? moment(`${itemsData?.credited_date}`).format('DD-MM-YYYY') : ""}</span></div>
                                                                    </div>
                                                                    : ""}
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </>
                                            : ""
                                        }
                                    </div>
                                </div>
                            </div>
                            } 
                        </>
                    ))}
                </> :
                ""
            }
        </>
    )
}