import React, { useEffect, useState } from "react";
import './wallet.scss';
import { Button, Form, OverlayTrigger, Popover, ProgressBar } from 'react-bootstrap';
import Slider from "react-slick";

import SearchIcon from '../resources/images/search.svg';
import GreenBGBanner from '../resources/images/wallet/green-bg.png';
import BlueBGBanner from '../resources/images/wallet/blue-bg.png';
import PurpleBGBanner from '../resources/images/wallet/purple-bg.png';
import OrangeBGBanner from '../resources/images/wallet/orange-bg.png';

// Import slick css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import OverAllStatsChart from "./OverAllStatsChart";
import MyBDMStatsChart from "./MyBDMStatsChart";
import MySDMStatsChart from "./MySDMStatsChart";
import MySupportedBDMStatsChart from "./MySupportedBDMStatsChart";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebaseConfig";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { setUserInfo } from "../store/actions";
import { callAxios, formatTodayDate, log__ } from "../utils/utils";
import { environment } from "../environment";
import { statuscode } from "../utils/codes";
import { IncentivePolicy } from "../utils/IncentivePolicy";

import { ReadMoreModal } from "./ReadMoreModal";
import { formatDate } from "fullcalendar/index.js";



const WalletDashboard = ({ setLoggedinUser, userRole }) => {

    const [isLoading, setIsLoading] = useState(true)
    const [user, loading, error] = useAuthState(auth);
    const [dashboardData, setDashboardData] = useState([])
    let navigate = useNavigate();

        // Read more modal
const [showReadmoreModal, setShowReadmoreModal] = useState(false);
const closeReadmoreModal = () => setShowReadmoreModal(false);
const openReadmoreModal = () => setShowReadmoreModal(true);
const [readMoreText, setReadMoreText_] = useState({});
const [startReadTime, setStartReadTime] = useState(null);
  const [progress, setProgress] = useState(0);

var settings = {
    arrows: false,
    autoplaySpeed:5000,
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
}

var settingsChart = {
    arrows: false,
 
    dots: true,
    infinite: true,
    
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
}

    const [startDate, setStartDate] = useState(() => {
        const startDD = `${new Date().getFullYear()}-${String(new Date().getMonth() + 1).padStart(2, '0')}-01`;
        return startDD;
      });
      const [endDate, setEndDate] = useState(() => {
          const date = new Date();
          const syear = date.getFullYear();
          const smonth = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
          const sday = String(date.getDate()).padStart(2, '0');
          return `${syear}-${smonth}-${sday}`;
      });

    const loadDashboard = (userRole) =>{
        setIsLoading(true)
        const progressInterval = setInterval(() => {

            setProgress((prev) => (prev < 90 ? prev + 1 : prev)); // Increment by 1%

          }, 900);
        callAxios(`${environment.REACT_APP_API_URL_Live}api/v1/wallet/get-dashboard-data`,{userType:userRole,startDate, endDate,card_type: ["individual", "invalidpolicy", "beforeOct24"]},"post").then((res)=>{
            setProgress(100);

            setTimeout(() => {
                clearInterval(progressInterval);
                setProgress(0);
            //  log__(res.data)
            setDashboardData(res.data)
            setIsLoading(false)
        }, 1000);

          })
    } 

    useEffect(() => {
        if (loading) return;
        if (!user) navigate("/");
        else {
    
          const userInfo = {
            uid: user?.uid,
            email: user?.email,
            displayName: user?.displayName,
            accessToken: user?.accessToken,
            idToken: user?.idToken,
          };
    
        setLoggedinUser(userInfo);
        loadDashboard(userRole);
    
        }
      }, [user, loading, userRole]);

        const keyToSearch = "new_hire_part_time";

        // Use find to get the first matching object
        // log__(Array.isArray(dashboardData?.earningByTypes) &&
        // dashboardData.earningByTypes.find(obj => obj.hasOwnProperty("new_hire_part_time")))

        // log__(dashboardData.earningByTypes['new_hire_part_time']);

        const handalSearch = async() =>{
            loadDashboard(userRole)
          }

          const naviateToWallet = (e)=>{
            e.preventDefault();
            navigate("/wallet",{state:{startDate:startDate, endDate:endDate,tab:'BDM'}})
          }
          const naviateToWalletBDM = (e) => {
            e.preventDefault();
            navigate("/wallet", { state: { startDate:startDate, endDate:endDate,tab:'BDM' } })
        }
        const naviateToWalletSDM = (e) => {
            e.preventDefault();
            navigate("/wallet", { state: {  startDate:startDate, endDate:endDate,tab:'SDM' } })
        }
        const naviateToWalletSBDM = (e) => {
            e.preventDefault();
            navigate("/wallet", { state: { startDate:startDate, endDate:endDate,tab:'SBDM' } })
        }

          const setReadMoreText = async(object) =>{
            setReadMoreText_(object)
            setStartReadTime(new Date())
        }

        const handleListDisputeFilter = (filter) =>{
            if(filter === "redeem-pending"){
                navigate("/wallet",{state:{filterOption:filter,userRole:userRole,startDate:"2024-10-01", endDate:formatTodayDate(),tab:'BDM'}})
            } else{
                navigate("/wallet",{state:{filterOption:filter,userRole:userRole,startDate:"2024-01-01", endDate:formatDate(new Date()),tab:'BDM'}})
            }
           
        }

    return (
        <div className="WalletDashboard py-3">
             {/* {isLoading? <div className="loading" style={{zIndex:9999}}>Loading&#8230;</div>:null} */}
             {isLoading ?
            <div className="loader-overlay" id="loader" style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                height: '100vh',
                background: 'rgba(0, 0, 0, 0.8)',
                backdropFilter: 'blur(2px)'
            }}>
                <img src={require('../resources/images/wallet/walletpreloader.gif')} alt="Balance" className="img-fluid" style={{ maxWidth: '200px', margin: "0 auto" }} />

                <div className="text-center w-50">
                    <p>Hi <b className="text-warning">{user?.displayName}</b>, this is Sheela! Calculating your earnings – hold tight, your wallet's a little heavy!  </p>

                    <p>You know <b className="text-warning">{user?.displayName}</b>, <b>Announcement Section</b> is your go-to space for important system insights and usage tips. I’ll keep sharing updates here – stay tuned and check back often!</p>

                    <p><ProgressBar animated now={progress} label={`${progress}%`}/></p>
                </div>

            </div>
            :null}
            <div className="container-fluid px-xxl-5">
                <div className="Wallet-page d-flex flex-wrap flex-column flex-md-row align-items-md-center justify-content-between gap-2 mb-3">
                    <div className="fs-3 fw-semibold text-light lh-sm text-nowrap">My Wallet</div>
                    <div className="date-wrapper d-flex flex-wrap align-items-md-center gap-2 gap-lg-3">
                        <Form.Group className="d-flex flex-column flex-md-row align-items-md-center flex-fill gap-1 gap-md-2 gap-xl-3" controlId="dateFrom">
                            <Form.Label className="m-0">From</Form.Label>
                            <Form.Control type="date" className="bg-transparent py-2"
                               defaultValue={`${new Date().getFullYear()}-${String(new Date().getMonth() + 1).padStart(2, '0')}-01`}
                               onChange={(e) => setStartDate(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="d-flex flex-column flex-md-row align-items-md-center flex-fill gap-1 gap-md-2 gap-xl-3" controlId="dateTo">
                            <Form.Label className="m-0">To</Form.Label>
                            <Form.Control type="date" className="bg-transparent py-2"
                                defaultValue={new Date().toJSON().slice(0, 10)}
                                onChange={(e) => setEndDate(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="d-flex flex-column justify-content-end flex-fill">
                            <Button variant="secondary" className="rounded-2 py-2" onClick={handalSearch}>
                                <img src={SearchIcon} alt="" className="img-fluid align-text-top" />
                                {/* <span className="d-md-none ms-1">Search</span> */}
                            </Button>
                        </Form.Group>
                    </div>
                </div>

               

<section className="announcement-section mb-4">
                    <div className="row g-3 g-xxl-4">
                        <div className="col-lg-4 col-xl-5">
                        <div className="info-card rounded-3 h-100 p-3 p-xxl-4" style={{ backgroundColor: '#322879' }}>



<div className="info-box h-100 d-flex flex-column">
    <div className="heading-label text-white fs-5 fw-semibold lh-sm mb-3"><i class="bi bi-megaphone-fill me-2"></i> Announcement</div>
    <Slider {...settings} className="custom-slider flex-fill pb-4">
        {dashboardData && dashboardData?.annoucements && dashboardData?.annoucements.length > 0 ?
            dashboardData && dashboardData?.annoucements && dashboardData?.annoucements.map((item) => (
                <>
                    <div className="description text-white text-opacity-75 text-truncate">
                        {item.title}
                    </div>
                    <div className="btn-wrapper mt-3 mb-3">
                        <Button variant="warning" size="sm" className="px-3" onClick={() => { openReadmoreModal(); setReadMoreText(item) }}>Read More</Button>
                    </div>
                </>

            ))
            :
            <div className="info-box">

                <div className="description text-white text-opacity-75">
                    No Announcement posted yet!
                </div>
            </div>
        }
    </Slider>
</div>
{/* ))
        :
        <div className="info-box">
            <div className="heading-label text-white fs-5 fw-semibold lh-sm mb-3"><i class="bi bi-megaphone-fill me-2"></i> Announcement</div>
            <div className="description text-white text-opacity-75">
                No Announcement posted yet!
            </div>
        </div>

    } */}


</div>
                        </div>
                        <div className="col-lg-8 col-xl-7">
                           
                            <div className="earning-section info-card h-100 rounded-3 p-3 p-xxl-4" style={{ backgroundColor: 'var(--wallet-color1)' }}>
                                <div className="d-flex flex-column flex-xl-row justify-content-between gap-2 mb-4 pb-xl-2">
                                    <div className="title-wrapper">
                                        <div className="heading-label text-white fs-3 fw-semibold lh-sm mb-2">
                                            <i class="bi bi-wallet-fill me-2"></i> Earning
                                        </div>
                                        {/* {dashboardData && dashboardData?.disputedCases? */}
                                        <div className="total-dispute text-red fs-14 fw-medium pointer" onClick={()=>{handleListDisputeFilter("disputed")}}><i className="bi bi-info-circle-fill"></i> {dashboardData && dashboardData?.disputedCases && dashboardData?.disputedCases?.length} Dispute Cases <span><i className="bi bi-arrow-right text-white"></i></span></div>
                                        {/* :null} */}

{/* {dashboardData && dashboardData?.redeemRequests? */}
                                        <div className="total-dispute text-green fs-14 fw-medium pointer" onClick={()=>{handleListDisputeFilter("redeem-pending")}}><i className="bi bi-info-circle-fill"></i> {dashboardData && dashboardData?.redeemRequests} Redeem Requests <span><i className="bi bi-arrow-right text-white"></i></span></div>
                                        {/* :null} */}
                                    </div>
                                    <div className="card-cover d-flex flex-wrap flex-xl-nowrap justify-content-xl-end flex-fill gap-3">
                                        <div className="info-box w-100 text-end rounded-3 px-3 py-2" style={{ backgroundColor: '#4834d3', maxWidth: '240px' }}>
                                            <div className="heading-label text-white fs-4 fw-semibold mt-1">₹0</div>
                                            <div className="description text-white text-opacity-75 fs-14">Amount Transferred</div>
                                        </div>
                                        <div className="info-box w-100 text-end rounded-3 px-3 py-2" style={{ backgroundColor: '#159b5c', maxWidth: '240px' }}>
                                            <div className="heading-label text-white fs-4 fw-semibold mt-1">₹{dashboardData?.walletInfo?.available_to_redeem + dashboardData?.walletInfo?.sdm_available_to_redeem + dashboardData?.walletInfo?.sbdm_available_to_redeem || 0}</div>
                                            <div className="description text-white text-opacity-75 fs-14">Available balance to redeem</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row g-3">
                                    {/* <div className="col-md-6 col-xl-4">
                                        <div className="user-incentive rounded-3 position-relative pt-3 p-2" style={{backgroundColor:'#424659'}}>
                                            <div className="d-flex flex-wrap align-items-center gap-2 gap-xxl-3 mt-1">
                                                <div className="icon text-white fs-3 d-flex align-items-center justify-content-center rounded-circle p-1" style={{ backgroundColor: '#6c5be8' }}>
                                                    <i class="bi bi-person-video3"></i>
                                                </div>
                                                <div className="info-cover d-flex align-items-center gap-2">
                                                    <div className="info-cover">
                                                        <div className="user-title fs-12 text-white text-opacity-50">Expected</div>
                                                        <div className="heading-label text-white fs-18 fw-semibold lh-sm">₹{dashboardData?.Balances?.totalExpectedBalance ? dashboardData?.Balances?.totalExpectedBalance - dashboardData?.Balances?.totalAvailableBalance : 0}</div>
                                                    </div>
                                                    <div className="info-cover border-secondary border-start ps-2">
                                                        <div className="user-title fs-12 text-white text-opacity-50">Approval Waiting</div>
                                                        <div className="heading-label text-white fs-18 fw-semibold lh-sm">₹{dashboardData?.Balances?.totalAvailableBalance ? dashboardData?.Balances?.totalAvailableBalance : 0}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="user-role-label fs-12 text-white fw-semibold rounded-1 position-absolute top-0 start-0 lh-sm ms-2" style={{ backgroundColor: '#455472',padding: '2px 10px',marginTop: '-8px' }}>BDM</div>
                                            <div className="text-white text-opacity-75 position-absolute top-0 end-0 mt-1 px-2" style={{ marginTop: '-8px' }}>
                                                <i class="bi bi-info-circle-fill" title="Loirem ipsum is simply dummy text"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-xl-4">
                                        <div className="user-incentive rounded-3 position-relative pt-3 p-2" style={{backgroundColor:'#424659'}}>
                                            <div className="d-flex flex-wrap align-items-center gap-2 gap-xxl-3 mt-1">
                                                <div className="icon text-white fs-3 d-flex align-items-center justify-content-center rounded-circle p-1" style={{ backgroundColor: '#9b59b6' }}>
                                                    <i class="bi bi-person-workspace"></i>
                                                </div>
                                                <div className="info-cover d-flex align-items-center gap-2">
                                                    <div className="info-cover">
                                                        <div className="user-title fs-12 text-white text-opacity-50">Expected</div>
                                                        <div className="heading-label text-white fs-18 fw-semibold lh-sm">₹{dashboardData?.Balances?.totalSDMExpected ? dashboardData?.Balances?.totalSDMExpected - dashboardData?.Balances?.totalSDMAvailableBalance : 0}</div>
                                                    </div>
                                                    <div className="info-cover border-secondary border-start ps-2">
                                                        <div className="user-title fs-12 text-white text-opacity-50">Approval Waiting</div>
                                                        <div className="heading-label text-white fs-18 fw-semibold lh-sm">₹{dashboardData?.Balances?.totalSDMAvailableBalance ? dashboardData?.Balances?.totalSDMAvailableBalance : 0}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="user-role-label fs-12 text-white fw-semibold rounded-1 position-absolute top-0 start-0 lh-sm ms-2" style={{ backgroundColor: '#455472',padding: '2px 10px',marginTop: '-8px' }}>SDM</div>
                                            <div className="text-white text-opacity-75 position-absolute top-0 end-0 mt-1 px-2" style={{ marginTop: '-8px' }}>
                                                <i class="bi bi-info-circle-fill" title="Loirem ipsum is simply dummy text"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-xl-4">
                                        <div className="user-incentive rounded-3 position-relative pt-3 p-2" style={{backgroundColor:'#424659'}}>
                                            <div className="d-flex flex-wrap align-items-center gap-2 gap-xxl-3 mt-1">
                                                <div className="icon text-white fs-3 d-flex align-items-center justify-content-center rounded-circle p-1" style={{ backgroundColor: '#2993d6' }}>
                                                    <i class="bi bi-person-workspace"></i>
                                                </div>
                                                <div className="info-cover d-flex align-items-center gap-2">
                                                    <div className="info-cover">
                                                        <div className="user-title fs-12 text-white text-opacity-50">Expected</div>
                                                        <div className="heading-label text-white fs-18 fw-semibold lh-sm">₹0</div>
                                                    </div>
                                                    <div className="info-cover border-secondary border-start ps-2">
                                                        <div className="user-title fs-12 text-white text-opacity-50">Approval Waiting</div>
                                                        <div className="heading-label text-white fs-18 fw-semibold lh-sm">₹0</div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="user-role-label fs-12 text-white fw-semibold rounded-1 position-absolute top-0 start-0 lh-sm ms-2" style={{ backgroundColor: '#455472',padding: '2px 10px',marginTop: '-8px' }}>Supported BDM</div>
                                            <div className="text-white text-opacity-75 position-absolute top-0 end-0 mt-1 px-2" style={{ marginTop: '-8px' }}>
                                                <i class="bi bi-info-circle-fill" title="Loirem ipsum is simply dummy text"></i>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="col-md-6 col-xl-4 pointer" onClick={naviateToWalletBDM}>
                                        <div className="user-incentive rounded-3 position-relative p-2" style={{ backgroundColor: '#424659' }}>
                                        <div className="text-white text-opacity-75 text-end lh-1 mb-xl-1 mb-xxl-0">
                                                <OverlayTrigger trigger="click" placement={'bottom'}
                                                    overlay={
                                                        <Popover>
                                                            <Popover.Body className="fs-12">
                                                                <div className="mb-3"><strong>BDM:</strong> You become the BDM once you successfully convert opportunities.</div>
                                                                <ul className="list w-100 d-flex flex-column gap-3 ms-1 mb-0 ps-2">
                                                                    <li><strong>Expected:</strong> This is the estimated amount from opportunities added to your wallet. These opportunities are not yet WON or have payments pending.</li>
                                                                    <li><strong>Approval Waiting:</strong> Payments for WON opportunities are awaiting management approval. Once approved, you'll see the "Redeem" button to claim your incentives.</li>
                                                                </ul>
                                                            </Popover.Body>
                                                        </Popover>
                                                    }
                                                >
                                                    <i className="bi bi-info-circle-fill pointer"></i>
                                                </OverlayTrigger>
                                            </div>
                                            <div className="d-flex flex-wrap flex-xl-nowrap align-items-center gap-2">
                                                <div className="icon text-white fs-18 d-flex align-items-center justify-content-center rounded-circle p-1" style={{ backgroundColor: '#6c5be8' }}>
                                                    <i class="bi bi-person-video3"></i>
                                                </div>
                                                <div className="info-cover d-flex align-items-center gap-2">
                                                    <div className="info-cover">
                                                        <div className="user-title text-white text-opacity-50">Expected</div>
                                                        <div className="heading-label text-white fs-18 fw-semibold lh-sm">₹{dashboardData?.Balances?.totalExpectedBalance ? dashboardData?.Balances?.totalExpectedBalance - dashboardData?.Balances?.totalAvailableBalance : 0}</div>
                                                    </div>
                                                    <div className="info-cover border-secondary border-start ps-2">
                                                        <div className="user-title text-white text-opacity-50 text-truncate">Approval Waiting</div>
                                                        <div className="heading-label text-white fs-18 fw-semibold lh-sm">₹{dashboardData?.Balances?.totalAvailableBalance ? dashboardData?.Balances?.totalAvailableBalance - dashboardData?.bdm?.totalBDMApprovedAmount : 0}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="user-role-label fs-12 text-white fw-semibold rounded-1 position-absolute top-0 start-0 lh-sm ms-2" style={{ backgroundColor: '#455472', padding: '2px 10px', marginTop: '-8px' }}>BDM</div>
                                            {/* <div className="text-white text-opacity-75 position-absolute top-0 end-0 mt-1 px-2" style={{ marginTop: '-8px' }}>
                                                <i class="bi bi-info-circle-fill" title="Loirem ipsum is simply dummy text"></i>
                                            </div> */}
                                           
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-xl-4 pointer" onClick={naviateToWalletSDM}>
                                        <div className="user-incentive rounded-3 position-relative p-2" style={{ backgroundColor: '#424659' }}>
                                        <div className="text-white text-opacity-75 text-end lh-1 mb-xl-1 mb-xxl-0">
                                                <OverlayTrigger trigger="click" placement={'bottom'}
                                                    overlay={
                                                        <Popover>
                                                            <Popover.Body className="fs-12">
                                                                <div className="mb-3"><strong>SDM:</strong> You are designated as the SDM for these billings, as assigned by your Billing Manager in the system.</div>
                                                                <ul className="list w-100 d-flex flex-column gap-3 ms-1 mb-0 ps-2">
                                                                    <li><strong>Expected:</strong> This is the estimated amount from opportunities added to your wallet. These opportunities are not yet WON or have payments pending.</li>
                                                                    <li><strong>Approval Waiting:</strong> Payments for WON opportunities are awaiting management approval. Once approved, you'll see the "Redeem" button to claim your incentives.</li>
                                                                </ul>
                                                            </Popover.Body>
                                                        </Popover>
                                                    }
                                                >
                                                    <i className="bi bi-info-circle-fill pointer"></i>
                                                </OverlayTrigger>
                                            </div>
                                            <div className="d-flex flex-wrap flex-xl-nowrap align-items-center gap-2">
                                                <div className="icon text-white fs-18 d-flex align-items-center justify-content-center rounded-circle p-1" style={{ backgroundColor: '#9b59b6' }}>
                                                    <i class="bi bi-person-workspace"></i>
                                                </div>
                                                <div className="info-cover d-flex align-items-center gap-2">
                                                    <div className="info-cover">
                                                        <div className="user-title text-white text-opacity-50">Expected</div>
                                                        <div className="heading-label text-white fs-18 fw-semibold lh-sm">₹{dashboardData?.Balances?.totalSDMExpected ? dashboardData?.Balances?.totalSDMExpected - dashboardData?.Balances?.totalSDMAvailableBalance : 0}</div>
                                                    </div>
                                                    <div className="info-cover border-secondary border-start ps-2">
                                                        <div className="user-title text-white text-opacity-50">Approval Waiting</div>
                                                        <div className="heading-label text-white fs-18 fw-semibold lh-sm">₹{dashboardData?.Balances?.totalSDMAvailableBalance ? dashboardData?.Balances?.totalSDMAvailableBalance : 0}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="user-role-label fs-12 text-white fw-semibold rounded-1 position-absolute top-0 start-0 lh-sm ms-2" style={{ backgroundColor: '#455472', padding: '2px 10px', marginTop: '-8px' }}>SDM</div>
                                            {/* <div className="text-white text-opacity-75 position-absolute top-0 end-0 mt-1 px-2" style={{ marginTop: '-8px' }}>
                                                <i class="bi bi-info-circle-fill" title="Loirem ipsum is simply dummy text"></i>
                                            </div> */}
                                           
                                        </div>
                                    </div>
                                    <div className="col-12 col-xl-4 pointer" onClick={naviateToWalletSBDM}>
                                        <div className="user-incentive rounded-3 position-relative p-2" style={{ backgroundColor: '#424659' }}>
                                        <div className="text-white text-opacity-75 text-end lh-1 mb-xl-1 mb-xxl-0">
                                                <OverlayTrigger trigger="click" placement={'bottom'}
                                                    overlay={
                                                        <Popover>
                                                            <Popover.Body className="fs-12">
                                                                <div className="mb-3"><strong>Supported BDM:</strong> You are recognized as a Supported BDM when you contribute relevant input during the conversion process alongside the BDM.</div>
                                                                <ul className="list w-100 d-flex flex-column gap-3 ms-1 mb-0 ps-2">
                                                                    <li>The BDM can share a percentage of the incentive with you during the redeem process.</li>
                                                                    <li>If you believe you should be listed as a Supported BDM for specific opportunities, you can <strong>raise a dispute</strong> to clarify with management and claim your share of incentives.</li>
                                                                    <li><strong>Expected:</strong> This is the estimated amount from opportunities added to your wallet. These opportunities are not yet WON or have payments pending.</li>
                                                                    <li><strong>Approval Waiting:</strong> Payments for WON opportunities are awaiting management approval. Once approved, you'll see the <strong>"Redeem"</strong> button to claim your incentives.</li>
                                                                </ul>
                                                            </Popover.Body>
                                                        </Popover>
                                                    }
                                                >
                                                    <i className="bi bi-info-circle-fill pointer"></i>
                                                </OverlayTrigger>
                                            </div>
                                            <div className="d-flex flex-wrap flex-xl-nowrap align-items-center gap-2">
                                                <div className="icon text-white fs-18 d-flex align-items-center justify-content-center rounded-circle p-1" style={{ backgroundColor: '#2993d6' }}>
                                                    <i class="bi bi-person-workspace"></i>
                                                </div>
                                                <div className="info-cover d-flex align-items-center gap-2">
                                                    <div className="info-cover">
                                                        <div className="user-title text-white text-opacity-50">Expected</div>
                                                        <div className="heading-label text-white fs-18 fw-semibold lh-sm">₹0</div>
                                                    </div>
                                                    <div className="info-cover border-secondary border-start ps-2">
                                                        <div className="user-title text-white text-opacity-50">Approval Waiting</div>
                                                        <div className="heading-label text-white fs-18 fw-semibold lh-sm">₹0</div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="user-role-label fs-12 text-white fw-semibold rounded-1 position-absolute top-0 start-0 lh-sm ms-2" style={{ backgroundColor: '#455472', padding: '2px 10px', marginTop: '-8px' }}>Supported BDM</div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="info-cards-section mb-4">
                    <div className="row g-3 g-xxl-4">
                        <div className="col-md-6 col-xl-3">
                            <div className="info-card h-100 rounded-3 overflow-hidden p-3" style={{ backgroundImage: `url(${GreenBGBanner})` }}>
                                <div className="table-responsive">
                                    <table className="table text-white border-light border-opacity-10 m-0">
                                        <thead>
                                            <tr>
                                                <th className="fw-semibold">New Client</th>
                                                <th className="fs-14 fw-light text-white text-opacity-75 text-capitalize">Full Time</th>
                                                <th className="fs-14 fw-light text-white text-opacity-75 text-capitalize">Part Time</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="fs-14 fw-light text-white text-opacity-75 text-capitalize">BDM</td>
                                                <td className="fs-6 fw-semibold">₹{dashboardData?.Balances?.totalNewHireBalance ? dashboardData?.Balances?.totalNewHireBalance : 0}</td>
                                                <td className="fs-6 fw-semibold">₹{dashboardData?.Balances?.totalNewPartBalance ? dashboardData?.Balances?.totalNewPartBalance : 0}</td>
                                            </tr>
                                            <tr>
                                                <td className="fs-14 fw-light text-white text-opacity-75 text-capitalize">SDM</td>
                                                <td className="fs-6 fw-semibold">₹{dashboardData?.Balances?.totalNewHireBalanceS ? dashboardData?.Balances?.totalNewHireBalanceS : 0}</td>
                                                <td className="fs-6 fw-semibold">₹{dashboardData?.Balances?.totalNewPartBalanceS ? dashboardData?.Balances?.totalNewPartBalanceS : 0}</td>
                                            </tr>
                                            <tr>
                                                <td className="fs-14 fw-light text-white text-opacity-75 text-capitalize">Supported BDM</td>
                                                <td className="fs-6 fw-semibold">₹0</td>
                                                <td className="fs-6 fw-semibold">₹0</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-xl-3">
                            <div className="info-card h-100 rounded-3 overflow-hidden p-3" style={{ backgroundImage: `url(${BlueBGBanner})` }}>
                                <div className="table-responsive">
                                    <table className="table text-white border-light border-opacity-10 m-0">
                                        <thead>
                                            <tr>
                                                <th className="fw-semibold">Cross Selling</th>
                                                <th className="fs-14 fw-light text-white text-opacity-75 text-capitalize">Full Time</th>
                                                <th className="fs-14 fw-light text-white text-opacity-75 text-capitalize">Part Time</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="fs-14 fw-light text-white text-opacity-75 text-capitalize">BDM</td>
                                                <td className="fs-6 fw-semibold">₹{dashboardData?.Balances?.totalcrossFullBalance ? dashboardData?.Balances?.totalcrossFullBalance : 0}</td>
                                                <td className="fs-6 fw-semibold">₹{dashboardData?.Balances?.totalcrossPartBalance ? dashboardData?.Balances?.totalcrossPartBalance : 0}</td>
                                            </tr>
                                            <tr>
                                                <td className="fs-14 fw-light text-white text-opacity-75 text-capitalize">SDM</td>
                                                <td className="fs-6 fw-semibold">₹{dashboardData?.Balances?.totalcrossFullBalanceS ? dashboardData?.Balances?.totalcrossFullBalanceS : 0}</td>
                                                <td className="fs-6 fw-semibold">₹{dashboardData?.Balances?.totalcrossPartBalanceS ? dashboardData?.Balances?.totalcrossPartBalanceS : 0}</td>
                                            </tr>
                                            <tr>
                                                <td className="fs-14 fw-light text-white text-opacity-75 text-capitalize">Supported BDM</td>
                                                <td className="fs-6 fw-semibold">₹0</td>
                                                <td className="fs-6 fw-semibold">₹0</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-xl-3">
                            <div className="info-card h-100 rounded-3 overflow-hidden p-3" style={{ backgroundImage: `url(${PurpleBGBanner})` }}>
                                <div className="table-responsive">
                                    <table className="table text-white border-light border-opacity-10 m-0">
                                        <thead>
                                            <tr>
                                                <th className="fw-semibold">Upscale</th>
                                                <th className="fs-14 fw-light text-white text-opacity-75 text-capitalize">Full Time</th>
                                                <th className="fs-14 fw-light text-white text-opacity-75 text-capitalize">Part Time</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="fs-14 fw-light text-white text-opacity-75 text-capitalize">BDM</td>
                                                <td className="fs-6 fw-semibold">₹{dashboardData?.Balances?.totalUpscaleFullBalance ? dashboardData?.Balances?.totalUpscaleFullBalance : 0}</td>
                                                <td className="fs-6 fw-semibold">₹{dashboardData?.Balances?.totalUpscalePartBalance ? dashboardData?.Balances?.totalUpscalePartBalance : 0}</td>
                                            </tr>
                                            <tr>
                                                <td className="fs-14 fw-light text-white text-opacity-75 text-capitalize">SDM</td>
                                                
                                                <td className="fs-6 fw-semibold">₹{dashboardData?.Balances?.totalUpscaleFullBalanceS ? dashboardData?.Balances?.totalUpscaleFullBalanceS : 0}</td>
                                                <td className="fs-6 fw-semibold">₹{dashboardData?.Balances?.totalUpscalePartBalanceS ? dashboardData?.Balances?.totalUpscalePartBalanceS : 0}</td>
                                            </tr>
                                            <tr>
                                                <td className="fs-14 fw-light text-white text-opacity-75 text-capitalize">Supported BDM</td>
                                                <td className="fs-6 fw-semibold">₹0</td>
                                                <td className="fs-6 fw-semibold">₹0</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-xl-3">
                            <div className="info-card h-100 rounded-3 overflow-hidden p-3" style={{ backgroundImage: `url(${OrangeBGBanner})` }}>
                                <div className="table-responsive">
                                    <table className="table text-white border-light border-opacity-10 m-0">
                                        <thead>
                                            <tr>
                                                <th className="fw-semibold">Client Refferal</th>
                                                <th className="fs-14 fw-light text-white text-opacity-75 text-capitalize">Full Time</th>
                                                <th className="fs-14 fw-light text-white text-opacity-75 text-capitalize">Part Time</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="fs-14 fw-light text-white text-opacity-75 text-capitalize">BDM</td>
                                                <td className="fs-6 fw-semibold">₹{dashboardData?.Balances?.totalrefFullBalance ? dashboardData?.Balances?.totalrefFullBalance : 0}</td>
                                                <td className="fs-6 fw-semibold">₹{dashboardData?.Balances?.totalrefPartBalance ? dashboardData?.Balances?.totalrefPartBalance : 0}</td>
                                            </tr>
                                            <tr>
                                                <td className="fs-14 fw-light text-white text-opacity-75 text-capitalize">SDM</td>
                                                
                                                <td className="fs-6 fw-semibold">₹{dashboardData?.Balances?.totalrefFullBalanceS ? dashboardData?.Balances?.totalrefFullBalanceS : 0}</td>
                                                <td className="fs-6 fw-semibold">₹{dashboardData?.Balances?.totalrefPartBalanceS ? dashboardData?.Balances?.totalrefPartBalanceS : 0}</td>
                                            </tr>
                                            <tr>
                                                <td className="fs-14 fw-light text-white text-opacity-75 text-capitalize">Supported BDM</td>
                                                <td className="fs-6 fw-semibold">₹0</td>
                                                <td className="fs-6 fw-semibold">₹0</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="overall-stats-section">
                    <div className="row g-3 g-xxl-4">
                        <div className="col-xl-5">
                            <div className="info-card rounded-3 h-100 p-3" style={{ backgroundColor: 'var(--wallet-color1)' }}>
                                <Slider {...settingsChart} className="custom-slider pb-3">
                                    <div className="chart-wrapper">
                                        <div className="heading-label text-white fs-5 fw-semibold mb-3">
                                            <i class="bi bi-pie-chart-fill me-2"></i> My Overall Stats
                                        </div>
                                        <div className="text-center mx-auto" style={{ maxWidth: '400px' }}>
                                            <OverAllStatsChart bdm={dashboardData?.Balances?.totalExpectedBalance ? dashboardData?.Balances?.totalExpectedBalance  : 0} sdm={dashboardData?.Balances?.totalSDMExpected ? dashboardData?.Balances?.totalSDMExpected : 0} sbdm={0} />
                                        </div>
                                    </div>
                                    <div className="chart-wrapper">
                                        <div className="heading-label text-white fs-5 fw-semibold mb-3">
                                            <i class="bi bi-pie-chart-fill me-2"></i> My BDM Stats
                                        </div>
                                        <div className="text-center mx-auto mt-5" style={{ maxWidth: '550px' }}>
                                            <MyBDMStatsChart bdmChartData={ dashboardData?.Balances?.BDMStats?dashboardData?.Balances?.BDMStats:[0,0,0,0,0,0,0,0]} />
                                        </div>
                                    </div>
                                    <div className="chart-wrapper">
                                        <div className="heading-label text-white fs-5 fw-semibold mb-3">
                                            <i class="bi bi-pie-chart-fill me-2"></i> My SDM Stats
                                        </div>
                                        <div className="text-center mx-auto mt-5" style={{ maxWidth: '550px' }}>
                                            <MySDMStatsChart sdmChartData={ dashboardData?.Balances?.SDMStats?dashboardData?.Balances?.SDMStats:[0,0,0,0,0,0,0,0]} />
                                        </div>
                                    </div>
                                    <div className="chart-wrapper">
                                        <div className="heading-label text-white fs-5 fw-semibold mb-3">
                                            <i class="bi bi-pie-chart-fill me-2"></i> My Supported BDM Stats
                                        </div>
                                        <div className="text-center mx-auto mt-5" style={{ maxWidth: '550px' }}>
                                            <MySupportedBDMStatsChart />
                                        </div>
                                    </div>
                                </Slider>
                            </div>
                        </div>
                        <div className="col-xl-7">
                            <div className="transactions-section info-card h-100 rounded-3 p-3" style={{ backgroundColor: 'var(--wallet-color1)' }}>
                                <div className="d-flex flex-wrap justify-content-between gap-2 mb-2">
                                    <div className="heading-label text-white fs-5 fw-semibold">
                                        <i class="bi bi-wallet-fill me-2"></i> Transactions
                                    </div>
                                      {dashboardData?.Balances?.opList && dashboardData?.Balances?.opList.length > 0 && dashboardData?.Balances?.opList && dashboardData?.Balances?.opList.every(item => item === null)?null
                                      : !dashboardData?.Balances?null
                                                                         
                                                                        :<Button variant="success" className="align-self-start px-3" onClick={naviateToWallet}>See More <i className="bi bi-arrow-right fs-18 ms-1"></i></Button>
                                                                        }
                                        
                                    {/* <Button variant="success" className="align-self-start px-3" onClick={naviateToWallet}>See More <i className="bi bi-arrow-right fs-18 ms-1"></i></Button> */}
                                </div>
                                <div className="transactions-wrapper">
                                    <div className="list-header rounded-3 px-3 py-2">
                                        <div className="row row-cols-sm-2 row-cols-md-3 row-cols-lg-5 gx-3 gy-md-1">
                                            <div className="col-xl-3 text-warning fw-normal">Opportunity ID</div>
                                            <div className="col-xl-3 text-warning fw-normal">Incentive Type</div>
                                            <div className="col-xl-2 text-warning fw-normal">Status</div>
                                            <div className="col-xl-2 text-warning fw-normal">Role</div>
                                            <div className="col-xl-2 text-warning fw-normal">Total Incentive</div>
                                        </div>
                                    </div>
                                    <div className="transactions-lists d-flex flex-column gap-2">
                                    {dashboardData?.Balances?.opList && dashboardData?.Balances?.opList.length > 0 && dashboardData?.Balances?.opList && dashboardData?.Balances?.opList.every(item => item === null)? <div className="text-center">No trasactions found!</div>
                                    : !dashboardData?.Balances?
                                    <div className="text-center">No trasactions found!</div>:null
                                    }

                                        {dashboardData?.Balances?.opList && dashboardData?.Balances?.opList.length > 0 &&
                                        dashboardData?.Balances?.opList.map((item) => {
                                            if(item){
                                                return(
                                                    <div className="list-item rounded-3 p-3">
                                                    <div className="row row-cols-sm-2 row-cols-md-3 row-cols-lg-5 gx-3 gy-md-1">
                                                        <div className="col-xl-3 opportunityID text-white text-opacity-75 text-capitalize text-break">{item.topic}</div>
                                                        <div className="col-xl-3 incentiveType text-white text-opacity-75 text-capitalize">{item?.incentiveType?.scenario + " " + item?.incentiveType?.client_type}</div>
                                                        {item.status === 3 ?
                                                            <div className="col-xl-2 status text-success text-capitalize text-semibold" >{statuscode[item.status]}</div>
                                                            : item.status === 4 ?
                                                                <div className="col-xl-2 status text-danger text-capitalize" >{statuscode[item.status]}</div>
                                                                :
                                                                <div className="col-xl-2 status text-info text-capitalize" >{statuscode[item.status]}</div>
                                                        }
    
                                                        <div className="col-xl-2 status text-white text-opacity-75 text-capitalize">{item?.incentiveType?.role}</div>
                                                        <div className="col-xl-2 status fw-semibold text-white text-capitalize">₹{item?.incentiveType?.total_bonus}</div>
                                                    </div>
                                                </div>
                                                )
                                            }
                                            
                                            
})}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
            {/* Read more Modal */}
            <ReadMoreModal show={showReadmoreModal} close={closeReadmoreModal} readMoreText={readMoreText} startReadTime={startReadTime} user={user}/>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
      setLoggedinUser: (user) => dispatch(setUserInfo(user))
    };
  };
  
  const mapStoreStateToProps = (state) => {
    return {
      ...state,
    };
  };
   
  export default connect(
    mapStoreStateToProps,
    mapDispatchToProps
  )(WalletDashboard);
