import React, { useEffect, useState } from "react";
import { Form } from 'react-bootstrap'
import SearchIcon from '../../resources/images/search.svg';
import moment from 'moment';

export const AccountHeader = ({ sendDataToParent }) => {
    const [getSearchvalue, setSearchvalue] = useState('')
    const [getClienttype, setClienttype] = useState('')
    

      const [requestedFrom, setRequestedFrom] = useState(() => {
                     const startDD = `${new Date().getFullYear()}-${String(new Date().getMonth() + 1).padStart(2, '0')}-01`;
                     return startDD;
                 });
                 const [requestedTo, setRequestedTo] = useState(() => {
                     const date = new Date();
                     const syear = date.getFullYear();
                     const smonth = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
                     const sday = String(date.getDate()).padStart(2, '0');
                     return `${syear}-${smonth}-${sday}`;
                 });

    const handleSelectChange = (event) => {
        const value = event.target.value;
        setClienttype(value);
        sendDataToParent({ getSearchvalue, getClienttype: value, requestedFrom, requestedTo });
    };

    const handleSearchChange = (event) => {
        const value = event.target.value;
        setSearchvalue(value);
        sendDataToParent({ getSearchvalue: value, getClienttype, requestedFrom, requestedTo });
    };

    const handleDateChange = (event) => {
        const { name, value } = event.target;
        if (name === 'requestedFrom') {
            setRequestedFrom(value);
        } else if (name === 'requestedTo') {
            setRequestedTo(value);
        }
        sendDataToParent({ getSearchvalue, getClienttype, requestedFrom: name === 'requestedFrom' ? value : requestedFrom, requestedTo: name === 'requestedTo' ? value : requestedTo });
    };

    return (
        <>
            <div className="date-wrapper d-flex flex-column flex-wrap flex-sm-row flex-xxl-nowrap align-items-sm-end justify-content-xxl-end flex-fill gap-2 gap-md-3 gap-xxl-4">
                <Form.Group className="search-wrapper" style={{ minWidth: '250px', maxWidth: '350px', width: '100%' }}>
                    {/* <Form.Label className="text-nowrap d-md-none m-0">&nbsp;</Form.Label> */}
                    <div className="searchfield-wrapper position-relative flex-fill" controlId="statusType">
                        <img src={SearchIcon} alt='Search Icon' className='position-absolute top-50 start-0 translate-middle-y ms-3' />
                        <Form.Control type="search" className="bg-transparent pe-3 py-2" placeholder="Search with Opportunity ID / Invoice ID" style={{ paddingLeft: '45px' }} onChange={handleSearchChange} value={getSearchvalue} />
                    </div>
                </Form.Group>
                <Form.Group className="client-type d-flex flex-column flex-xxl-row align-items-xxl-center gap-1 gap-xl-2 flex-fill" controlId="clientType">
                    <Form.Label className="text-nowrap m-0">Client Type</Form.Label>
                    <Form.Select className="py-2" style={{ minWidth: '250px', backgroundColor: 'var(--dark-theme-bg2)' }} value={getClienttype} onChange={handleSelectChange} required>
                        <option value="">Select Client Type</option>
                        <option value="NewFullTime">New Full Time</option>
                        <option value="UpscalingFullTime">Upscaling Full Time</option>
                        <option value="CrossSellingFullTime">Cross Selling Full Time</option>
                        <option value="ClientReferralFullTime">Client Referral Full Time</option>
                        <option value="NewPartTime">New Part Time</option>
                        <option value="UpscalingPartTime">Upscaling Part Time</option>
                        <option value="CrossSellingPartTime">Cross Selling Part Time</option>
                        <option value="ClientReferralPartTime">Client Referral Part Time</option>
                    </Form.Select>
                </Form.Group>
                <Form.Group className="d-flex flex-column flex-xxl-row align-items-xxl-center gap-1 gap-xl-2 flex-fill" controlId="requestedFrom">
                    <Form.Label className="text-nowrap m-0">Requested From</Form.Label>
                    <Form.Control type="date" className="bg-transparent py-2"
                       
                        defaultValue={`${new Date().getFullYear()}-${String(new Date().getMonth() + 1).padStart(2, '0')}-01`}
                        value={requestedFrom}
                        onChange={handleDateChange}
                        name="requestedFrom"
                    />
                </Form.Group>
                <Form.Group className="d-flex flex-column flex-xxl-row align-items-xxl-center gap-1 gap-xl-2 flex-fill" controlId="requestedTo">
                    <Form.Label className="text-nowrap m-0">Requested To</Form.Label>
                    <Form.Control type="date" className="bg-transparent py-2"
                        
                        defaultValue={new Date().toJSON().slice(0, 10)}
                        value={requestedTo}
                        onChange={handleDateChange}
                        name="requestedTo"  // Identify which date field is being changed
                    />
                </Form.Group>
            </div>
        </>
    )
}