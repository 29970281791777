import { Accordion, Button, Card, useAccordionButton } from "react-bootstrap"
import { convertReadable, convertReadableTime, log__ } from "../../utils/utils";
import { cr541_servicecategory, formatShiftTime, new_ClientType, new_EmploymentTenure, new_opportunitytype, new_servicetype, new_servicetype_mapping, nm__clienttype, nm_invoicefrom, nm_paymentstatus, nm_servicemonth, nm_servicestatus, statuscode } from "../../utils/codes";
import { useEffect, useState } from "react";
import { RedeemAmountModal } from "../RedeemAmountModal"
import { DeleteConfirmModal } from "../DeleteConfirmModal"

function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
        log__('totally custom!'),
    );

    return (
        <div className="custom-bg" onClick={decoratedOnClick}>
            {children}
        </div>
    );
}
export const WonCard = ({ key, index, opInfo, user, userRole, loadWallet, setIsLoading, opRealTime, setActiveKey, activeKey }) => {

    const [showRedeemAmountModal, setShowRedeemAmountModal] = useState(false);
    const closeRedeemAmountModal = () => setShowRedeemAmountModal(false);
    const openRedeemAmountModal = () => setShowRedeemAmountModal(true);

    const [redeemAmount, setRedeemAmount] = useState(0);
    const [whichMonthRedeem, setWhichMonth] = useState("");
    const [type, setType] = useState("");
    const [invoiceId, setInvoiceId] = useState("");

    const [invoiceHtml, setInvoiceHtml] = useState([]);

    const [isSharedYour1stInstallment, setisSharedYour1stInstallment] = useState({})
    const [installmentNo, setInstallmentNo] = useState(null)
    

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const closeDeleteModal = () => setShowDeleteModal(false);
    const openDeleteModal = () => setShowDeleteModal(true);
    // console.log(invoiceId)
    useEffect(() => {

        if (opInfo.serviceInfo.length > 0 && opInfo.groupedData && Object.entries(opInfo.groupedData).length > 0 && opInfo.cardType === "individual") {

            const promises = Object.entries(opInfo.groupedData).map((item, index) => {
                const [key, value] = item;
                if (Array.isArray(item[1]) &&
                    item[1][0]) {


                    const installmentIndex = item[1] ? item[1][0].installmentIndex : null;

                    const isProrated = item[1] ? item[1][0].isProrated : false;

                    const isClientTypeMismatched = item[1] ? item[1][0].is_clienttypes_mismatched : false

                    const isSharedYour1stInstallment = item[1][0].is_first_installment_shared;



                    // Make API call for each item
                    let incentiveAmount = item[1] ? item[1][0].amount : 0
                    let indexTitle;
                    if (installmentIndex === 1) {

                        indexTitle = "1st";
                    }
                    if (installmentIndex === 2) {

                        indexTitle = "2nd";
                    }
                    if (installmentIndex === 3) {

                        indexTitle = "3rd";
                    }
                    if (installmentIndex === 4) {

                        indexTitle = "4th";
                    }
                    if (isProrated) {
                        indexTitle = "Wrong ";
                    }


                    let installmentColor;
                    let headerColor;
                    if (item[1][0].nm_paymentstatus === 124950000 && !item[1][0].is_redeemption_approved_by_manager) {
                        installmentColor = "#453b2d";
                        headerColor = "#56452a";
                    } else if (item[1][0].nm_paymentstatus === 124950002) {
                        installmentColor = "rgb(111 111 111)";
                        headerColor = "rgb(73 73 73)";
                    } else {
                        installmentColor = "#2b3f37";
                        headerColor = "#274d3c";
                    }


                    if (key === "mismatched") {
                        installmentColor = "#7D2930";
                        headerColor = "#8A3F45";
                    }
                    if (key === "prorated") {
                        indexTitle = "Wrong ";
                        installmentColor = "#A55018";
                        headerColor = "#BD6B35";
                    }
                    if (!item[1][0].isIncentiveEnded) {
                        return (

                            <div className="col-md-6">
                                {/* 1st Installment Info */}
                                <div className="installment-info d-flex flex-column h-100 rounded-3 overflow-hidden" style={{ backgroundColor: installmentColor }}>
                                    <div className="installment-header fs-18 text-white px-3 py-2" style={{ backgroundColor: headerColor }}>
                                        {/* {indexTitle} Installment of Incentive */}
                                        {item[1][0].is_billing_start_before_oct_24 ?
                                            "Billing is before october 2024"
                                            : item[1][0].nm_paymentstatus === 124950002 ?

                                                "Awaiting payment from client"


                                                : item[1][0].isProrated ?
                                                    "Prorated Payment"
                                                    : isClientTypeMismatched ?
                                                        "Billing type mismatched"

                                                        : item[1][0].is_hour_less ?
                                                            "Total hour are less"


                                                            : indexTitle + " Installment of Incentive"

                                        }

                                    </div>
                                    <div className="incentive-body d-flex flex-column justify-content-between flex-fill p-3">
                                        <div className="value fs-12 text-white text-opacity-75 mb-3">
                                            The redeem button will be enabled once the client is won, payment is received,
                                            billing is assigned to the Billing Manager and SDM (Service Lead) on NM-Apps,
                                            and the resource has started work on the billing.
                                        </div>
                                        <div className="row gx-2 gy-3">
                                            <div className="col-sm-6 info-box text-capitalize">
                                                <div className="label fs-14 text-warning">Billing Manager</div>
                                                <div className="value text-white text-opacity-75 text-capitalize">{item[1][0]?.manager?.length > 0 ? item[1][0]?.manager : "-"}</div>
                                            </div>
                                            <div className="col-sm-6 info-box text-capitalize">
                                                <div className="label fs-14 text-warning">Service Delivery Manager <sup>2</sup></div>
                                                <div className="value text-white text-opacity-75">{item[1][0]?.lead?.length > 0 ? item[1][0]?.lead : "-"}</div>
                                            </div>
                                            <div className="col-sm-6 info-box text-capitalize">
                                                <div className="label fs-14 text-warning">Client ID</div>
                                                <div className="value text-white text-opacity-75">{item[1][0].nm_zohoclientid}</div>
                                            </div>
                                            <div className="col-sm-6 info-box text-capitalize">
                                                <div className="label fs-14 text-warning">Client Type</div>
                                                <div className="value text-white text-opacity-75">
                                                    {item[1][0]?.clientType_ && item[1][0]?.clientType_.length > 0 ? item[1][0].clientType_.map((cl) => (
                                                        <p className="mb-0">{nm__clienttype[cl]}</p>
                                                    )) : nm__clienttype[item[1][0]?.nm_clienttype]}
                                                </div>
                                            </div>
                                            <div className="col-sm-6 info-box text-capitalize">
                                                <div className="label fs-14 text-warning">Hour based on Receive Payment</div>
                                                <div className="value text-white text-opacity-75">{item[1][0].totalHours ? item[1][0].totalHours : '-'}</div>
                                            </div>
                                            <div className="col-sm-6 info-box text-capitalize">
                                                <div className="label fs-14 text-warning">Service Month</div>
                                                <div className="value text-white text-opacity-75">{nm_servicemonth[item[1][0].nm_servicemonth]}</div>
                                            </div>
                                            <div className="col-sm-6 info-box text-capitalize">
                                                <div className="label fs-14 text-warning">Front Face on Billing <sup>1</sup></div>
                                                <div className="value text-white text-opacity-75">{item[1][0]?.frontFace?.length > 0 ? item[1][0].frontFace : "-"}</div>
                                            </div>
                                            <div className="col-sm-6 info-box text-capitalize">
                                                <div className="label fs-14 text-warning">Service Type</div>
                                                <div className="value text-white text-opacity-75">{new_servicetype_mapping[item[1][0].nm_servicetype]}</div>
                                            </div>
                                            <div className="col-sm-6 info-box text-capitalize">
                                                <div className="label fs-14 text-warning">Invoice From</div>
                                                <div className="value text-white text-opacity-75">{nm_invoicefrom[item[1][0].nm_invoicefrom]}</div>
                                            </div>
                                            <div className="col-sm-6 info-box text-capitalize">
                                                <div className="label fs-14 text-warning">Invoice ID</div>
                                                <div className="value text-white text-opacity-75">{item[1][0].nm_zohoinvoiceid}</div>
                                            </div>
                                            <div className="col-sm-6 info-box text-capitalize">
                                                <div className="label fs-14 text-warning">Service Status</div>
                                                <div className="value" style={{ color: '#03ff85' }}>{nm_servicestatus[item[1][0].nm_servicestatus]}</div>
                                            </div>
                                            <div className="col-sm-6 info-box text-capitalize">
                                                <div className="label fs-14 text-warning">Payment Receive Date</div>
                                                <div className="value text-white text-opacity-75">{item[1][0].nm_paymentreceiveddate ? convertReadable(item[1][0].nm_paymentreceiveddate) : "-"}</div>
                                            </div>
                                            <div className="col-sm-6 info-box text-capitalize">
                                                <div className="label fs-14 text-warning">Payment Status</div>
                                                <div className="value text-white text-opacity-75">{nm_paymentstatus[item[1][0].nm_paymentstatus]}</div>
                                            </div>
                                        </div>
                                        {item[1][0].is_account_credited?
                                        <>
                                        <hr className="divider border border-top-2 border-secondary border-opacity-75 my-3" />
                                    <div className="row gx-2 gy-3">
                                        <div className="col-sm-6 info-box text-capitalize">
                                            <div className="label fs-14 text-warning">Your Incentive</div>
                                            <div className="value text-white text-opacity-75">₹{item[1][0].redeem_amount}</div>
                                        </div>
                                        <div className="col-sm-6 info-box text-capitalize">
                                            <div className="label fs-14 text-warning">Credit Transection Details</div>
                                            <div className="value text-break text-white text-opacity-75 d-flex flex-wrap align-items-center">{item[1][0].send_money_transaction_id} <i className="bi bi-dot text-white fs-3 lh-1"></i> {convertReadable(item[1][0].send_money_transaction_time)} {convertReadableTime(item[1][0].send_money_transaction_time)}</div>
                                        </div>
                                        {item[1][0].is_share_your_incentive && item[1][0].shared_account_credited?
                                        <>
                                            <div className="col-sm-6 info-box text-capitalize">
                                            <div className="label fs-14 text-warning">Supported BDM Incentive</div>
                                            <div className="value text-white text-opacity-75">₹{item[1][0].shared_redeem_amount}</div>
                                        </div>
                                        <div className="col-sm-6 info-box text-capitalize">
                                            <div className="label fs-14 text-warning">Credit Transection Details</div>
                                            <div className="value text-break text-white text-opacity-75 d-flex flex-wrap align-items-center">{item[1][0].shared_credit_txn_id} <i className="bi bi-dot text-white fs-3 lh-1"></i> {convertReadable(item[1][0].shared_credit_date)} {convertReadableTime(item[1][0].shared_credit_date)}</div>
                                        </div>
                                        </>
                                        :null
                                        }
                                        
                                    </div>
                                    <div className="btn-wrapper text-center mt-4 mb-2">
                                        <Button variant="outline-success" className="bg-transparent text-center rounded-1 px-4 py-1"  style={{ minWidth: '240px', color: '#03ff84', borderColor: '#03ff84' }}>
                                            {item[1][0].is_share_your_incentive && item[1][0].shared_account_credited?
                                            <>
                                            <div className="fs-5 fw-semibold lh-sm">Credited ₹{item[1][0].redeem_amount + item[1][0].shared_redeem_amount}</div>
                                            <div className="fs-12 opacity-75">On {convertReadableTime(item[1][0].shared_credit_date)}</div>
                                            </>

                                            : item[1][0].is_share_your_incentive && !item[1][0].shared_account_credited?

                                            <>
                                            <div className="fs-5 fw-semibold lh-sm">Credited ₹{item[1][0].redeem_amount}</div>
                                            <div className="fs-12 opacity-75">On {convertReadable(item[1][0].send_money_transaction_time)} {convertReadableTime(item[1][0].send_money_transaction_time)}</div>
                                            </>
                                            :
                                            <>
                                            <div className="fs-5 fw-semibold lh-sm">Credited ₹{item[1][0].redeem_amount}</div>
                                            <div className="fs-12 opacity-75">On {convertReadable(item[1][0].send_money_transaction_time)} {convertReadableTime(item[1][0].send_money_transaction_time)}</div>
                                            </>
                                            }
                                        </Button>
                                    </div>
                                        </>
                                        :
                                        <div className="btn-wrapper text-center mt-4 mb-2">

                                            {item[1][0].is_billing_start_before_oct_24 ?
                                                <Button className="bg-transparent border border-danger text-center rounded-1 px-4 py-1" style={{ minWidth: '240px' }}>
                                                    <span className="d-block fs-5 fw-semibold text-white">Not eligible for redeem</span>
                                                    <span className="d-block fs-12 text-white text-opacity-75">Incentive policy effected from October 2024</span>
                                                </Button>
                                                : item[1][0].nm_paymentstatus === 124950002 ?


                                                    <Button className="bg-transparent border border-white text-center rounded-1 px-4 py-1" style={{ minWidth: '240px' }}>
                                                        <span className="d-block fs-5 fw-semibold text-white"></span>
                                                        <span className="d-block fs-12 text-white text-opacity-75">Awaiting payment from the client.</span>
                                                    </Button>

                                                    : item[1][0].isProrated ?
                                                        <Button className="bg-transparent border border-danger text-center rounded-1 px-4 py-1" style={{ minWidth: '240px' }}>
                                                            {/* <span className="d-block fs-5 fw-semibold text-white">Not eligible for redeem</span> */}
                                                            <span className="d-block fs-12 text-white text-opacity-75">Prorated payments are excluded from incentives as they represent partial payments made by the client to align with the regular monthly billing cycle.</span>
                                                        </Button>
                                                        : isClientTypeMismatched ?
                                                            <Button className="bg-transparent border border-danger text-center rounded-1 px-4 py-1" style={{ minWidth: '240px' }}>
                                                                <span className="d-block fs-5 fw-semibold text-white">Not eligible for redeem</span>
                                                                <span className="d-block fs-12 text-white text-opacity-75">Billing Type Mismatched</span>
                                                            </Button>

                                                            : item[1][0].is_hour_less ?
                                                                <Button className="bg-transparent border border-danger text-center rounded-1 px-4 py-1" style={{ minWidth: '240px' }}>
                                                                    <span className="d-block fs-5 fw-semibold text-white">Not eligible for redeem</span>
                                                                    <span className="d-block fs-12 text-white text-opacity-75">total hours are less then incentive policy</span>
                                                                </Button>

                                                                : item[1][0].nm_paymentstatus === 124950000 && !item[1][0].is_redeemption_approved_by_manager ?
                                                                    <Button className="bg-transparent border border-warning text-center rounded-1 px-4 py-1" style={{ minWidth: '240px' }}>
                                                                        <span className="d-block fs-5 fw-semibold text-white">Redeem ₹{incentiveAmount}</span>
                                                                        <span className="d-block fs-12 text-white text-opacity-75">Awaiting management approval.</span>
                                                                    </Button>


                                                                    : item[1][0].nm_paymentstatus === 124950000 && item[1][0].is_redeemption_approved_by_manager && item[1][0].is_installment_approved === 1 && item[1][0].is_requested_for_accounts ?
                                                                        <Button variant="info" className="text-center rounded-1 px-4 py-1" style={{ minWidth: '240px' }}>
                                                                            <span className="d-block fs-5 fw-semibold text-white">Redeemed ₹{incentiveAmount}</span>
                                                                            <span className="d-block fs-12 text-white text-opacity-75">Awaiting credit from Accounts</span>
                                                                        </Button>
                                                                        : item[1][0].nm_paymentstatus === 124950000 && item[1][0].is_redeemption_approved_by_manager && item[1][0].is_installment_approved === 1 && !item[1][0].is_requested_for_accounts ?


                                                                            <Button variant="success" className="text-center rounded-1 px-4 py-1" style={{ minWidth: '240px' }} onClick={() => { openRedeemAmountModal(); setRedeemAmount(incentiveAmount); setWhichMonth(installmentIndex); setType("bdm"); setInvoiceId(item[1][0].nm_zohoinvoiceid); setisSharedYour1stInstallment(isSharedYour1stInstallment); setInstallmentNo(installmentIndex) }}>
                                                                                <span className="d-block fs-5 fw-semibold text-white">Redeem ₹{incentiveAmount}</span>
                                                                                <span className="fs-12 text-white text-opacity-75">You can redeem now</span>
                                                                            </Button>
                                                                            : null

                                            }
                                        </div>
                                        }
                                    </div>
                                </div>
                            </div>




                        );
                    }

                }
            });

            // Once all promises are resolved, set the invoiceHtml
            Promise.all(promises).then(setInvoiceHtml);
        }

        if (opInfo.serviceInfo.length > 0 && opInfo.groupedData && Object.entries(opInfo.groupedData).length > 0 && opInfo.cardType === "sdm") {

            const promises = Object.entries(opInfo.groupedData).map((item, index) => {
                const [key, value] = item
                let installmentColor;
                let headerColor;
                if (Array.isArray(item[1]) &&
                    item[1][0]) {


                    const installmentIndex = item[1] ? item[1][0].installmentIndex : null;

                    const isProrated = item[1] ? item[1][0].isProrated : false;

                    const isClientTypeMismatched = item[1] ? item[1][0].is_clienttypes_mismatched : false




                    // Make API call for each item
                    let incentiveAmount = item[1] ? item[1][0].amount : 0
                    let indexTitle;
                    if (installmentIndex === 1) {

                        indexTitle = "1st";
                    }
                    if (installmentIndex === 2) {

                        indexTitle = "2nd";
                    }
                    if (installmentIndex === 3) {

                        indexTitle = "3rd";
                    }
                    if (installmentIndex === 4) {

                        indexTitle = "4th";
                    }




                    if (item[1][0].nm_paymentstatus === 124950000 && !item[1][0].is_redeemption_approved_by_manager) {
                        installmentColor = "#453b2d";
                        headerColor = "#56452a";
                    } else if (item[1][0].nm_paymentstatus === 124950002) {
                        installmentColor = "rgb(111 111 111)";
                        headerColor = "rgb(73 73 73)";
                    } else {
                        installmentColor = "#2b3f37";
                        headerColor = "#274d3c";
                    }

                    //   if(isClientTypeMismatched){
                    //     indexTitle = "Wrong ";
                    //     installmentColor = "#7D2930";
                    //     headerColor = "#8A3F45";
                    // }

                    //   if(item[1][0].isSdmMismatched){
                    //     installmentColor = "#7D2930";
                    //     headerColor = "#8A3F45";
                    // }

                    //     if (isProrated) {
                    //         indexTitle = "Wrong ";
                    //         installmentColor = "#A55018";
                    //         headerColor = "#BD6B35";
                    //     }
                    if (key === "mismatched") {
                        installmentColor = "#7D2930";
                        headerColor = "#8A3F45";
                    }
                    if (item[1][0].isSdmMismatched) {
                        installmentColor = "#7D2930";
                        headerColor = "#8A3F45";
                    }
                    if (key === "prorated") {
                        indexTitle = "Wrong ";
                        installmentColor = "#A55018";
                        headerColor = "#BD6B35";
                    }
                    if (!item[1][0].isIncentiveEnded) {
                        return (

                            <div className="col-md-6">
                                {/* 1st Installment Info */}
                                <div className="installment-info d-flex flex-column h-100 rounded-3 overflow-hidden" style={{ backgroundColor: installmentColor }}>
                                    <div className="installment-header fs-18 text-white px-3 py-2" style={{ backgroundColor: headerColor }}>
                                        {/* {indexTitle} Installment of Incentive */}
                                        {item[1][0].is_billing_start_before_oct_24 ?
                                            "Billing is before october 2024"
                                            : item[1][0].nm_paymentstatus === 124950002 ?

                                                "Awaiting payment from client"


                                                : item[1][0].isProrated ?
                                                    "Prorated Payment"
                                                    : isClientTypeMismatched ?
                                                        "Billing type mismatched"

                                                        : item[1][0].is_hour_less ?
                                                            "Total hour are less"

                                                            : item[1][0].isSdmMismatched ?
                                                                "Non-SDM Eligibility"


                                                                : indexTitle + " Installment of Incentive"

                                        }

                                    </div>
                                    <div className="incentive-body d-flex flex-column justify-content-between flex-fill p-3">
                                        <div className="value text-white text-opacity-75 mb-2" style={{ fontSize: "11px" }}>
                                            <p>The Redeem Button will be enabled once the client is marked as "Won," payment is received, billing details are assigned to the Billing Manager and SDM (Service Lead) on NM-Apps, and the resource has commenced work on the billing. Resources must also begin updating their working hours on NM-Apps via the Resource App.</p>

                                            <p>SDM incentives will start with the client's billing month payment and include the payment for the following month. For instance, the Redeem Button for the first billing month's incentive will be activated upon receiving the payment for the second month.</p>
                                        </div>
                                        <div className="row gx-2 gy-3">
                                            <div className="col-sm-6 info-box text-capitalize">
                                                <div className="label fs-14 text-warning">Billing Manager</div>
                                                <div className="value text-white text-opacity-75 text-capitalize">{item[1][0]?.manager?.length > 0 ? item[1][0]?.manager : "-"}</div>
                                            </div>
                                            <div className="col-sm-6 info-box text-capitalize">
                                                <div className="label fs-14 text-warning">Service Delivery Manager <sup>2</sup></div>
                                                <div className="value text-white text-opacity-75">{item[1][0]?.lead?.length > 0 ? item[1][0]?.lead : "-"}</div>
                                            </div>
                                            <div className="col-sm-6 info-box text-capitalize">
                                                <div className="label fs-14 text-warning">Client ID</div>
                                                <div className="value text-white text-opacity-75">{item[1][0].nm_zohoclientid}</div>
                                            </div>
                                            <div className="col-sm-6 info-box text-capitalize">
                                                <div className="label fs-14 text-warning">Client Type</div>
                                                <div className="value text-white text-opacity-75">
                                                    {item[1][0]?.clientType_ && item[1][0]?.clientType_.length > 0 ? item[1][0].clientType_.map((cl) => (
                                                        <p className="mb-0">{nm__clienttype[cl]}</p>
                                                    )) : nm__clienttype[item[1][0]?.nm_clienttype]}     </div>
                                            </div>
                                            <div className="col-sm-6 info-box text-capitalize">
                                                <div className="label fs-14 text-warning">Hour based on Receive Payment</div>
                                                <div className="value text-white text-opacity-75">{item[1][0].totalHours ? item[1][0].totalHours : '-'}</div>
                                            </div>
                                            <div className="col-sm-6 info-box text-capitalize">
                                                <div className="label fs-14 text-warning">Service Month</div>
                                                <div className="value text-white text-opacity-75">{nm_servicemonth[item[1][0].nm_servicemonth]}</div>
                                            </div>
                                            <div className="col-sm-6 info-box text-capitalize">
                                                <div className="label fs-14 text-warning">Front Face on Billing <sup>1</sup></div>
                                                <div className="value text-white text-opacity-75">{item[1][0]?.frontFace?.length > 0 ? item[1][0].frontFace : "-"}</div>
                                            </div>
                                            <div className="col-sm-6 info-box text-capitalize">
                                                <div className="label fs-14 text-warning">Service Type</div>
                                                <div className="value text-white text-opacity-75">{new_servicetype_mapping[item[1][0].nm_servicetype]}</div>
                                            </div>
                                            <div className="col-sm-6 info-box text-capitalize">
                                                <div className="label fs-14 text-warning">Invoice From</div>
                                                <div className="value text-white text-opacity-75">{nm_invoicefrom[item[1][0].nm_invoicefrom]}</div>
                                            </div>
                                            <div className="col-sm-6 info-box text-capitalize">
                                                <div className="label fs-14 text-warning">Invoice ID</div>
                                                <div className="value text-white text-opacity-75">{item[1][0].nm_zohoinvoiceid}</div>
                                            </div>
                                            <div className="col-sm-6 info-box text-capitalize">
                                                <div className="label fs-14 text-warning">Service Status</div>
                                                <div className="value" style={{ color: '#03ff85' }}>{nm_servicestatus[item[1][0].nm_servicestatus]}</div>
                                            </div>
                                            <div className="col-sm-6 info-box text-capitalize">
                                                <div className="label fs-14 text-warning">Payment Receive Date</div>
                                                <div className="value text-white text-opacity-75">{item[1][0].nm_paymentreceiveddate ? convertReadable(item[1][0].nm_paymentreceiveddate) : "-"}</div>
                                            </div>
                                            <div className="col-sm-6 info-box text-capitalize">
                                                <div className="label fs-14 text-warning">Payment Status</div>
                                                <div className="value text-white text-opacity-75">{nm_paymentstatus[item[1][0].nm_paymentstatus]}</div>
                                            </div>
                                        </div>
                                        {item[1][0].is_account_credited?
                                        <>
                                        <hr className="divider border border-top-2 border-secondary border-opacity-75 my-3" />
                                    <div className="row gx-2 gy-3">
                                        <div className="col-sm-6 info-box text-capitalize">
                                            <div className="label fs-14 text-warning">Your Incentive</div>
                                            <div className="value text-white text-opacity-75">₹{item[1][0].redeem_amount}</div>
                                        </div>
                                        <div className="col-sm-6 info-box text-capitalize">
                                            <div className="label fs-14 text-warning">Credit Transection Details</div>
                                            <div className="value text-break text-white text-opacity-75 d-flex flex-wrap align-items-center">{item[1][0].send_money_transaction_id} <i className="bi bi-dot text-white fs-3 lh-1"></i> {convertReadable(item[1][0].send_money_transaction_time)} {convertReadableTime(item[1][0].send_money_transaction_time)}</div>
                                        </div>
                                        
                                        
                                    </div>
                                    <div className="btn-wrapper text-center mt-4 mb-2">
                                        <Button variant="outline-success" className="bg-transparent text-center rounded-1 px-4 py-1"  style={{ minWidth: '240px', color: '#03ff84', borderColor: '#03ff84' }}>
                                            {item[1][0].is_share_your_incentive && item[1][0].shared_account_credited?
                                            <>
                                            <div className="fs-5 fw-semibold lh-sm">Credited ₹{item[1][0].redeem_amount + item[1][0].shared_redeem_amount}</div>
                                            <div className="fs-12 opacity-75">On {convertReadableTime(item[1][0].shared_credit_date)}</div>
                                            </>

                                            : item[1][0].is_share_your_incentive && !item[1][0].shared_account_credited?

                                            <>
                                            <div className="fs-5 fw-semibold lh-sm">Credited ₹{item[1][0].redeem_amount}</div>
                                            <div className="fs-12 opacity-75">On {convertReadable(item[1][0].send_money_transaction_time)} {convertReadableTime(item[1][0].send_money_transaction_time)}</div>
                                            </>
                                            :
                                            <>
                                            <div className="fs-5 fw-semibold lh-sm">Credited ₹{item[1][0].redeem_amount}</div>
                                            <div className="fs-12 opacity-75">On {convertReadable(item[1][0].send_money_transaction_time)} {convertReadableTime(item[1][0].send_money_transaction_time)}</div>
                                            </>
                                            }
                                        </Button>
                                    </div>
                                        </>
                                        :
                                        <div className="btn-wrapper text-center mt-4 mb-2">

                                            {item[1][0].is_billing_start_before_oct_24 ?
                                                <Button className="bg-transparent border border-danger text-center rounded-1 px-4 py-1" style={{ minWidth: '240px' }}>
                                                    <span className="d-block fs-5 fw-semibold text-white">Not eligible for redeem</span>
                                                    <span className="d-block fs-12 text-white text-opacity-75">Incentive policy effected from October 2024</span>
                                                </Button>
                                                : item[1][0].nm_paymentstatus === 124950002 ?


                                                    <Button className="bg-transparent border border-white text-center rounded-1 px-4 py-1" style={{ minWidth: '240px' }}>
                                                        <span className="d-block fs-5 fw-semibold text-white"></span>
                                                        <span className="d-block fs-12 text-white text-opacity-75">Awaiting payment from the client.</span>
                                                    </Button>

                                                    : item[1][0].isProrated ?
                                                        <Button className="bg-transparent border border-danger text-center rounded-1 px-4 py-1" style={{ minWidth: '240px' }}>
                                                            {/* <span className="d-block fs-5 fw-semibold text-white">Not eligible for redeem</span> */}
                                                            <span className="d-block fs-12 text-white text-opacity-75">Prorated payments are excluded from incentives as they represent partial payments made by the client to align with the regular monthly billing cycle.</span>
                                                        </Button>
                                                        : isClientTypeMismatched ?
                                                            <Button className="bg-transparent border border-danger text-center rounded-1 px-4 py-1" style={{ minWidth: '240px' }}>
                                                                <span className="d-block fs-5 fw-semibold text-white">Not eligible for redeem</span>
                                                                <span className="d-block fs-12 text-white text-opacity-75">Billing Type Mismatched</span>
                                                            </Button>

                                                            : item[1][0].is_hour_less ?
                                                                <Button className="bg-transparent border border-danger text-center rounded-1 px-4 py-1" style={{ minWidth: '240px' }}>
                                                                    {/* <span className="d-block fs-5 fw-semibold text-white">Not eligible for redeem</span> */}
                                                                    <span className="d-block fs-12 text-white text-opacity-75">total hours are less then incentive policy</span>
                                                                </Button>
                                                                : item[1][0].isSdmMismatched ?
                                                                    <Button className="bg-transparent border border-danger text-center rounded-1 px-4 py-1" style={{ minWidth: '240px' }}>
                                                                        {/* <span className="d-block fs-5 fw-semibold text-white">Not eligible for redeem</span> */}
                                                                        <span className="d-block fs-12 text-white text-opacity-75">You are not designated as the SDM for this billing month and therefore, you are not eligible for incentive payments related to this billing cycle.</span>
                                                                    </Button>
                                                                    : item[1][0].nm_paymentstatus === 124950000 && item[1][0].monthCount === 0 && item[1][0].sdmNewHireFullPart && item[1][0].cr541_servicecategory === 232520000 ?
                                                                        <Button className="bg-transparent border border-warning text-center rounded-1 px-4 py-1" style={{ minWidth: '240px' }}>
                                                                            <span className="d-block fs-5 fw-semibold text-white">Pending Payment Milestones</span>
                                                                            <span className="d-block fs-12 text-white text-opacity-75"></span>
                                                                        </Button>
                                                                        : item[1][0].nm_paymentstatus === 124950000 && item[1][0].monthCount < 2 && item[1][0].sdmNewHireFullPart && item[1][0].cr541_servicecategory === 232520001 ?
                                                                            <Button className="bg-transparent border border-warning text-center rounded-1 px-4 py-1" style={{ minWidth: '240px' }}>
                                                                                <span className="d-block fs-5 fw-semibold text-white">Pending Payment Milestones</span>
                                                                                <span className="d-block fs-12 text-white text-opacity-75"></span>
                                                                            </Button>
                                                                            : item[1][0].nm_paymentstatus === 124950000 && !item[1][0].is_redeemption_approved_by_manager ?
                                                                                <Button className="bg-transparent border border-warning text-center rounded-1 px-4 py-1" style={{ minWidth: '240px' }}>
                                                                                    <span className="d-block fs-5 fw-semibold text-white">Redeem ₹{incentiveAmount}</span>
                                                                                    <span className="d-block fs-12 text-white text-opacity-75">Awaiting management approval.</span>
                                                                                </Button>


                                                                                : item[1][0].nm_paymentstatus === 124950000 && item[1][0].is_redeemption_approved_by_manager && item[1][0].is_installment_approved === 1 && item[1][0].is_requested_for_accounts ?
                                                                                    <Button variant="info" className="text-center rounded-1 px-4 py-1" style={{ minWidth: '240px' }}>
                                                                                        <span className="d-block fs-5 fw-semibold text-white">Redeemed ₹{incentiveAmount}</span>
                                                                                        <span className="d-block fs-12 text-white text-opacity-75">Awaiting credit from Accounts</span>
                                                                                    </Button>
                                                                                    : item[1][0].nm_paymentstatus === 124950000 && item[1][0].is_redeemption_approved_by_manager && item[1][0].is_installment_approved === 1 && !item[1][0].is_requested_for_accounts ?


                                                                                        <Button variant="success" className="text-center rounded-1 px-4 py-1" style={{ minWidth: '240px' }} onClick={() => { openRedeemAmountModal(); setRedeemAmount(incentiveAmount); setWhichMonth(installmentIndex); setType("sdm"); setInvoiceId(item[1][0].nm_zohoinvoiceid); setisSharedYour1stInstallment(null) }}>
                                                                                            <span className="d-block fs-5 fw-semibold text-white">Redeem ₹{incentiveAmount}</span>
                                                                                            <span className="fs-12 text-white text-opacity-75">You can redeem now</span>
                                                                                        </Button>
                                                                                        : null

                                            }
                                        </div>
                    }

                                    </div>
                                </div>
                            </div>




                        );
                    }

                }
            });

            // Once all promises are resolved, set the invoiceHtml
            Promise.all(promises).then(setInvoiceHtml);
        }

        
    }, [opInfo]); // Add opInfo as a dependency

    return (
        <div className="info-card rounded-3 p-2 mt-3">
            <Card.Header className="rounded-3" style={{ backgroundColor: '#1C5138' }}>
                <CustomToggle eventKey={index}>
                    <div className="row gx-2 gy-2">
                        <div className="left-section col-md info-box text-capitalize">
                            <div className="label fs-5 fw-medium text-warning">{opInfo.new_fullname}</div>
                            <div className="value text-white text-opacity-75 text-break">{opInfo.new_opportunityid}</div>
                        </div>
                        <div className="right-section col-md info-box text-capitalize">
                            <div className="total-Incentive pe-5">
                                <div className="fs-5 fw-semibold text-white">Total Incentive: ₹{opInfo.incentivePolicyObject.total_bonus} {opInfo.serviceInfo[0].cr541_servicecategory ? " | " + cr541_servicecategory[opInfo.serviceInfo[0].cr541_servicecategory].replace("-", " ") : " | Uncategorized"}</div>
                                <div className="fs-6 fw-semibold text-white text-opacity-50 text-capitalize">Incentive Type: {opInfo.incentivePolicyObject.scenario} ({opInfo.incentivePolicyObject.client_type})</div>
                            </div>
                        </div>
                    </div>
                </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={index}>
                <Card.Body className="mt-3 px-2">
                    {opInfo?.cardType === "sdm" ?

                        <div className="row row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 gx-2 gy-3">
                            <div className="info-box text-capitalize">
                                <div className="label fs-14 text-warning">Opportunity ID</div>
                                <div className="value text-white text-opacity-75 text-break">{opInfo.new_opportunityid}</div>
                            </div>
                            <div className="info-box text-capitalize">
                                <div className="label fs-14 text-warning">Opportunity Created</div>
                                <div className="value text-white text-opacity-75">{convertReadable(opInfo.createdon)}</div>
                            </div>
                            <div className="info-box text-capitalize">
                                <div className="label fs-14 text-warning">Opportunity Type</div>
                                <div className="value text-white text-opacity-75">{new_opportunitytype[opInfo.new_opportunitytype]}</div>
                            </div>
                            <div className="info-box text-capitalize">
                                <div className="label fs-14 text-warning">Skill Required</div>
                                <div className="value text-white text-opacity-75">{opInfo.skills}</div>
                            </div>
                            <div className="info-box text-capitalize">
                                <div className="label fs-14 text-warning">Opportunity Status</div>
                                {opInfo.statuscode === 3 ?
                                    <div className="value fw-medium" style={{ color: '#03ff85' }}>{statuscode[opInfo.statuscode]}</div>
                                    : opInfo.statuscode === 4 ?
                                        <div className="value fw-medium" style={{ color: '#d32f2f' }}>{statuscode[opInfo.statuscode]}</div>
                                        :
                                        <div className="value fw-medium " style={{ color: '#26c6da' }}>{statuscode[opInfo.statuscode]}</div>
                                }
                            </div>

                            <div className="info-box text-capitalize">
                                <div className="label fs-14 text-warning">Client Type</div>
                                <div className="value text-white text-opacity-75">{new_ClientType[opInfo.new_clienttype]}</div>
                            </div>
                            <div className="info-box text-capitalize">
                                <div className="label fs-14 text-warning">Country</div>
                                <div className="value text-white text-opacity-75">{opInfo.country}</div>
                            </div>
                            <div className="info-box text-capitalize">
                                <div className="label fs-14 text-warning">TimeZone</div>
                                <div className="value text-white text-opacity-75">{opInfo.timezone}</div>
                            </div>
                        </div>

                        :
                        <div className="row row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 gx-2 gy-3">
                            <div className="info-box text-capitalize">
                                <div className="label fs-14 text-warning">Opportunity ID</div>
                                <div className="value text-white text-opacity-75 text-break">{opInfo.new_opportunityid}</div>
                            </div>
                            <div className="info-box text-capitalize">
                                <div className="label fs-14 text-warning">Opportunity Created</div>
                                <div className="value text-white text-opacity-75">{convertReadable(opInfo.createdon)}</div>
                            </div>
                            <div className="info-box text-capitalize">
                                <div className="label fs-14 text-warning">Opportunity Added to Your Wallet</div>
                                <div className="value text-white text-opacity-75">{convertReadable(opInfo.wallet_opportunities.createdAt)}</div>
                            </div>
                            <div className="info-box text-capitalize">
                                <div className="label fs-14 text-warning">Skill Required</div>
                                <div className="value text-white text-opacity-75">{opInfo.skills}</div>
                            </div>
                            <div className="info-box text-capitalize">
                                <div className="label fs-14 text-warning">Opportunity Status</div>
                                {opInfo.statuscode === 3 ?
                                    <div className="value fw-medium" style={{ color: '#03ff85' }}>{statuscode[opInfo.statuscode]}</div>
                                    : opInfo.statuscode === 4 ?
                                        <div className="value fw-medium" style={{ color: '#d32f2f' }}>{statuscode[opInfo.statuscode]}</div>
                                        :
                                        <div className="value fw-medium " style={{ color: '#26c6da' }}>{statuscode[opInfo.statuscode]}</div>
                                }
                            </div>
                            <div className="info-box text-capitalize">
                                <div className="label fs-14 text-warning">Opportunity Type</div>
                                <div className="value text-white text-opacity-75">{new_opportunitytype[opInfo.new_opportunitytype]}</div>
                            </div>
                            <div className="info-box text-capitalize">
                                <div className="label fs-14 text-warning">Client Type</div>
                                <div className="value text-white text-opacity-75">{new_ClientType[opInfo.new_clienttype]}</div>
                            </div>
                            <div className="info-box text-capitalize">
                                <div className="label fs-14 text-warning">Country</div>
                                <div className="value text-white text-opacity-75">{opInfo.country}</div>
                            </div>
                            <div className="info-box text-capitalize">
                                <div className="label fs-14 text-warning">TimeZone</div>
                                <div className="value text-white text-opacity-75">{opInfo.timezone}</div>
                            </div>
                        </div>
                    }
                    {
                        opInfo.statuscode === 3 && !opInfo._new_serviceapplied_value ?

                            <>
                                <hr className="divider border border-top-2 border-secondary border-opacity-75 mt-4 mb-3" />
                                <div className="waiting-section fs-18 fw-semibold text-info">Waiting for service creation</div>

                            </>

                            : opInfo.statuscode === 3 && opInfo._new_serviceapplied_value ?
                                <>
                                    <hr className="divider border border-top-2 border-secondary border-opacity-75 my-3" />
                                    <div className="row row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 gx-2 gy-3">
                                        <div className="info-box text-capitalize">
                                            <div className="label fs-14 text-warning">Service ID</div>
                                            <div className="value text-white text-opacity-75 text-uppercase text-break">{opInfo.service[0].new_name}</div>
                                        </div>
                                        <div className="info-box text-capitalize">
                                            <div className="label fs-14 text-warning">Service Model</div>
                                            <div className="value text-white text-opacity-75">{new_servicetype_mapping[opInfo?.serviceInfo[0]?.nm_servicetype]}</div>
                                        </div>
                                        <div className="info-box text-capitalize">
                                            <div className="label fs-14 text-warning">Service Belongs To</div>
                                            <div className="value text-white text-opacity-75">{opInfo?.serviceInfo[0]?.belongsTo ? opInfo?.serviceInfo[0]?.belongsTo : "-"}</div>
                                        </div>
                                        <div className="info-box text-capitalize">
                                            <div className="label fs-14 text-warning">Service Tenure</div>
                                            {/* <div className="value text-white text-opacity-75">{opInfo.service[0].new_servicetenure}</div> */}
                                            <div className="value text-white text-opacity-75">{new_EmploymentTenure[opInfo.new_employmenttenure]}</div>
                                        </div>
                                        <div className="info-box text-capitalize">
                                            <div className="label fs-14 text-warning">Shift Start Time</div>
                                            <div className="value text-white text-opacity-75">{formatShiftTime(opInfo.service[0].new_shiftstarttime)}</div>
                                        </div>
                                        <div className="info-box text-capitalize">
                                            <div className="label fs-14 text-warning">Service Type</div>
                                            <div className="value text-white text-opacity-75">{new_servicetype[opInfo.service[0].new_servicetype]}</div>
                                        </div>
                                        <div className="info-box text-capitalize">
                                            <div className="label fs-14 text-warning">Service Lead (BDM)</div>
                                            <div className="value text-white text-opacity-75">{opInfo?.BDMName.length > 0 ? opInfo?.BDMName : "-"}</div>
                                        </div>
                                        <div className="info-box text-capitalize">
                                            <div className="label fs-14 text-warning">Shift Hours</div>
                                            <div className="value text-white text-opacity-75">{opInfo.service[0].new_shifthour}</div>
                                        </div>
                                        <div className="info-box text-capitalize">
                                            <div className="label fs-14 text-warning">Shift End Time</div>
                                            <div className="value text-white text-opacity-75">{formatShiftTime(opInfo.service[0].new_shiftendtime)}</div>
                                        </div>
                                    </div>
                                </>

                                : null

                    }
                    <hr className="divider border border-top-2 border-secondary border-opacity-75 my-3" />
                    <div className="description fs-12 text-white text-opacity-75 mb-3">
                        Incentive Redemption: The process will be available to you once the Opportunity status turns WON and payment is received from the client.
                    </div>
                    <div className="installment-wrapper">
                        <div className="row flex-nowrap g-3 overflow-auto">
                            {!opInfo?.isBdmMismatched && opInfo?.cardType === "individual" ?
                                invoiceHtml
                                : opInfo?.cardType === "sdm" ?
                                    invoiceHtml
                                    :
                                    null
                            }
                        </div>
                    </div>

                    <div className="description mt-3">
                        <div className="fs-12 text-white text-opacity-75 mb-1">1. "Front Face" means the client is familiar with them. If it's different from the billing resource, it means the billing resource is acting as their proxy.</div>
                        <div className="fs-12 text-white text-opacity-75">2. The Service Delivery Manager (SDM) is responsible for managing billing hours with the client and coordinating with the working resource to ensure smooth delivery of billing hours to the client.</div>
                    </div>
                </Card.Body>
            </Accordion.Collapse>
            <RedeemAmountModal show={showRedeemAmountModal} close={closeRedeemAmountModal} redeemAmount={redeemAmount} walletOppInfo={opInfo.wallet_opportunities} userRole={userRole} whichMonthRedeem={whichMonthRedeem} setShowRedeemAmountModal={setShowRedeemAmountModal} loadWallet={loadWallet} opInfo={opInfo} cardType={opInfo.cardType} type={type} invoiceId={invoiceId} isSharedYour1stInstallment={isSharedYour1stInstallment} installmentNo={installmentNo}/>

            <DeleteConfirmModal show={showDeleteModal} close={closeDeleteModal} loadWallet={loadWallet} walletOppInfo={opInfo.wallet_opportunities} userRole={userRole} setShowDeleteModal={setShowDeleteModal} />
        </div>
    )
}